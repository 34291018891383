import * as R from "ramda";
import { msaConfigOptions } from "utils/globals.js";

export const evaluateServiceManagement = (service_management) => {
  const defaultServiceManagement = {
    os: 0,
    db: 0,
    ws: 0,
  };
  const settings =
    R.isEmpty(service_management) || !service_management
      ? defaultServiceManagement
      : service_management;

  const getSetting = (value) =>
    R.path([value, 0], msaConfigOptions) ||
    R.path(
      [
        R.find((key) => {
          return msaConfigOptions[key][0] === value;
        }, R.keys(msaConfigOptions)),
        0,
      ],
      msaConfigOptions
    ) ||
    0;

  R.reduce(
    (acc, val) => {
      acc[val] = getSetting(settings[val] || 0);
      return acc;
    },
    {},
    R.keys(settings)
  );

  const result = {};
  R.forEachObjIndexed((value, key) => {
    result[key] = R.path([value, 0], msaConfigOptions) || 0;
  }, settings);
  return result;
};

export const compareServiceManagement = (a, b) => {
  return R.reduce(
    (acc, val) => {
      if (acc && a[val] !== b[val]) acc = false;
      return acc;
    },
    true,
    R.keys(a)
  );
};

import React from "react";
import IconCell from "components/table/IconCell";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledStatus = styled(IconCell)`
  && {
    color: ${(props) => (props.status ? props.theme.green[70] : props.theme.red[70])};
    margin-right: 5px;
  }
`;

const OnlineStatus = (props) => {
  const { t } = useTranslation();
  const status = props.status ? "common:agents.status_online" : "common:agents.status_offline";

  return (
    <StyledStatus id={props.id} icon="icon-circle" status={props.status}>
      {t(status)}
    </StyledStatus>
  );
};

OnlineStatus.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
};

export default OnlineStatus;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as R from "ramda";
import Text from "components/form/Text";
import TextArea from "components/form/TextArea";
import FuzzyTime from "components/common/FuzzyTime";
import watcherTheme from "watcherTheme";
import Link from "components/form/Link";
import Status from "components/details/Status";
import Command from "components/details/Command";
import { toTitleCase } from "utils/globals.js";
import { useTranslation } from "react-i18next";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0px 0px 40px;
  padding-top: 15px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-right: 30px;
  ${({ theme }) => theme.down.lg} {
    width: 100%;
    padding-right: 0px;
  }
`;

const Subsection = styled.div`
  margin-bottom: 1rem;
`;

const FlexSection = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 2rem;
  border-bottom: 1px solid ${watcherTheme.grey[20]};
  padding: 0.5rem 0.4rem 0.5rem 0;
`;

const SubheaderSection = styled(FlexSection)`
  border-bottom: 2px solid ${watcherTheme.grey[20]};
  padding: 5px 0;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  ${({ theme }) => theme.down.lg} {
    width: 100%;
  }
`;

const InlineItems = styled.div`
  display: flex;
`;

const FixedWidthCategory = styled(Text)`
  min-width: 160px;
`;

const ResultDetailsTab = (props) => {
  const { t } = useTranslation();

  const SubheaderText = (properties) => {
    return (
      <Text size="xl" m="0" className="-mt--1 -text--bold" t={properties.t}>
        {properties.children}
      </Text>
    );
  };

  const CategoryText = (properties) => {
    return (
      <FixedWidthCategory className="-text--bolder" m="0" t={properties.t}>
        {properties.children}
      </FixedWidthCategory>
    );
  };

  const InfoText = (properties) => {
    return (
      <Text className="-pr--2" m="0" size="md" t={properties.t}>
        {properties.children}
      </Text>
    );
  };

  const InfoObject = (properties) => {
    return <InlineItems className="-text--md -m--0">{properties.children}</InlineItems>;
  };

  const getStatus = () => {
    let data = R.path(["status"], props.result);
    let status = data === 0 ? 0 : data ? data : 3; // 0 was falsy
    return (
      <FlexSection>
        <CategoryText t={["common:agents.results.details.status"]} />
        <div className="-text--md">
          <Status id={"status"} status={status} />
        </div>
      </FlexSection>
    );
  };

  const getOccurrences = () => {
    let occurrences = R.path(["occurrences"], props.result);
    return occurrences ? (
      <FlexSection id="occurrences">
        <CategoryText t={["common:agents.results.details.occurrences"]} />
        <InfoText>{occurrences}</InfoText>
      </FlexSection>
    ) : (
      ""
    );
  };

  const getLastOccurrence = () => {
    return (
      <FlexSection>
        <CategoryText t={["common:agents.results.details.last_occurrence"]} />
        <InfoObject>
          <FuzzyTime
            id={`details-last-occurrence-${R.path(["id"], props.result)}`}
            time={R.path(["executed"], props.result) * 1000}
          />
        </InfoObject>
      </FlexSection>
    );
  };

  const getFirstOccurrence = () => {
    let occ = R.path(["occurrences"], props.result);
    let int = R.path(["interval"], props.result);
    if (occ && int) {
      let now = new Date().getTime() / 1000;
      let secondsElapsed = occ * int;
      return (
        <FlexSection id="first-occurrence">
          <CategoryText t={["common:agents.results.details.first_occurrence"]} />
          <InfoObject>
            <FuzzyTime
              id={`details-first-occurrence-${R.path(["id"], props.result)}`}
              time={new Date((now - secondsElapsed) * 1000)}
            />
          </InfoObject>
        </FlexSection>
      );
    } else {
      return "";
    }
  };

  const getSuppressionStatus = () => {
    let suppressed = R.path(["suppressed"], props.result)
      ? "common:agents.results.details.yes"
      : "common:agents.results.details.no";
    return (
      <FlexSection>
        <CategoryText t={["common:agents.results.details.check_suppressed"]} />
        <InfoText id="suppression-status" t={[suppressed]} />
      </FlexSection>
    );
  };

  const getCheckType = () => {
    let checkType = R.path(["check_type"], props.result);
    let checkId = R.path(["id"], props.result);
    return (
      <FlexSection>
        <CategoryText t={["common:agents.results.details.type"]} />
        <InfoText id="check-type">
          {checkType || checkId ? toTitleCase(checkType || checkId) : ""}
        </InfoText>
      </FlexSection>
    );
  };
  //

  const getPolicyLink = () => {
    let policyId = R.path(["watcher_policy_id"], props.result);
    return policyId ? (
      <FlexSection id="policy">
        <CategoryText t={["common:agents.results.details.policy"]} />
        <InfoObject id="policy-name">
          <Link
            id="policy-link"
            to={`/policies/${policyId}`}
            tooltip={t("common:tooltips.policy_info")}
          >
            {R.path(["watcher_policy_name"], props.result)}
          </Link>
        </InfoObject>
      </FlexSection>
    ) : (
      ""
    );
  };

  const getAgentName = () => {
    // TODO: this currently does not access the display name
    // or hostname before defaulting to id (see EventDetailsTab)
    return (
      <FlexSection>
        <CategoryText t={["common:agents.results.details.agent"]} />
        <InfoText id="agent-name">
          <Link
            id={`agent-link`}
            to={`/agents/${props.agentId}`}
            tooltip={t("common:tooltips.agent_details")}
          >
            {props.agentId}
          </Link>
        </InfoText>
      </FlexSection>
    );
  };

  const getDescription = () => {
    return (
      <FlexSection>
        <CategoryText t={["common:agents.results.details.description"]} />
        <InfoText id="check-description">{R.path(["description"], props.result) || ""}</InfoText>
      </FlexSection>
    );
  };

  const getCommand = () => {
    let command = R.path(["command"], props.result);
    return command ? (
      <FlexSection id="check-command">
        <CategoryText t={["common:agents.results.details.command"]} />
        <InfoObject>
          <Command command={command} />
        </InfoObject>
      </FlexSection>
    ) : (
      ""
    );
  };

  const getConfig = () => {
    let warning = R.path(["thresholds", "warning"], props.result);
    let critical = R.path(["thresholds", "critical"], props.result);
    return warning || critical ? (
      <FlexSection id="config">
        <CategoryText t={["common:agents.results.details.configuration"]} />
        {warning ? (
          <InfoText id="warning" t={["common:agents.results.details.warning"]}>
            {warning}
          </InfoText>
        ) : (
          ""
        )}
        {critical ? (
          <InfoText id="critical" t={["common:agents.results.details.critical"]}>
            {critical}
          </InfoText>
        ) : (
          ""
        )}
      </FlexSection>
    ) : (
      ""
    );
  };

  return (
    <React.Fragment>
      {!props.loading && (
        <ContentWrapper>
          <LeftColumn>
            <Subsection>
              <SubheaderSection>
                <SubheaderText t={["common:agents.results.details.result_details"]} />
              </SubheaderSection>
              {getStatus()}
              {getOccurrences()}
              {getLastOccurrence()}
              {getFirstOccurrence()}
              {getSuppressionStatus()}
            </Subsection>
            <Subsection>
              <SubheaderSection>
                <SubheaderText t={["common:agents.results.details.check_details"]} />
              </SubheaderSection>
              {getCheckType()}
              {getPolicyLink()}
              {getAgentName()}
              {getDescription()}
              {getCommand()}
              {getConfig()}
            </Subsection>
          </LeftColumn>

          <RightColumn>
            <SubheaderSection className="-mb--1">
              <SubheaderText t={["common:agents.results.details.output"]} />
            </SubheaderSection>
            <TextArea
              id={`details-output-${R.path(["id"], props.result)}`}
              defaultValue={R.path(["output"], props.result)}
              disabled
              onChange={() => {}}
              size="-lg"
              style={{ height: "400px" }}
            />
          </RightColumn>
        </ContentWrapper>
      )}
    </React.Fragment>
  );
};

ResultDetailsTab.propTypes = {
  result: PropTypes.object.isRequired,
  agentId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ResultDetailsTab;

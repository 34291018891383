import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InfoBox = (props) => {
  const { t } = useTranslation();

  return (
    <div className="chi-alert -info" role="alert">
      <i className="chi-alert__icon chi-icon icon-circle-info"></i>
      <div className="chi-alert__content">
        <p className="chi-alert__text">{props.t ? t(props.t) : props.children}</p>
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  t: PropTypes.array,
  children: PropTypes.any,
};

export default InfoBox;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQueryParameter } from "utils/nav";
import * as cam from "cam/utils/camOauth";
import { redirectToCamOAuth } from "cam/utils/camOauth";
import { tokenSelector } from "redux/users/userSlice";
import { logIn, logInFailure } from "redux/users/userSlice";
import styled from "styled-components";
import Button from "components/common/Button";
import TextInput from "components//form/TextInput";
import { useHistory } from "react-router";
import useKeyEvent from "hooks/useKeyEvent";
import Spinner from "../../components/form/Spinner";

// @TODO: i18n
export const OAUTH_FAILURE_MSG = "Login failed, unauthenticated login attempt";

const Page = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const CardContent = styled.div`
  min-width: 500px;
  min-height: 200;
`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState();
  const history = useHistory();
  const tokenRef = useRef(token);

  if (cam.oauthFragmentIsSet()) {
    const selectedToken = useSelector(tokenSelector);
    useEffect(() => {
      if (cam.oauthStateIsValid()) {
        let token = cam.extractTokenFromFragment();
        // Why is this necessary?
        window.location.hash = "";
        dispatch(logIn(history, token, true));
      } else {
        dispatch(logInFailure(OAUTH_FAILURE_MSG));
      }
    }, [selectedToken]);

    return (
      <Page>
        <Spinner id="login-spinner" size="xxl" color="primary" />
      </Page>
    );
  } else if (getQueryParameter("useOauth") == null || getQueryParameter("useOauth") === "true") {
    redirectToCamOAuth();
    return (
      <Page>
        <Spinner id="login-spinner" size="xxl" color="primary" />
      </Page>
    );
  }

  const handleLogin = async () => {
    if (await dispatch(logIn(history, tokenRef.current, true))) {
      history.push("/agents");
    }
  };

  const handleInput = (e) => {
    tokenRef.current = e.target.value;
    setToken(e.target.value);
  };

  useKeyEvent(handleLogin, ["Enter", "NumpadEnter"]);

  return (
    <Page>
      <div className="chi-card -portal">
        <div className="chi-card__header -sm">
          <div className="chi-card__title">CAM Token</div>
        </div>
        <CardContent className="chi-card__content">
          <TextInput id="login-page-text-input" onChange={handleInput}></TextInput>
        </CardContent>
        <div className="chi-card__footer">
          <Button id="login-page-login-button" onClick={handleLogin} className="-primary">
            Login
          </Button>
        </div>
      </div>
      <div />
    </Page>
  );
};

export default LoginPage;

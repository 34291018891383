import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

const ChiSlider = (props) => {
  const getTickOptions = () => {
    return R.map(([id, label]) => {
      return (
        <div key={id} id={id}>
          <div className="chi-input__tick"></div>
          <div className="chi-input__tick-label">{label}</div>
        </div>
      );
    }, props.options);
  };

  return (
    <div className="chi-form__item">
      <label className="chi-label" htmlFor={props.id}>
        {props.label}
      </label>
      <div className="chi-input__wrapper">
        <input
          className="chi-range-slider"
          type={props.type || "range"}
          min={props.min}
          max={props.max}
          step={props.step || "1"}
          id={props.id}
        ></input>
        <div className="chi-input__tick-bar">{getTickOptions()}</div>
        {/* <div className="chi-input__progress"></div>
        <div className="chi-input__thumb"></div> */}
      </div>
    </div>
  );
};

ChiSlider.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  step: PropTypes.string,
};

export default ChiSlider;

import React from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import Link from "components/form/Link";
import styled from "styled-components";
import Table from "components/table/Table";
import Icon from "components/common/Icon";
import Status from "components/details/Status";
import Dropdown, { DropdownItem } from "components/menu/Dropdown";
import TruncatedText from "components/common/TruncatedText";
import { toTitleCase } from "utils/globals.js";
import { useTranslation } from "react-i18next";

const ButtonsCell = styled.div`
  display: flex;
`;

const AgentResultsTab = (props) => {
  const { t } = useTranslation();

  const getStatus = (properties) => {
    return <Status id={`row-${properties.row.id}-status`} status={properties.cell.value} />;
  };

  const getCheckType = (properties) => {
    let checkType = R.path(["row", "original", "check_type"], properties);
    let checkId = R.path(["row", "original", "id"], properties);
    let titleCase = checkType || checkId ? toTitleCase(checkType || checkId) : "";
    let resultUuid = R.path(["row", "original", "id"], properties);
    return (
      <Link
        id={`row-${properties.row.id}-result-details-link`}
        to={`/agents/${props.agentUuid}/results/${resultUuid}`}
        tooltip={t("common:tooltips.result_details")}
      >
        {titleCase}
      </Link>
    );
  };

  const getPolicyLink = (properties) => {
    let name = R.path(["row", "original", "watcher_policy_name"], properties);
    return name ? (
      <Link
        id={`row-${properties.row.id}-policy-link`}
        to={`/policies/${properties.value}`}
        tooltip={t("common:tooltips.policy_info")}
      >
        {name}
      </Link>
    ) : (
      ""
    );
  };

  const getType = (properties) => {
    let type = properties.value;
    return <span id={`row-${properties.row.id}-result-type`}>{type ? toTitleCase(type) : ""}</span>;
  };

  const getDescriptionCell = (properties) => {
    return (
      <TruncatedText
        id={`description-${properties.row.id}`}
        text={properties.value || ""}
        maxLength={20}
      />
    );
  };

  const getOutput = (properties) => {
    return (
      <TruncatedText
        id={`output-${properties.row.id}`}
        text={properties.value || ""}
        maxLength={20}
        popoverStyles={{ maxWidth: "600px" }}
      />
    );
  };

  const getSuppressionStatus = (properties) => {
    return (
      <span id={`row-${properties.row.id}-suppression`}>
        {t([
          properties.value
            ? "common:agents.results.details.yes"
            : "common:agents.results.details.no",
        ])}
      </span>
    );
  };

  const getCellButtons = (properties) => {
    const id = properties.row.id;
    const command = R.path(["row", "original", "command"], properties);

    const handleCopy = () => {
      navigator.clipboard.writeText(command);
    };

    return (
      <ButtonsCell>
        <Dropdown id={`kebab-dropdown-${id}`} icon="icon-more-vert" flat float>
          <DropdownItem
            id={`action-copy-command-${id}`}
            onClick={handleCopy}
            className={command ? "" : " -disabled"}
          >
            <Icon id={`${properties.row.original.id}-copy-button`} icon="icon-paste" float flat />
            {t("common:agents.results.dropdown_copy")}
          </DropdownItem>
        </Dropdown>
      </ButtonsCell>
    );
  };

  const columns = [
    {
      Header: "common:agents.results.header_status",
      accessor: "status",
      Cell: getStatus,
    },
    {
      Header: "common:agents.results.header_checktype",
      accessor: "check_type",
      Cell: getCheckType,
    },
    {
      Header: "common:agents.results.header_policy",
      accessor: "watcher_policy_id",
      Cell: getPolicyLink,
    },
    {
      Header: "common:agents.results.header_type",
      accessor: "type",
      Cell: getType,
    },
    {
      Header: "common:agents.results.header_description",
      accessor: "description",
      Cell: getDescriptionCell,
    },
    {
      Header: "common:agents.results.header_output",
      accessor: "output",
      Cell: getOutput,
    },
    {
      Header: "common:agents.results.header_suppressed",
      accessor: "suppressed",
      Cell: getSuppressionStatus,
    },
    {
      Header: "common:agents.results.header_timestamp",
      accessor: "executed",
      isTimestamp: true,
      timestampOffset: 1000,
    },
    {
      id: "buttons-cell",
      Header: "",
      accessor: "workspace_id",
      Cell: getCellButtons,
      disableSortBy: true,
    },
  ];

  return <Table id="agent-results-table" columns={columns} data={props.data} />;
};

AgentResultsTab.propTypes = {
  data: PropTypes.array.isRequired,
  agentUuid: PropTypes.string.isRequired,
};

export default AgentResultsTab;

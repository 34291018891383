import React, { useState } from "react";
import styled from "styled-components";
import Popover from "components/modal/Popover";
import PopoverAnchor from "components/modal/PopoverAnchor";
import PropTypes from "prop-types";
import Icon from "components/common/Icon";
import Text from "components/form/Text";

const InlineItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TruncatedText = (props) => {
  const [show, setShow] = useState(false);
  const [popoverRef, setPopoverRef] = useState(null);

  const handlePopoverOpen = () => {
    setShow(true);
  };
  const handlePopoverClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      {props.text.length > props.maxLength && (
        <InlineItems>
          <Text size="md" m="0">
            {props.text.slice(0, props.maxLength).trim() + " ... "}
          </Text>
          <PopoverAnchor
            id={`${props.id}-anchor`}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            getRef={setPopoverRef}
          >
            <Icon
              id={`info-icon-${props.id}`}
              icon="icon-circle-info-outline"
              size="xs"
              className="-icon--info"
            />
          </PopoverAnchor>
          <Popover
            id={`${props.id}-popover`}
            show={show}
            text={props.text}
            handleClose={handlePopoverClose}
            refElement={popoverRef || {}}
            popoverStyles={props.popoverStyles}
          />
        </InlineItems>
      )}
      {props.text.length <= props.maxLength && (
        <Text size="md" m="0">
          {props.text}
        </Text>
      )}
    </React.Fragment>
  );
};

TruncatedText.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  popoverStyles: PropTypes.object,
};

export default TruncatedText;

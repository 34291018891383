import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*
  This sets standard layout for all page contents within main
*/

const StyledPageContent = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  min-height: 175px;
  width: 100%;
  ${({ theme }) => theme.only.xs} {
    padding: 1rem 1rem;
  }
  ${({ theme }) => theme.only.sm} {
    padding: 1rem 1.5rem 2rem;
  }
  ${({ theme }) => theme.only.md} {
    padding: 1rem 2rem 2rem;
  }
  ${({ theme }) => theme.up.lg} {
    padding: 1rem 6rem 2rem;
  }
`;

const PageContent = (props) => {
  return <StyledPageContent>{props.children}</StyledPageContent>;
};

PageContent.propTypes = {
  children: PropTypes.any,
};

export default PageContent;

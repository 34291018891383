import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import eventsApi from "clients/eventsApi";
import { addNotification } from "redux/notifications/notificationSlice";
import * as R from "ramda";
import i18next from "i18next";
import settings from "utils/settings";

const eventsAdapter = createEntityAdapter({
  // eslint-disable-next-line
  // NOTE: This correctly orders them so that the most recent is first when they first load into the redux store, but as they update in real time you currently have to hover over the timestamp to get it to show the updated timestamp, and you have to refresh the browser page entirely to get them to reorder so that the most recent are at the top again. Do we want to change this behavior wrt UX?
  sortComparer: R.descend(R.prop("timestamp")),
  // eslint-disable-next-line
  // FIXME: (?) We are actually using the check.executed property in the table cell, because timestamp was not updating properly (this was a last-minute change the morning of Demo Days) - however, the line below doesn't correctly order them. Not clear on what the difference is between timestamp and check.executed but they do not behave as I'd expect.
  // sortComparer: R.descend(R.prop(R.path("check", "executed"))),
});

export const getEvents = createAsyncThunk("events/getEvents", async (tenantId, { dispatch }) => {
  return await eventsApi.getEvents(`${settings.source}_customer`, tenantId).catch((err) => {
    dispatch(
      addNotification([
        "danger",
        i18next.t("common:events.notifications.error.fetching_events", { tenant: tenantId }),
        false,
        "common:common.error_title",
      ])
    );
    throw err;
  });
});

// eventId added to args for use in reducer
export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  // eslint-disable-next-line
  async ({ tenantId, clientId, checkId, eventId }, { dispatch }) => {
    return await eventsApi
      .deleteEvent("edge_customer", tenantId, clientId, checkId)
      .then((resp) => {
        dispatch(
          addNotification([
            "success",
            i18next.t("common:events.notifications.success.deleting_event"),
            true,
            "common:common.success_title",
          ])
        );
        return resp;
      })
      .catch((err) => {
        dispatch(
          addNotification([
            "danger",
            i18next.t("common:events.notifications.error.deleting_event"),
            false,
            "common:common.error_title",
          ])
        );
        throw err;
      });
    // TODO: handle other types of errors/failures?
  }
);

const initialState = eventsAdapter.getInitialState({
  loading: false,
  entities: {},
  ids: [],
});

const eventsSlice = createSlice({
  name: "events",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        eventsAdapter.setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getEvents.rejected, (state) => {
        eventsAdapter.removeAll(state);
        state.loading = false;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        eventsAdapter.removeOne(state, action.meta.arg.eventId);
        state.loading = false;
      })
      .addCase(deleteEvent.rejected, (state) => {
        eventsAdapter.removeAll(state);
        state.loading = false;
      });
  },
});

export default eventsSlice.reducer;

export const eventsSelectors = eventsAdapter.getSelectors((state) => state.events);

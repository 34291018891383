import React from "react";
import PropTypes from "prop-types";
import Icon from "components/common/Icon";
import Button from "components/common/Button";

import styled from "styled-components";

const StyledButton = styled(Button)`
  /* has to be this specific 
  because the hover and active css on buttons would overwrite the border */
  &&&&& {
    ${({ noPadding }) => (noPadding ? "padding: 0;" : "")}
    ${({ noPadding }) => (noPadding ? "border: 0;" : "")}
  }
`;

const IconButton = (props) => {
  return (
    <StyledButton
      getRef={props.getRef}
      noPadding={props.noPadding}
      id={`button-${props.id}`}
      size={props.size}
      onClick={props.onClick}
      flat={props.flat}
      className={`${props.float ? " -float" : "-icon"}${props.close ? " -close" : ""} ${
        props.className ? props.className : ""
      }`}
    >
      <Icon size={props.size} id={`${props.id}-icon`} icon={props.icon} />
    </StyledButton>
  );
};

IconButton.propTypes = {
  getRef: PropTypes.func,
  noPadding: PropTypes.bool,
  close: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  flat: PropTypes.bool,
  float: PropTypes.bool,
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IconButton;

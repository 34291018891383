import { createSlice, createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";
import { addNotification } from "redux/notifications/notificationSlice";
import agentsApi from "clients/agentsApi";
import * as R from "ramda";

const resultsAdapter = createEntityAdapter({
  sortComparer: R.ascend(R.prop("name")),
});

export const getAgentResults = createAsyncThunk(
  "results/getAgentResults",
  async (agentId, { dispatch }) => {
    return await agentsApi.getAgentResults(agentId).catch((err) => {
      dispatch(
        addNotification([
          "danger",
          "common:agents.notifications.error.fetching_results",
          false,
          "common:common.error_title",
        ])
      );

      throw err;
    });
  }
);

const initialState = resultsAdapter.getInitialState({
  loading: false,
  entities: {},
  ids: [],
});

const resultsSlice = createSlice({
  name: "results",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgentResults.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgentResults.fulfilled, (state, action) => {
        // format payload to be acceptable into [] of {}
        // add 'id' field to payload
        let results = Object.values(action.payload);
        const forEachIndexed = R.addIndex(R.forEach);
        forEachIndexed((result, index) => {
          result.id = Object.keys(action.payload)[index];
        }, results);

        resultsAdapter.setAll(state, results);
        state.loading = false;
      })
      .addCase(getAgentResults.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default resultsSlice.reducer;

export const resultsSelectors = resultsAdapter.getSelectors((state) => state.results);

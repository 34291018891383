import { createSlice, createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";
import enablementApi from "clients/enablementApi";
import { addNotification } from "redux/notifications/notificationSlice";
import * as R from "ramda";
import i18next from "i18next";

const serverAdapter = createEntityAdapter({
  sortComparer: R.ascend(R.prop("name")),
});

export const getEnablementServers = createAsyncThunk(
  "enablement/getEnablementServers",
  async (tenantId, { dispatch }) => {
    return await enablementApi.getServers(tenantId).catch((err) => {
      dispatch(
        addNotification([
          "danger",
          "common:enablement.notifications.error.fetching_servers",
          true,
          "common:common.error_title",
        ])
      );

      throw err;
    });
  }
);

export const postEnablementServers = createAsyncThunk(
  "enablement/postEnablementServers",
  async ({ source, customerId, body }, { dispatch, getState }) => {
    let fullPayload = await enablementApi
      .postEnablementServers(source, customerId, body)
      .catch((obj) => {
        if (!obj.response) {
          dispatch(
            addNotification([
              "danger",
              "common:enablement.notifications.error.failed_to_update",
              false,
              "common:common.error_title",
            ])
          );
          Promise.reject(obj);
        } else {
          if (obj.response.data.message) {
            let message = obj.response.data.message;
            dispatch(addNotification(["danger", message, false, "common:common.error_title"]));
            Promise.reject(obj);
          } else if (obj.response.data) {
            return obj.response.data;
          } else {
            // not sure if this would ever be needed
            dispatch(
              addNotification([
                "danger",
                "common:enablement.notifications.error.failed_to_update",
                false,
                "common:common.error_title",
              ])
            );
            Promise.reject(obj);
          }
        }
      });
    if (fullPayload.length > 0) {
      let failed = [];
      let succeeded = [];
      R.forEach((item) => {
        if (item.error) {
          failed.push(R.clone(item));
        } else {
          succeeded.push(R.clone(item));
        }
      }, fullPayload);
      if (succeeded.length > 0) {
        dispatch(
          addNotification([
            "success",
            i18next.t("common:enablement.notifications.success.update_partial", {
              count: succeeded.length,
            }),
            true,
            "common:common.success_title",
          ])
        );
      }
      if (failed.length > 0) {
        const detailsData = failed.map((failedServer) => {
          const srv = enablementServerSelectors.selectById(
            getState(),
            failedServer.external_data.server_id
          );

          return {
            id: srv.id,
            displayName: i18next.t("common:enablement.server_display_name", {
              serverName: srv.name || i18next.t("common:enablement.unknown_server"),
              serverId: srv.id,
            }),
            errorMessage: R.path(["error", "message"], failedServer),
          };
        });

        const details = {
          title: "common:enablement.notifications.errorModal.title",
          subtitle: "common:enablement.notifications.errorModal.subtitle",
          icon: "icon-circle-alert",
          data: detailsData,
        };

        dispatch(
          addNotification([
            "danger",
            i18next.t("common:enablement.notifications.error.update_partial"),
            false,
            "common:common.error_title",
            details,
          ])
        );
      }
      return fullPayload;
    }
  }
);

const initialState = serverAdapter.getInitialState({
  loading: false,
});

const enablementSlice = createSlice({
  name: "enablement",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEnablementServers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEnablementServers.fulfilled, (state, action) => {
        let serverObjects = R.map((object) => {
          return {
            ...object,
            isUpdating: false,
          };
        }, action.payload);
        serverAdapter.setAll(state, serverObjects);
        state.loading = false;
      })
      .addCase(getEnablementServers.rejected, (state) => {
        serverAdapter.removeAll(state);
        state.loading = true;
      })
      .addCase(postEnablementServers.pending, (state, action) => {
        let serverObjects = R.map((object) => {
          return {
            id: object.external_data.server_id,
            changes: {
              isUpdating: true,
            },
          };
        }, action.meta.arg.body);
        serverAdapter.updateMany(state, serverObjects);
      })
      .addCase(postEnablementServers.fulfilled, (state, action) => {
        let serverObjects = R.map((object) => {
          return {
            id: object.external_data.server_id,
            changes: {
              service_management: object.service_management,
              service_management_update_date: object.service_management_update_date,
              isUpdating: false,
            },
          };
        }, action.payload);
        serverAdapter.updateMany(state, serverObjects);
      })
      .addCase(postEnablementServers.rejected, (state, action) => {
        let serverObjects = R.map((object) => {
          return {
            id: object.external_data.server_id,
            changes: {
              isUpdating: false,
            },
          };
        }, action.meta.arg.body);
        serverAdapter.updateMany(state, serverObjects);
      });
  },
});

export default enablementSlice.reducer;

export const enablementServerSelectors = serverAdapter.getSelectors((state) => state.enablement);

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Router from "react-router-dom";
import Tooltip from "components/modal/Tooltip";
import PopoverAnchor from "components/modal/PopoverAnchor";

const Link = (props) => {
  const [show, setShow] = useState(false);
  const [tooltipRef, setTooltipRef] = useState(null);

  const handleTooltipOpen = () => {
    setShow(true);
  };
  const handleTooltipClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      <PopoverAnchor
        id={`${props.id}-anchor`}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        getRef={setTooltipRef}
      >
        <Router.Link
          id={props.id}
          to={props.to}
          className={`chi-link${props.disabled ? " -disabled" : ""}`}
        >
          {props.children}
        </Router.Link>
      </PopoverAnchor>
      <Tooltip
        id={`${props.id}-tooltip`}
        text={props.tooltip}
        show={show}
        refElement={tooltipRef || {}}
        handleClose={handleTooltipClose}
      />
    </React.Fragment>
  );
};

Link.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.any,
  to: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default Link;

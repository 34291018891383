import styled from "styled-components";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { logIn } from "redux/users/userSlice";
import Routes from "components/navigation/Routes";
import Navigation from "components/navigation/Navigation";
import EmptyTenantPage from "components/navigation/EmptyTenantPage";
import Notifications from "components/notification/Notifications";
import { useSelector } from "react-redux";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import PageContent from "components/common/PageContent";

const Main = styled.main`
  overflow: auto;
`;

const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

const App = () => {
  const tenant = useSelector(selectedCustomerIdSelector);
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logIn(history));
  }, []);

  return (
    <AppContainer>
      <Notifications />
      <Navigation />
      <Main>
        <PageContent>{tenant ? <Routes /> : <EmptyTenantPage />}</PageContent>
      </Main>
    </AppContainer>
  );
};

export default App;

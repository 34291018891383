import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "components/common/Icon";
import { useTranslation } from "react-i18next";

const StyledLeftIcon = styled(Icon)`
  margin-right: 5px !important;
`;

const StyledRightIcon = styled(Icon)`
  margin-left: 5px !important;
`;

// https://assets.ctl.io/chi/3.15.0/components/forms/text-input/
const TextInput = (props) => {
  const { t } = useTranslation();

  return (
    <div
      id={`${props.id}-container`}
      className={`chi-form__item${props.row ? " -row" : ""}${
        props.floating ? " -floating-label" : ""
      }`}
    >
      {props.label || props.optional ? (
        <label id={`${props.id}-label`} className={`chi-label`} htmlFor={props.id}>
          {t(props.label)}
          {props.optional ? (
            <abbr className={`chi-label__optional`} title="Optional field">
              {t(props.optional)}
            </abbr>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}
      <div
        className={`chi-input__wrapper${props.iconLeft ? " -icon--left" : ""}${
          props.iconRight ? " -icon--right" : ""
        }`}
      >
        <input
          type="text"
          className={`chi-input${props.error ? " -danger" : ""}`}
          id={props.id}
          placeholder={t(props.placeholder) || ""}
          required={props.required || false}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
          value={props.value}
        />
        {props.iconLeft && <StyledLeftIcon id={`icon-lef-${props.id}`} icon={props.iconLeft} />}
        {props.iconRight && (
          <StyledRightIcon id={`icon-right-${props.id}`} icon={props.iconRight} />
        )}
      </div>
      {props.message ? (
        <div className={`chi-label -status${props.error ? " -danger" : ""}`}>
          {t(props.message)}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

TextInput.propTypes = {
  // Label for input
  value: PropTypes.string,
  // Label for input
  label: PropTypes.string,
  // Input disabled
  disabled: PropTypes.bool,
  // Input readOnly
  readOnly: PropTypes.bool,
  // Placeholder text
  placeholder: PropTypes.string,
  // TODO spinner
  spinner: PropTypes.bool,
  // "required" styling (red splat)
  required: PropTypes.bool,
  // optional text (text in parentheses)
  optional: PropTypes.string,
  // TODO help tooltip
  help: PropTypes.string,
  // Message/description
  message: PropTypes.string,
  // "error" styling
  error: PropTypes.bool,
  // Makes Lable inline instead of on top
  row: PropTypes.bool,
  // Floating Label (keeps placeholder text visable when selected)
  floating: PropTypes.bool,
  // Input Icons
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  // On Change Function
  onChange: PropTypes.func.isRequired,
  // ID
  id: PropTypes.string.isRequired,
};

export default TextInput;

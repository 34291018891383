import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Text from "components/form/Text";

const StyledPageTitle = styled(Text)`
  color: ${({ theme }) => theme.blue[100]};
  margin: 1rem 0 0.5rem 0 !important;
`;

const PageTitle = (props) => {
  return (
    <StyledPageTitle id={props.id} size={props.size || "h4"} t={props.t}>
      {props.children}
    </StyledPageTitle>
  );
};

PageTitle.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.string,
  t: PropTypes.array,
};

export default PageTitle;

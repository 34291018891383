import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "components/common/Icon";

const IconStyleDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const IconCell = (props) => {
  return (
    <IconStyleDiv id={`${props.id}-wrapper`}>
      <Icon id={`${props.id}-icon`} icon={props.icon} className={props.className}></Icon>
      {props.children}
    </IconStyleDiv>
  );
};

IconCell.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default IconCell;

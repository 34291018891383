import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import React, { useState } from "react";
import { useTransition, animated } from "react-spring";

const Tooltip = (props) => {
  const [tooltipElement, setTooltipElement] = useState(null);
  const { styles, attributes } = usePopper(props.refElement, tooltipElement, {
    placement: props.placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      {
        name: "computeStyles",
        options: {
          adaptive: false,
        },
      },
    ],
  });

  const tooltipTransitions = useTransition(props.show, {
    from: { opacity: 0 },
    enter: () => async (next) => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      await next({
        opacity: 1,
      });
    },
    leave: { opacity: 0 },
  });

  return (
    props.show &&
    props.refElement &&
    tooltipTransitions(
      (transitionProps, item, key) =>
        item && (
          <animated.div
            id={props.id}
            className="chi-tooltip -active"
            aria-label={props.text}
            aria-modal="true"
            role="dialog"
            ref={setTooltipElement}
            style={{
              ...transitionProps,
              ...styles.popper,
              margin: 0,
              maxWidth: "unset",
              ...props.tooltipStyles,
            }}
            {...attributes.popper}
            x-placement={props.placement}
            key={key}
          >
            <div style={{ width: "auto" }}>{props.text}</div>
          </animated.div>
        )
    )
  );
};

Tooltip.defaultProps = {
  placement: "top",
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refElement: PropTypes.any,
  placement: PropTypes.string,
  tooltipStyles: PropTypes.any,
};

export default Tooltip;

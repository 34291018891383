import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import Header from "components/common/Header";
import { useLocation } from "react-router-dom";
import * as R from "ramda";
import styled from "styled-components";

const StyledSwagger = styled.div`
  overflow: auto;
`;

const SwaggerPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const getSwaggerUrl = (endpoint) => "https://api.local.watcher.ctl.io" + "/swagger_doc" + endpoint;

let potato = {
  "/docs/swagger/agents": {
    to: "/docs/swagger/agents",
    title: "Agents API",
    swaggerUrl: "/agents",
  },
  "/docs/swagger/actionTypes": {
    to: "/docs/swagger/actionTypes",
    title: "Action Types",
    swaggerUrl: "/policies/actions_types ",
  },
  "/docs/swagger/actions": {
    to: "/docs/swagger/actions",
    title: "Actions API",
    swaggerUrl: "/policies/actions",
  },
  "/docs/swagger/checkCategories": {
    to: "/docs/swagger/checkCategories",
    title: "Check Categories",
    swaggerUrl: "/policies/checks_categories",
  },
  "/docs/swagger/checkTypes": {
    to: "/docs/swagger/checkTypes",
    title: "Check Types",
    swaggerUrl: "/policies/checks_types",
  },
  "/docs/swagger/policies": {
    to: "/docs/swagger/policies",
    title: "Policies API",
    swaggerUrl: "/policies",
  },
  "/docs/swagger/scopeTypes": {
    to: "/docs/swagger/scopeTypes",
    title: "Scope Types",
    swaggerUrl: "/policies/scope_types",
  },
  "/docs/swagger/events": {
    to: "/docs/swagger/events",
    title: "Events API",
    swaggerUrl: "/events",
  },
  "/docs/swagger/suppressions": {
    to: "/docs/swagger/suppressions",
    title: "Suppressions API",
    swaggerUrl: "/suppressions",
  },
  "/docs/swagger/metrics": {
    to: "/docs/swagger/metrics",
    title: "Metrics API",
    swaggerUrl: "/metrics",
  },
};

const Swagger = () => {
  let hashUrl = useLocation().pathname;
  return (
    <SwaggerPageWrapper>
      <Header links={R.values(potato)} />
      <StyledSwagger>
        <SwaggerUI url={getSwaggerUrl(R.path([hashUrl, "swaggerUrl"], potato))} />
      </StyledSwagger>
    </SwaggerPageWrapper>
  );
};

export default Swagger;

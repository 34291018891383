import * as R from "ramda";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Notification from "./Notification";
import { useTransition, animated } from "react-spring";
import { useSelector, useDispatch } from "react-redux";
import { removeNotification, notificationSelectors } from "redux/notifications/notificationSlice";
import NotificationDetailsModal from "components/modal/NotificationDetailsModal";
import { useTranslation } from "react-i18next";

export const Container = styled.div`
  position: fixed;
  z-index: 29;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Message = styled(animated.div)`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 20rem;
`;

// react-spring config
const config = { tension: 125, friction: 20, precision: 0.1 };
// timeout for the notification
const timeout = 3500;

const Notifications = () => {
  const { t } = useTranslation();
  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);
  const items = useSelector(notificationSelectors.selectAll);
  const [displayModal, setDisplayModal] = useState(false);
  const [currData, setCurrData] = useState({});

  const dispatch = useDispatch();
  const removeItem = (key) => {
    dispatch(removeNotification(key));
  };

  const transitions = useTransition(items, {
    key: (item) => item.id,
    from: { opacity: 0, height: 0, life: "100%" },
    enter: (item) => async (next, stop) => {
      cancelMap.set(item, () => {
        stop();
        removeItem(item.id);
      });
      await next({
        opacity: 1,
        height: refMap.get(item).offsetHeight,
        config,
      });
      if (item.hasTimeout) {
        await next({
          life: "0%",
          config: { duration: timeout },
        });
        removeItem(item.id);
      }
    },
    leave: () => async (next) => {
      await next({ opacity: 0, config });
      await next({ height: 0, config });
    },
  });

  const handleCloseDetailsModal = () => {
    setDisplayModal(false);
    setCurrData({});
  };

  return (
    <React.Fragment>
      {displayModal && !R.isEmpty(currData) && (
        <NotificationDetailsModal
          isOpen={displayModal}
          detailsData={currData.details.data}
          title={currData.details.title}
          subtitle={currData.details.subtitle}
          detailsType={currData.type}
          icon={currData.details.icon}
          handleClose={handleCloseDetailsModal}
        />
      )}
      <Container id={"notifications-container"}>
        {transitions(({ life, ...style }, item, state) => {
          if (!items.find((i) => i.id === item.id) && state.phase === "mount") {
            return;
          }
          const handleGetRef = (ref) => ref && refMap.set(item, ref);
          const handleCloseNotification = (e) => {
            e.stopPropagation();
            if (cancelMap.has(item) && R.find(R.propEq("id", item.id))(items)) {
              cancelMap.get(item)();
            }
          };
          const handleOpenDetailsModal = (e) => {
            setCurrData(item);
            handleCloseNotification(e);
            setDisplayModal(true);
          };
          return (
            <Message style={style} key={`notifications-wrapper-${item.id}`}>
              <Notification
                id={`notifications-notification-${item.id}`}
                key={`notifications-notification-${item.id}`}
                style="toast"
                type={item.type}
                size={!item.details ? "sm" : "md"}
                title={item.title}
                msg={item.msg}
                timeout={item.hasTimeout ? timeout + 450 : null} // +450 for smooth animation
                primaryButtonText={item.details ? t("common:common.view_details") : null}
                handlePrimaryClick={item.details ? handleOpenDetailsModal : null}
                handleClose={handleCloseNotification}
                getRef={handleGetRef}
                life={life}
              />
            </Message>
          );
        })}
      </Container>
    </React.Fragment>
  );
};

export default Notifications;

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import EventsPage from "components/events/EventsPage";
import EventDetailsPage from "components/events/EventDetailsPage";
import EnablementPage from "components/enablement/EnablementPage";
import AgentsPage from "components/agents/AgentsPage";
import AgentDetailsPage from "components/agents/AgentDetailsPage";
import ResultDetailsPage from "../agents/ResultDetailsPage";
import { getDefaultPagePath } from "utils/sourceComponents";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={getDefaultPagePath()} />
      </Route>
      <Route exact path="/events">
        <EventsPage />
      </Route>
      <Route path="/events/:eventUuid">
        <EventDetailsPage />
      </Route>
      <Route path="/enablement">
        <EnablementPage />
      </Route>
      <Route exact path="/agents">
        <AgentsPage />
      </Route>
      <Redirect exact from="/agents/:agentUuid" to="/agents/:agentUuid/results" />
      <Route path="/agents/:agentUuid/results/:resultUuid">
        <ResultDetailsPage />
      </Route>
      <Route path="/agents/:agentUuid">
        <AgentDetailsPage />
      </Route>
      <Route path="/dashboard"></Route>
      <Route path="/suppressions"></Route>

      <Route path="*">idk man you lost</Route>
    </Switch>
  );
};

export default Routes;

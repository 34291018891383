import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import FuzzyTime from "components/common/FuzzyTime";

const StyledCell = styled.td`
  padding: 0 1rem !important;
`;

const TableCell = (props) => {
  const TimeStampCell = () => {
    return (
      <FuzzyTime
        id={`row-${props.cell.row.id}-fuzzy-time`}
        time={props.cell.value * props.cell.column.timestampOffset || 1}
      />
    );
  };

  let cell = props.cell.render("Cell");

  if (R.path(["cell", "column", "isTimestamp"], props)) {
    cell = TimeStampCell();
  }

  return (
    <StyledCell
      key={`${props.tableId}-row-${props.rowIndex}-column${props.cellIndex}`}
      {...props.cell.getCellProps()}
    >
      {cell}
    </StyledCell>
  );
};

TableCell.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  cellIndex: PropTypes.number.isRequired,
  cell: PropTypes.object.isRequired,
  tableId: PropTypes.string.isRequired,
};

export default TableCell;

import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Popover from "components/modal/Popover";
import PropTypes from "prop-types";
import Text from "components/form/Text";

const StyledContent = styled.span`
  cursor: default;
`;

const FuzzyTime = (props) => {
  const [refEl, setRefEl] = useState(null);
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <StyledContent
        id={`${props.id}-wrapper`}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        ref={setRefEl}
      >
        <Text size="md" m="0">
          {moment(props.time).fromNow()}
        </Text>
      </StyledContent>
      <Popover
        id={`${props.id}-popover`}
        handleClose={() => setShow(false)}
        refElement={refEl}
        placement="bottom"
        show={show}
      >
        <Text size="md" m="0">
          {moment(props.time).format("MM/DD/YYYY, h:mm:ss a")}
        </Text>
      </Popover>
    </React.Fragment>
  );
};

FuzzyTime.propTypes = {
  time: PropTypes.any,
  size: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default FuzzyTime;

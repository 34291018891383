import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent } from "redux/events/eventsSlice";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as R from "ramda";
import Link from "components/form/Link";
import Table from "components/table/Table";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import Status from "components/details/Status";
import Dropdown, { DropdownItem } from "components/menu/Dropdown";
import Icon from "components/common/Icon";
import TruncatedText from "components/common/TruncatedText";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "utils/globals.js";

const ButtonsCell = styled.div`
  display: flex;
`;

const EventsTable = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const tenant = useSelector(selectedCustomerIdSelector);

  const createSuppression = (event, suppressionType) => {
    // TODO: On a future card, check for existing suppressions
    // before returning objects with default values
    let suppression = {
      client_ids: [event.client.id],
      expire: -1,
      expire_on_resolve: false,
      reason: "",
      policy_id: null,
      check_id: suppressionType === "check" ? event.check.name : null,
    };
    props.getSuppression(suppression);
  };

  const getStatus = (properties) => {
    return <Status id={`row-${properties.row.id}-status`} status={properties.cell.value} />;
  };

  const getCheckTypeCell = (properties) => {
    let checkType = R.path(["row", "original", "check", "check_type"], properties);
    let checkName = R.path(["row", "original", "check", "name"], properties);
    let id = R.path(["row", "original", "id"], properties);
    return (
      <Link
        id={`row-${properties.row.id}-event-details-link`}
        to={`/events/${id}`}
        tooltip={t("common:tooltips.event_details")}
      >
        {checkType || checkName ? toTitleCase(checkType || checkName) : ""}
      </Link>
    );
  };

  const getAgentNameCell = (properties) => {
    let displayName = properties.value;
    let hostname = properties.row.original.client.hostname;
    let id = properties.row.original.client.id;
    let name = displayName || hostname || id;
    if (name === "Agent not found.") return <p>{name}</p>; // no link
    return (
      <Link
        id={`row-${properties.row.id}-agent-details-link`}
        to={`/agents/${id}/results`}
        tooltip={t("common:tooltips.agent_details")}
      >
        {name}
      </Link>
    );
  };

  const getDescriptionCell = (properties) => {
    return (
      <TruncatedText
        id={`description-${properties.row.id}`}
        text={properties.value || ""}
        maxLength={20}
      />
    );
  };

  const getOutputCell = (properties) => {
    let text = properties.value || "";
    return (
      <TruncatedText
        id={`output-${properties.row.id}`}
        text={text}
        maxLength={20}
        popoverStyles={{ maxWidth: "600px" }}
      />
    );
  };

  const getCellButtons = (properties) => {
    const command = R.path(["row", "original", "check", "command"], properties);
    const handleCopy = () => {
      navigator.clipboard.writeText(command);
    };

    const handleSuppressAgent = () => {
      createSuppression(props.data[properties.row.id], "agent");
      props.openSuppressConfig();
    };

    const handleSuppressCheck = () => {
      createSuppression(props.data[properties.row.id], "check");
      props.openSuppressConfig();
    };

    const handleDeleteEvent = () => {
      let event = props.data[properties.row.id];
      let params = {
        tenantId: tenant,
        clientId: event.client.id,
        checkId: event.check.name,
        eventId: event.id,
      };
      dispatch(deleteEvent(params));
    };

    return (
      <ButtonsCell>
        <Dropdown
          key={`kebab-button-${properties.row.id}`}
          id={`kebab-button-${properties.row.id}`}
          flat
          noPadding
          icon="icon-more-vert"
        >
          <DropdownItem
            id={`action-button-copy-command-${properties.row.id}`}
            className={command ? "" : " -disabled"}
            onClick={handleCopy}
          >
            <Icon id={`${properties.row.original.id}-copy-button`} icon="icon-paste" float flat />
            {t("common:events.table.dropdown_copy")}
          </DropdownItem>
          <DropdownItem
            id={`action-button-suppress-agent-${properties.row.id}`}
            onClick={handleSuppressAgent}
          >
            <Icon
              id={`${properties.row.original.id}-suppress-agent-button`}
              icon="icon-bell-off"
              float
              flat
            />
            {t("common:events.table.dropdown_suppress_agent")}
          </DropdownItem>
          <DropdownItem
            id={`action-button-suppress-check-${properties.row.id}`}
            onClick={handleSuppressCheck}
          >
            <Icon
              id={`${properties.row.original.id}-suppress-check-button`}
              icon="icon-bell-off"
              float
              flat
            />
            {t("common:events.table.dropdown_suppress_check")}
          </DropdownItem>
          <DropdownItem
            id={`action-button-delete-event-${properties.row.id}`}
            onClick={handleDeleteEvent}
          >
            <Icon
              id={`${properties.row.original.id}-delete-event-button`}
              icon="icon-delete"
              float
              flat
            />
            {t("common:events.table.dropdown_delete_event")}
          </DropdownItem>
        </Dropdown>
      </ButtonsCell>
    );
  };

  const columns = [
    {
      id: "status-cell",
      Header: "common:events.table.header_status",
      accessor: "check.status",
      Cell: getStatus,
    },
    {
      id: "check-type-cell",
      Header: "common:events.table.header_checktype",
      accessor: "check.check_type",
      Cell: getCheckTypeCell,
    },
    {
      id: "agent-name-cell",
      Header: "common:events.table.header_agentname",
      accessor: "client.display_name",
      Cell: getAgentNameCell,
    },
    {
      id: "description-cell",
      Header: "common:events.table.header_description",
      accessor: "check.description",
      Cell: getDescriptionCell,
    },
    {
      id: "output-cell",
      Header: "common:events.table.header_output",
      accessor: "check.output",
      Cell: getOutputCell,
    },
    {
      id: "occurrences-cell",
      Header: "common:events.table.header_occurrences",
      accessor: "occurrences",
    },
    {
      id: "timestamp-cell",
      Header: "common:events.table.header_timestamp",
      accessor: "check.executed",
      isTimestamp: true,
      timestampOffset: 1000,
    },
    {
      id: "buttons-cell",
      Header: "common:events.table.header_actions",
      accessor: "",
      Cell: getCellButtons,
      disableSortBy: true,
    },
  ];

  return (
    <React.Fragment>
      <Table id="events-results-table" columns={columns} data={props.data} />
    </React.Fragment>
  );
};

EventsTable.propTypes = {
  data: PropTypes.array.isRequired,
  getSuppression: PropTypes.func.isRequired,
  openSuppressConfig: PropTypes.func.isRequired,
};

export default EventsTable;

import { createSlice } from "@reduxjs/toolkit";
import { loadKey, saveKey, removeKey, APITOKEN, PREV_ROUTE } from "clients/localStorage";
import * as nav from "utils/nav";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    loading: false,
    errors: {
      loadingError: null,
    },
  },
  reducers: {
    logInFailure: (state, action) => {
      state.errors.loadingError = action.payload;
    },
    removeToken: (state) => {
      state.token = null;
    },
  },
});

export default userSlice.reducer;

export const { removeToken, logInFailure } = userSlice.actions;

export const tokenSelector = (state) => state.user.token;

// argToken is an api token, force wther or not to ignore what is in local storage
export const logIn = (history, argToken, force) => async () => {
  let token = force ? argToken : loadKey(APITOKEN) || argToken;
  if (token && token !== "null") {
    saveKey(APITOKEN, token);
    // Force is set after login.  In that case, lookup the previous route and go there after login.
    if (force) {
      const prevRoute = loadKey(PREV_ROUTE) || "/";
      history.push(prevRoute);
    }
    return token;
  } else {
    saveKey(PREV_ROUTE, nav.windowLocationNoOrigin());
    history.push("/login");
    return null;
  }
};

export const logOut = () => async (dispatch) => {
  dispatch(removeToken());
  removeKey(APITOKEN);
};

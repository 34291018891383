import settings from "utils/settings";

import * as camCustomerSlice from "cam/redux/customerSlice";
import * as edgeCustomerSlice from "edge/redux/customerSlice";

import camLoginPage from "cam/components/LoginPage";
import edgeLoginPage from "edge/components/LoginPage";

const customerSlices = {
  cam: camCustomerSlice,
  edge: edgeCustomerSlice,
};

const loginPageComponents = {
  cam: camLoginPage,
  edge: edgeLoginPage,
};

const edgePages = {
  "/events": {
    to: "/events",
    exact: false,
    title: "common:page_navigation.events",
    defaultPage: true,
  },
  "/enablement": {
    to: "/enablement",
    exact: false,
    title: "common:page_navigation.enablement",
  },
  "/agents": {
    to: "/agents",
    exact: false,
    title: "common:page_navigation.agents",
  },
};

const camPages = {
  "/events": {
    to: "/events",
    exact: false,
    title: "common:page_navigation.events",
    defaultPage: true,
  },
  "/agents": {
    to: "/agents",
    exact: false,
    title: "common:page_navigation.agents",
  },
};

const pagesBySource = {
  cam: camPages,
  edge: edgePages,
};

export const customerSlice = customerSlices[settings.source];
export const LoginPage = loginPageComponents[settings.source];
export const pages = pagesBySource[settings.source];

export const getDefaultPagePath = () => {
  const defaultPage = Object.entries(pages).find((entry) => {
    return entry[1].defaultPage != null && entry[1].defaultPage;
  });
  return defaultPage[1].to;
};

import styled from "styled-components";
import Button from "components/common/Button";
import React, { useState, useRef } from "react";
import TextInput from "components//form/TextInput";
import { logIn } from "redux/users/userSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import useKeyEvent from "hooks/useKeyEvent";

const Page = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const CardContent = styled.div`
  min-width: 500px;
  min-height: 200;
`;

const LoginPage = () => {
  const [token, setToken] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenRef = useRef(token);
  const handleLogin = async () => {
    dispatch(logIn(history, tokenRef.current, true));
  };

  const handleInput = (e) => {
    tokenRef.current = e.target.value;
    setToken(e.target.value);
  };

  useKeyEvent(handleLogin, ["Enter", "NumpadEnter"]);

  return (
    <Page>
      <div className="chi-card -portal">
        <div className="chi-card__header -sm">
          <div className="chi-card__title">Edge Orchestrator Token</div>
        </div>
        <CardContent className="chi-card__content">
          <TextInput id="login-page-text-input" onChange={handleInput}></TextInput>
        </CardContent>
        <div className="chi-card__footer">
          <Button id="login-page-login-button" onClick={handleLogin} className="-primary">
            Login
          </Button>
        </div>
      </div>
      <div />
    </Page>
  );
};

export default LoginPage;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as R from "ramda";
import styled from "styled-components";

const AsteriskWrapper = styled.div`
  margin: 0px 0px 0px 4px;
`;

const TextWrapper = styled.span`
  display: flex;
  align-items: center;
  &&& {
    line-height: normal !important;
  }
`;

const Text = (props) => {
  const { t } = useTranslation();

  const getText = () => {
    const translated = R.map((text) => t(text), props.t);

    return R.join(" ", translated);
  };

  const classes = [
    `-text--${props.size || "md"}`,
    props.lh ? ` -lh--${props.lh}` : "",
    props.m ? ` -m--${props.m}` : "",
    props.className,
  ];
  return (
    <TextWrapper id={props.id || ""} className={classes.join(" ").replace(/\s+/g, " ").trim()}>
      {props.t && getText()}
      {props.children || ""}
      {props.asterisk && (
        <AsteriskWrapper>
          <span className="-text--danger">*</span>
        </AsteriskWrapper>
      )}
    </TextWrapper>
  );
};

Text.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  lh: PropTypes.string,
  asterisk: PropTypes.bool,
  children: PropTypes.any,
  t: PropTypes.array,
  m: PropTypes.string,
};

export default Text;

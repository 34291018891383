import axios from "axios";
import * as localStorage from "clients/localStorage";
import { defaultWatcherUrl } from "clients/watcherClient";

const baseUrl = `${defaultWatcherUrl()}/eo`;

export const headers = () => {
  return {
    Authorization: `Bearer ${localStorage.loadKey(localStorage.APITOKEN)}`,
  };
};

const morpheusClient = () => {
  let client = axios.create({
    baseURL: baseUrl,
    timeout: 5000,
    headers: headers(),
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // eslint-disable-next-line
      console.error(error);
      return Promise.reject(error);
    }
  );

  return client;
};

const get = async (url, options = {}) => {
  let resp = await morpheusClient().get(url, options);
  return resp.data;
};

const getWhoami = async () => {
  return await get("/api/whoami");
};

const getServers = async () => {
  let data = await get("/api/servers");
  return data.servers;
};

const getTenants = async (max, offset) => {
  try {
    // TODO: convert tenant IDs to strings? Discrepancy between local and dev environments
    return await get(`/api/accounts`, { params: { max, offset } });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Failed to get accounts, returning users account from whoami", error);
    let whoami = await getWhoami();
    return {
      accounts: [whoami.user.account],
      meta: { total: 1 },
    };
  }
};

export default {
  getServers,
  getTenants,
  getWhoami,
};

import watcherClient from "clients/watcherClient";

const postSuppression = async (scope, body) => {
  let resp = await watcherClient().post(`/suppressions/${scope}`, body);
  return resp.data;
};

export default {
  postSuppression,
};

export const loadKey = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Failed to load key " + key, err);
    // return undefined
  }
};

// If data is an object it needs to be serialized ex: JSON.stringify()
export const saveKey = (key, data) => {
  try {
    if (data !== undefined) localStorage.setItem(key, data);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Failed to save key " + key, err);
  }
};

export const removeKey = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Failed to remove key " + key, err);
  }
};

// Local Storage Keys
export const PREFIX = "monitoring";
export const APITOKEN = `${PREFIX}.apiToken`;
export const SELECTEDCUSTOMER = `${PREFIX}.selectedCustomer`;
export const LANGUAGE = `${PREFIX}.currentLanguage`;
export const PREV_ROUTE = `${PREFIX}.previousRoute`;

import * as R from "ramda";
import morpheusClient from "edge/clients/morpheusClient";
import { createSlice, createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";
import { addNotification } from "redux/notifications/notificationSlice";

const serverAdapter = createEntityAdapter();
export const getMorpheusTenants = createAsyncThunk(
  "morpheus/getMorpheusTenants",
  async (_, { dispatch }) => {
    let max = 25;
    let data = [];
    let tmp = [];

    tmp = await morpheusClient.getTenants(max, 0).catch(() => {
      dispatch(addNotification(["danger", "Unable to fetch tenants.", true, "Error"]));
    });
    let total = tmp.meta.total;
    let numPages = Math.ceil(total / max);
    for (let i = 0; i < numPages; i++) {
      tmp = await morpheusClient.getTenants(max, data.length).catch(() => {
        dispatch(addNotification(["danger", "Unable to fetch tenants.", true, "Error"]));
      });
      data = R.concat(data, tmp.accounts);
    }
    return data;
  }
);

const initialState = serverAdapter.getInitialState({
  loading: false,
  entities: [],
  selectedTenant: null,
  ids: [],
});

const morpheusSlice = createSlice({
  name: "morpheus",
  initialState: initialState,
  reducers: {
    setTenant: (state, action) => {
      state.selectedTenant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMorpheusTenants.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMorpheusTenants.fulfilled, (state, action) => {
      state.loading = false;
      serverAdapter.setAll(state, action.payload);
    });
    builder.addCase(getMorpheusTenants.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default morpheusSlice.reducer;

export const customerSelectors = serverAdapter.getSelectors((state) => state.morpheus);
export const getCustomers = getMorpheusTenants;

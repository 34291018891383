import watcherClient from "clients/watcherClient";
import agentsApi from "clients/agentsApi";
import settings from "utils/settings";

const baseRoute = "/events/";

const getEventData = async (source, customerId) => {
  let resp = await watcherClient().get(`${baseRoute}${source}=${customerId}`, {});
  return resp.data;
};

// This adds agent display name to event data
const getEvents = (source, customerId) => {
  return Promise.all([
    agentsApi.getAgents(settings.source, customerId),
    getEventData(source, customerId),
  ]).then((resp) => {
    let [agents, events] = resp;
    let eventsPlus = events.map((event) => {
      let agent = agents.find((ag) => ag.id === event.client.id) || null;
      return {
        ...event,
        client: {
          ...event.client,
          display_name: agent ? agent.display_name : "Agent not found.",
        },
      };
    });
    return eventsPlus;
  });
};

const deleteEvent = async (source, customerId, clientId, checkId) => {
  let resp = await watcherClient().delete(
    `${baseRoute}${source}=${customerId}/${clientId}/${checkId}`
  );
  return resp.data;
};

export default {
  getEvents,
  deleteEvent,
};

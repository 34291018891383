import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import styled from "styled-components";
import Text from "components/form/Text";

const StyledAnchor = styled.span`
  cursor: default;
`;

const PopoverAnchor = (props) => {
  const omittedProps = ["getRef"];

  const [referenceElement, setReferenceElement] = useState(null);

  useEffect(() => {
    if (props.getRef) {
      props.getRef(referenceElement);
    }
  }, [referenceElement]);

  return (
    <React.Fragment>
      <StyledAnchor
        {...R.omit(omittedProps, props)}
        id={props.id}
        ref={setReferenceElement}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.t && <Text size="md" m="0" t={[props.t] || []} />}
        {props.children}
      </StyledAnchor>
    </React.Fragment>
  );
};

PopoverAnchor.propTypes = {
  id: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  getRef: PropTypes.func,
  children: PropTypes.node,
  t: PropTypes.string,
};

export default PopoverAnchor;

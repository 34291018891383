import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { LANGUAGE } from "clients/localStorage";
import moment from "moment";
// need to import each language supported (other then en)
import "moment/locale/ja";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      fallbackLng: "en",
      debug: false,
      ns: ["common", "check_types"],
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      detection: {
        order: ["querystring", "localStorage"],
        lookupQuerystring: "lang",
        lookupLocalStorage: LANGUAGE,
      },
    },
    () => {
      moment.locale(i18n.language);
    }
  );

export default i18n;

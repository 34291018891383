import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";

const StyledSVG = styled.svg`
  display: block;
  position: absolute;
  transform: rotate(0.75turn);
  circle.progress-indicator {
    stroke: ${({ theme }) => theme.grey[30]};
    fill: none;
    /* transition: stroke-dashoffset 850ms ease-in-out; */
  }
  circle.progress-bg {
    fill: none;
    stroke: transparent;
  }
`;

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Life = styled(animated.div)`
  position: absolute;
  bottom: 0;
  /* .25 rem - .0625rem from (.chi .chi-alert:not(.-banner):before)*/
  left: 0.1875rem;
  width: auto;
  background-color: #d0d4d9;
  height: 5px;
`;

const Progress = (props) => {
  const strokeWidth = props.strokeWidth;
  const size = props.size;
  // math
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const timeoutStyle = useSpring({
    from: {
      strokeDashoffset: `${circumference * 0.1}rem`,
    },
    to: {
      strokeDashoffset: `${0}rem`,
    },
    config: { tension: 125, friction: 20, precision: 0.1, duration: props.timeout },
  });
  const controlledStyle = useSpring({
    strokeDashoffset: `${((100 - props.progress) / 100) * circumference * 0.1}rem`,
  });

  return (
    <SvgWrapper
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StyledSVG className="svg" width={`${size * 0.1}rem`} height={`${size * 0.1}rem`}>
        <circle
          className="progress-bg"
          cx={`${center * 0.1}rem`}
          cy={`${center * 0.1}rem`}
          r={`${radius * 0.1}rem`}
          strokeWidth={strokeWidth}
        />
        <animated.circle
          style={props.timeout ? timeoutStyle : controlledStyle}
          className="progress-indicator"
          cx={`${center * 0.1}rem`}
          cy={`${center * 0.1}rem`}
          r={`${radius * 0.1}rem`}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference * 0.1}rem`}
        />
      </StyledSVG>
      <span style={{ position: "relative" }}>{props.children}</span>
    </SvgWrapper>
  );
};

Progress.defaultProps = {
  strokeWidth: 5,
  size: 25,
  progress: 0,
};
Progress.propTypes = {
  timeout: PropTypes.number,
  strokeWidth: PropTypes.number,
  size: PropTypes.number,
  // 0-100
  progress: PropTypes.number,
  children: PropTypes.node,
};

export default Progress;

import * as R from "ramda";
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { loadKey, saveKey, SELECTEDCUSTOMER } from "clients/localStorage";
import { customerSlice } from "utils/sourceComponents";

const navigationAdapter = createEntityAdapter();
const initialState = navigationAdapter.getInitialState({
  selectedCustomer: JSON.parse(loadKey(SELECTEDCUSTOMER) || "{}") || null,
});

// TODO: Add notification for switching tenant (whether they did it on purpose or not)

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
      saveKey(SELECTEDCUSTOMER, JSON.stringify(action.payload));
    },
  },
});

export default navigationSlice.reducer;

export const navigationTenantSelectors = navigationAdapter.getSelectors(
  (state) => state.navigation
);

export const customerListSelector = (state) => {
  return customerSlice.customerSelectors.selectAll(state);
};

export const customerSelectors = customerSlice.customerSelectors;
export const getCustomers = customerSlice.getCustomers;

export const { setSelectedCustomer } = navigationSlice.actions;

export const selectedCustomerIdSelector = (state) =>
  R.path(["navigation", "selectedCustomer", "id"], state);

export const selectedCustomerNameSelector = (state) => {
  return R.path(["navigation", "selectedCustomer", "name"], state);
};

import React, { useState } from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "components/common/Icon";
import Popover from "components/modal/Popover";
import Spinner from "components/form/Spinner";
import PopoverAnchor from "components/modal/PopoverAnchor";
import Text from "components/form/Text";
import { msaConfigOptions as options } from "utils/globals.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 5px;
`;

const StyledAnchor = styled(PopoverAnchor)`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin: 0px 5px 0px 0px !important;
  padding: 0px !important;
`;

const MSAEnabledCell = (props) => {
  const [show, setShow] = useState(props.show);
  const [cellRefElement, setCellRefElement] = useState(null);

  let sm = props.serviceManagement;

  let result = {
    os: "common:enablement.configureMSA.configOptions.off",
    db: "common:enablement.configureMSA.configOptions.off",
    ws: "common:enablement.configureMSA.configOptions.off",
    text: "common:enablement.configureMSA.configOptions.off",
  };

  let highest = "off";

  if (!R.isEmpty(sm)) {
    for (let prop in sm) {
      result[prop] = options[sm[prop]][1];
    }
    highest = sm["os"];
  }

  result.text = options[highest][1];

  const handlePopoverOpen = () => {
    setShow(true);
  };
  const handlePopoverClose = () => {
    setShow(false);
  };

  return (
    <div id={`cell-wrapper-${props.id}`} {...props.serviceManagement}>
      <Container key={`container-${props.id}`}>
        {props.isUpdating ? (
          <Spinner id={`spinner-${props.id}`} size="sm" color="primary" />
        ) : (
          <Text t={[result.text]} />
        )}
        <StyledAnchor
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          getRef={setCellRefElement}
        >
          <Icon
            id={`info-icon-${props.id}`}
            icon="icon-circle-info-outline"
            size="xs"
            className="-icon--info"
          />
        </StyledAnchor>
        <Popover
          id={"popover-" + props.id}
          refElement={cellRefElement}
          placement="right"
          show={show}
          handleClose={handlePopoverClose}
        >
          <FlexDiv>
            <FlexDiv id={`popover-${props.id}-os`}>
              <StyledText size="md" lh="0" t={["common:enablement.msa_os", result.os]} />
            </FlexDiv>
            <FlexDiv id={`popover-${props.id}-ws`}>
              <StyledText size="md" lh="0" t={["common:enablement.msa_ws", result.ws]} />
            </FlexDiv>
            <FlexDiv id={`popover-${props.id}-db`}>
              <StyledText size="md" lh="0" t={["common:enablement.msa_db", result.db]} />
            </FlexDiv>
          </FlexDiv>
        </Popover>
      </Container>
    </div>
  );
};

MSAEnabledCell.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  serviceManagement: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool,
};

export default MSAEnabledCell;

import React from "react";
import Header from "components/common/Header";
import ContextNavigation from "components/navigation/ContextNavigation";

import { pages } from "utils/sourceComponents";
const Navigation = () => {
  // TODO move ContextNavigation Somewhere else based on wireframes whenever we get them
  return <Header headerStart={<ContextNavigation />} links={pages} />;
};

export default Navigation;

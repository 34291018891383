import React, { useEffect, useState } from "react";
import NavBrand from "assets/NavBrandIcon.svg";
import { Link, useRouteMatch } from "react-router-dom";
import * as R from "ramda";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledNav = styled.nav`
  &&& {
    ${({ theme }) => theme.only.xs} {
      padding: 0 1rem;
    }
    ${({ theme }) => theme.only.sm} {
      padding: 0 1.5rem;
    }
    ${({ theme }) => theme.only.md} {
      padding: 0 2rem;
    }
    ${({ theme }) => theme.up.lg} {
      padding: 0 6rem;
    }
  }
`;

const LinkWrapper = (props) => {
  let match = useRouteMatch({
    path: props.to,
    exact: props.exact || false,
  });
  useEffect(() => {
    if (match) props.setSelectedLink(props.to);
  }, [match]);

  return (
    <li ref={props.setRef}>
      <Link id={`header-link-${props.to}`} to={props.to}>
        {props.children}
      </Link>
    </li>
  );
};

LinkWrapper.propTypes = {
  exact: PropTypes.bool,
  children: PropTypes.node,
  setSelectedLink: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
};

const Header = (props) => {
  const [tabOffsets, setTabOffsets] = useState({});
  const [selectedLink, setSelectedLink] = useState("");
  const [links, setLinks] = useState([]);
  const sliderStyles = useSpring({
    width: tabOffsets[selectedLink] && tabOffsets[selectedLink][0],
    left: tabOffsets[selectedLink] && tabOffsets[selectedLink][1],
    display: "unset",
  });
  const { t } = useTranslation();

  useEffect(() => {
    const tmpOffsets = {};
    let tmpCount = 0;
    setLinks(
      R.map((x) => {
        const setRef = (el) => {
          if (!el) return;
          let margin = Number(window.getComputedStyle(el).marginRight.replace("px", ""));
          let width = el.offsetWidth;
          tmpOffsets[x[1].to] = [width, tmpCount];

          tmpCount += margin + width;
        };

        return (
          <LinkWrapper
            setRef={setRef}
            setSelectedLink={setSelectedLink}
            exact={x[1].exact}
            key={x[1].to}
            to={x[1].to}
          >
            {t(x[1].title)}
          </LinkWrapper>
        );
      }, R.toPairs(props.links) || [])
    );

    setTabOffsets(tmpOffsets);

    if (window.chi) {
      window.chi.mobilenav(document.getElementById("mobile-navigation-trigger"));
    }
  }, []);

  const getMobileLinks = () => {
    return R.map((link) => {
      return (
        <li key={`key-${link[1].to}`}>
          <Link to={link[1].to}>
            <i className="chi-icon icon-atom"></i>
            <span>{t(link[1].title)}</span>
          </Link>
        </li>
      );
    }, R.toPairs(props.links) || []);
  };

  return (
    <React.Fragment>
      <header id="watcher-header" className="chi-header -portal -navbar">
        <StyledNav className="chi-header__content">
          <div className="chi-header__brand">
            <a className="chi-brand" href="#" aria-label="Lumen">
              <img src={NavBrand} />
            </a>
          </div>
          <div className="chi-header__start">
            {/* TODO automatically move headerStart into a drawer when burger menu is shown */}
            <div className="-d--flex -d-lg--none">
              <button
                className="chi-button -icon -flat chi-drawer__trigger"
                id="mobile-navigation-trigger"
                data-target="#mobile-nav-1"
                aria-label="Toggle navigation"
              >
                <div className="chi-button__content">
                  <i className="chi-icon -sm--2 icon-menu" />
                </div>
              </button>
            </div>
            {props.headerStart}
          </div>
          <div className="chi-header__end">
            {/* TODO automatically move headerEnd into a drawer when burger menu is shown */}
            {props.headerEnd}
          </div>
        </StyledNav>
        <StyledNav className="chi-header__navbar">
          <div className="chi-header__start">
            <div className="-d--none -d-lg--flex">
              <ul className="chi-tabs -inverse -xs">
                {links}
                <animated.li className="chi-sliding-border" style={sliderStyles}></animated.li>
              </ul>
            </div>
          </div>
          <div className="chi-header__end">
            {/* <ul className="chi-header__navbar-menu">{getLinks(props.links)}</ul> */}
          </div>
        </StyledNav>
      </header>

      <div className="chi-mobile-nav" id="mobile-nav-1">
        <div className="chi-backdrop -closed -position--absolute">
          <div className="chi-backdrop__wrapper">
            <div className="chi-drawer -left -animated chi-mobile-nav__first-level">
              <div className="-position--relative">
                <div className="chi-mobile-nav__first-level-content -w--100">
                  <div className="chi-drawer__header chi-mobile-nav__header">
                    <a className="chi-brand" href="#" aria-label="Lumen">
                      <img src={NavBrand} />
                    </a>
                    <button className="chi-button -icon -close" aria-label="Close">
                      <div className="chi-button__content">
                        <i className="chi-icon icon-x"></i>
                      </div>
                    </button>
                  </div>
                  <div className="chi-drawer__content chi-mobile-nav__content">
                    <ul className="chi-tabs -vertical -icons">{getMobileLinks()}</ul>
                    <div className="chi-divider"></div>
                  </div>
                </div>
              </div>
              <div className="chi-mobile-nav__drawers">
                <div className="chi-drawer -right -position--absolute" id="second-level-1">
                  <div className="chi-drawer__header chi-mobile-nav__header">
                    <button className="chi-button -icon -close" aria-label="Close"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  headerStart: PropTypes.node,
  headerEnd: PropTypes.node,
  links: PropTypes.object,
};

export default Header;

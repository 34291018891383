import React, { Suspense } from "react";
import "utils/i18n";
import store from "redux/store";
import ReactDOM from "react-dom";
import App from "components/App";
import Debug from "components/Debug";
import { Provider } from "react-redux";
import Swagger from "components/swagger/Swagger";
import { Switch, Route, Redirect } from "react-router-dom";
import watcherTheme from "watcherTheme";
import { BrowserRouter as Router } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import styled from "styled-components";

import { LoginPage } from "utils/sourceComponents";

const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
  }

  input[type="checkbox" i]:focus .chi-checkbox__input{
    outline: none !important;
  }
`;

const Loading = () => {
  return (
    <LoaderDiv>
      <div>loading...</div>
    </LoaderDiv>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <ThemeProvider theme={watcherTheme}>
        <Suspense fallback={<Loading />}>
          <Router>
            <Switch>
              <Route exact path="/login">
                <LoginPage />
              </Route>
              {/* <Route
              path="/callback"
              render={(routeProps) => (
                <Callback
                  onSuccess={() => {
                    // this function is also passed `user` if we need to do anything with it
                    // there is also in onFailure prop as well.
                    routeProps.history.push("/");
                  }}
                  userManager={userManager}
                />
              )}
            /> */}
              <Route exact path="/debug">
                <Debug />
              </Route>
              <Route path="/docs/swagger">
                <Redirect to="/docs/swagger/agents" />
                <Swagger />
              </Route>
              <Route path="*">
                <App />
              </Route>
            </Switch>
          </Router>
        </Suspense>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

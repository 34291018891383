import React from "react";
import IconCell from "components/table/IconCell";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { useTranslation } from "react-i18next";

const StyledStatus = styled(IconCell)`
  && {
    color: ${(props) => props.color};
    margin-right: 5px;
  }
`;

const Status = (props) => {
  const { t } = useTranslation();

  let result = {
    color: "",
    icon: "",
    text: "",
  };

  switch (props.status) {
    case 0:
      result.color = props.theme.success;
      result.icon = "icon-circle-check";
      result.text = t("common:details.status.okay");
      break;
    case 1:
      result.color = props.theme.warning;
      result.icon = "icon-circle-warning";
      result.text = t("common:details.status.warning");
      break;
    case 2:
      result.color = props.theme.error;
      result.icon = "icon-circle-x";
      result.text = t("common:details.status.critical");
      break;
    default:
      result.color = props.theme.muted;
      result.icon = "icon-circle-question";
      result.text = t("common:details.status.unknown");
  }

  return (
    <StyledStatus id={props.id} icon={result.icon} color={result.color}>
      {result.text}
    </StyledStatus>
  );
};

Status.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default withTheme(Status);

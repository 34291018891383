import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ToggleSwitch = (props) => {
  const { t } = useTranslation();
  return (
    <div className="chi-form__item">
      <label className="chi-switch" htmlFor={props.id}>
        {props.leftLabel ? <span className="chi-switch__label">{t(props.leftLabel)}</span> : ""}
        <input type="checkbox" className="chi-switch__input" id={props.id} />
        <span className="chi-switch__content">
          <span className="chi-switch__thumb"></span>
        </span>
        {props.rightLabel ? <span className="chi-switch__label">{t(props.rightLabel)}</span> : ""}
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default ToggleSwitch;

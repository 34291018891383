import * as R from "ramda";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "components/form/Text";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/common/Button";
import TextArea from "components/form/TextArea";
import CheckBox from "components/form/CheckBox";
import DatePicker from "components/form/DatePicker";
import { postSuppression } from "redux/suppression/suppressionSlice";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import PageTitle from "components/common/PageTitle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 19rem;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const CurrentSuppressed = styled.div`
  display: flex;
`;

const StyledButton = styled.div`
  margin: ${(props) => (props.hasDelete ? "0 0.5rem 0 0" : "0 0.5rem")};
`;

const StyledInfoText = styled(Text)`
  margin-right: 5px;
`;

const StyledExpiration = styled.div`
  margin: 2rem 0 0 0;
`;

const StyledExpirationOnResolve = styled.div`
  margin: 2rem 0 2rem 0;
`;

const StyledContent = styled.div``;

const SuppressionConfig = (props) => {
  const [suppression, setSuppression] = useState(props.suppression || {});
  const dispatch = useDispatch();
  const defaultTime = props.suppression.expire
    ? moment(moment().valueOf() + suppression.expire * 100)
    : moment().add(1, "hour");
  const customerId = useSelector(selectedCustomerIdSelector);

  const handleSuppression = async () => {
    const ops = {
      scope: `edge_customer=${customerId}`,
      body: suppression,
    };
    await dispatch(postSuppression(ops));
    return props.onSuppress && props.onSuppress();
  };

  const handleReasonInput = (e) => {
    let tempSuppression = R.clone(suppression);
    tempSuppression.reason = e;
    setSuppression(tempSuppression);
  };

  const handleExpire = (e) => {
    let tempSuppression = R.clone(suppression);
    tempSuppression.expire = e.unix() - moment().unix();
    setSuppression(tempSuppression);
  };

  const handleExpireOnRedsolve = (e) => {
    let tempSuppression = R.clone(suppression);
    tempSuppression.expire_on_resolve = e;
    setSuppression(tempSuppression);
  };
  const handleExpireNever = (e) => {
    let tempSuppression = R.clone(suppression);
    tempSuppression.expire = e ? -1 : defaultTime.unix() - moment().unix();
    setSuppression(tempSuppression);
  };

  const handleSave = () => {
    props.handleClose();
    return handleSuppression();
  };

  return (
    <Wrapper>
      <PageTitle size="h4" t={["common:suppression.config_title"]} />
      <Content id="suppression-config-panel">
        <Left>
          <div className="chi-card">
            <div className="chi-card__header -sm">
              <Text className="chi-card__title" size="lg" t={["common:suppression.title_agents"]} />
            </div>
            <StyledContent className="chi-card__content">
              <div className="chi-card__caption">
                {R.map(
                  (id) => (
                    <div key={id}>{id}</div>
                  ),
                  suppression.client_ids || []
                )}
              </div>
            </StyledContent>
          </div>
          {props.suppression.check_id ? (
            <div className="chi-card">
              <div className="chi-card__header -sm">
                <Text
                  className="chi-card__title"
                  size="lg"
                  t={["common:suppression.title_check"]}
                />
              </div>
              <div className="chi-card__content">
                <div className="chi-card__caption">{props.suppression.check_id}</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Left>
        <Center>
          <div>
            <CurrentSuppressed>
              <StyledInfoText
                className="-text--bold"
                t={["common:suppression.text_currently_suppressed"]}
              />
              <Text>{props.suppression.id ? "Yes" : "No"}</Text>
            </CurrentSuppressed>
            <div className="chi-divider" />
          </div>
          <div>
            <div>
              <CheckBox
                label="common:suppression.checkbox_never_expire"
                checked={Boolean(suppression.expire === -1)}
                id="suppression-expire-never"
                onChange={handleExpireNever}
              />
              <StyledExpiration>
                <StyledInfoText
                  className="-text--bold"
                  t={["common:suppression.text_suppression_expiration"]}
                />
                <DatePicker
                  defaultDate={defaultTime}
                  onChange={handleExpire}
                  id="suppression-config-date-picker"
                  disablePast
                  disabled={Boolean(suppression.expire === -1)}
                />
              </StyledExpiration>
            </div>
            <StyledExpirationOnResolve>
              <CheckBox
                id="suppression-expire-on-resolve"
                value={false}
                onChange={handleExpireOnRedsolve}
                label="common:suppression.checkbox_auto_expire"
              />
            </StyledExpirationOnResolve>
            <TextArea
              label="common:suppression.text_reason"
              onChange={handleReasonInput}
              id="suppression-reason-input"
            />
          </div>
          <div className="chi-divider" />
          <Buttons>
            <Button
              onClick={props.handleClose}
              id="suppression-cancel-button"
              t={["common:common.button_cancel"]}
            />
            <StyledButton hasDelete={props.suppression.id}>
              {props.suppression.id ? (
                <Button id="suppression-delete-button" danger t={["common:common.button_delete"]} />
              ) : (
                ""
              )}
            </StyledButton>
            <Button
              id="suppression-save-button"
              onClick={handleSave}
              className="-primary"
              t={["common:common.button_save"]}
            />
          </Buttons>
        </Center>
      </Content>
    </Wrapper>
  );
};

SuppressionConfig.propTypes = {
  // Callback for after the suppression has been made
  onSuppress: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  suppression: PropTypes.object.isRequired,
};

export default SuppressionConfig;

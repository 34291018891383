import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const TextArea = (props) => {
  const [value, setValue] = useState(props.defaultValue);
  const { t } = useTranslation();

  const handleChange = (e) => {
    props.onChange(e.target.value);
    setValue(e.target.valuea);
  };
  return (
    <div className="chi-form__item">
      {props.label ? (
        <label
          className={`chi-label ${props.className}`}
          htmlFor={props.id}
          optional={props.optional}
          required={props.required}
        >
          {t(props.label)}
        </label>
      ) : (
        ""
      )}
      {props.disabled ? (
        <textarea
          value={props.defaultValue}
          className={`chi-input ${props.size || "-md"}`}
          style={props.style || {}}
          id={props.id}
          placeholder={t(props.placeholder)}
          disabled={props.disabled}
          readOnly={props.readOnly}
          required={props.required}
          onChange={handleChange}
        />
      ) : (
        <textarea
          value={value}
          className={`chi-input ${props.size || "-md"}`}
          style={props.style || {}}
          id={props.id}
          placeholder={t(props.placeholder)}
          disabled={props.disabled}
          readOnly={props.readOnly}
          required={props.required}
          onChange={handleChange}
        />
      )}
      {props.description || (
        <div className={`chi-label -status${props.error ? " -danger" : ""}`}>
          {t(props.description)}
        </div>
      )}
    </div>
  );
};

TextArea.propTypes = {
  readOnly: PropTypes.bool,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  style: PropTypes.object, // ex: {height: "400px"}
};

export default TextArea;

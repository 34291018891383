import { useEffect } from "react";
import * as R from "ramda";

const useKeyEvent = (callBack, keys) => {
  // if callBack is using sate That data needs to be safed in a ref(useRef)
  useEffect(() => {
    const handleEscape = (event) => {
      if (R.find(R.equals(event.code))(keys)) {
        callBack();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);
};

export default useKeyEvent;

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "redux/users/userSlice";
import enablementSlice from "redux/enablement/enablementSlice";
import eventsSlice from "redux/events/eventsSlice";
import morpheusSlice from "edge/redux/customerSlice";
import camSlice from "cam/redux/customerSlice";
import navigationSlice from "redux/navigation/navigationSlice";
import suppressionSlice from "redux/suppression/suppressionSlice";
import agentsSlice from "redux/agents/agentsSlice";
import notificationSlice from "redux/notifications/notificationSlice";
import resultsSlice from "redux/results/resultsSlice";

const rootReducer = combineReducers({
  user: userSlice,
  enablement: enablementSlice,
  events: eventsSlice,
  morpheus: morpheusSlice,
  cam: camSlice,
  navigation: navigationSlice,
  agents: agentsSlice,
  suppression: suppressionSlice,
  notification: notificationSlice,
  results: resultsSlice,
});

export default configureStore({ reducer: rootReducer });

import * as R from "ramda";
import moment from "moment";
import PropTypes from "prop-types";
import Popover from "components/modal/Popover";
import Button from "components/common/Button";
import React, { useState, useEffect } from "react";

import styled from "styled-components";
import IconButton from "../common/IconButton";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 17.5rem;
`;

const StyledCalendar = styled.div`
  align-self: start;
`;

const DateTimePicker = (props) => {
  let currentDate = moment();

  const [selectedDate, setSelectedDate] = useState(
    moment(props.defaultDate || moment(currentDate))
  );

  const [controllerDate, setControllerDate] = useState(moment(currentDate));

  const [calendarReferenceElement, setCalendarReferenceElement] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    props.onChange(selectedDate);
  }, [selectedDate]);

  const handlePrevMonth = () => {
    const tempDate = moment(controllerDate);
    tempDate.subtract(1, "month");
    setControllerDate(tempDate);
  };

  const handleNextMonth = () => {
    const tempDate = moment(controllerDate);
    tempDate.add(1, "month");
    setControllerDate(tempDate);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleToggle = () => {
    if (props.disabled) return;
    setShow(!show);
  };

  const handleSetPM = () => {
    const tempDate = moment(selectedDate);
    if (tempDate.hour() < 12) {
      setSelectedDate(tempDate.add(12, "hour"));
    }
  };

  const handleSetAM = () => {
    const tempDate = moment(selectedDate);
    if (tempDate.hour() > 11) {
      setSelectedDate(tempDate.subtract(12, "hour"));
    }
  };

  const getDays = () => {
    return R.times((e) => {
      // Have to add + because moment().date(1) is the first day of the month and
      // moment().date(0) is the last day of the prev month
      const dayDate = e + 1;
      const isDisabled =
        props.disablePast &&
        moment()
          .date(dayDate)
          .month(controllerDate.month())
          .year(controllerDate.year())
          .isBefore(currentDate);

      const isSelected =
        selectedDate.date() === dayDate &&
        selectedDate.month() === controllerDate.month() &&
        selectedDate.year() === controllerDate.year();
      const isToday =
        currentDate.date() === dayDate &&
        currentDate.month() === controllerDate.month() &&
        currentDate.year() === controllerDate.year();
      const handleSelectDate = () => {
        return (
          isDisabled ||
          setSelectedDate(
            moment(selectedDate)
              .date(dayDate)
              .month(controllerDate.month())
              .year(controllerDate.year())
          )
        );
      };

      return (
        <div
          id={`${props.id}-datepicker-day-${e + 1}`}
          key={`hi-datepicker__day-${dayDate}`}
          onClick={handleSelectDate}
          className={`chi-datepicker__day${isSelected ? " -active" : ""}${
            isDisabled ? " -inactive" : ""
          }${isToday ? " -today" : ""}`}
        >
          {e + 1}
        </div>
      );
    }, controllerDate.daysInMonth());
  };

  return (
    <React.Fragment>
      <Button
        disabled={props.disabled}
        id={`${props.id}-button`}
        iconRight="icon-date"
        onClick={handleToggle}
        getRef={setCalendarReferenceElement}
      >
        {selectedDate.format("MM/DD/YYYY, LTS")}
      </Button>
      <Popover
        id={`${props.id}-popover`}
        refElement={calendarReferenceElement}
        show={show}
        handleClose={handleClose}
        placement="bottom"
      >
        <Wrapper>
          <StyledCalendar
            className={`chi-datepicker -week-starts-on-sun -month-starts-on-${moment(controllerDate)
              .startOf("month")
              .format("ddd")
              .toLowerCase()}`}
          >
            <div className="chi-datepicker__month-row">
              <IconButton
                id={`prev-month-${props.id}`}
                icon="icon-chevron-left"
                onClick={handlePrevMonth}
                flat
              />
              <div className="chi-datepicker__month">{controllerDate.format("MMM YYYY")}</div>
              <IconButton
                id={`next-month-${props.id}`}
                icon="icon-chevron-right"
                onClick={handleNextMonth}
                flat
              />
            </div>
            <div className="chi-datepicker__day-names">
              <div className="chi-datepicker__week-day">S</div>
              <div className="chi-datepicker__week-day">M</div>
              <div className="chi-datepicker__week-day">T</div>
              <div className="chi-datepicker__week-day">W</div>
              <div className="chi-datepicker__week-day">T</div>
              <div className="chi-datepicker__week-day">F</div>
              <div className="chi-datepicker__week-day">S</div>
            </div>
            <div className="chi-datepicker__days">{getDays()}</div>
          </StyledCalendar>
          <div className="chi-time-picker">
            <div className="chi-time-picker__content">
              <div className="chi-time-picker__hours">
                {/* <div
                className={`chi-time-picker__hour${
                  selectedDate.hour() === 0 || selectedDate.hour() === 12 ? " -active" : ""
                }`}
              >
                12
              </div> */}
                {R.times((e) => {
                  const handleSetHour = () => {
                    const tempDate = moment(selectedDate);
                    if (tempDate.hours() > 11) {
                      setSelectedDate(tempDate.set("hour", e + 12));
                    } else {
                      setSelectedDate(tempDate.set("hour", e));
                    }
                  };

                  return (
                    <div
                      key={`chi-time-picker__hour-${e}`}
                      onClick={handleSetHour}
                      className={`chi-time-picker__hour${
                        selectedDate.hour() % 12 === e ? " -active" : ""
                      }`}
                    >
                      {e === 0 ? "12" : (e + "").padStart(2, "0")}
                    </div>
                  );
                }, 12)}
              </div>
              <div className="chi-time-picker__minutes">
                {R.times((e) => {
                  const handleSetMinutes = () => {
                    const tempDate = moment(selectedDate);
                    setSelectedDate(tempDate.set("minute", e));
                  };
                  return (
                    <div
                      key={`chi-time-picker__minute-${e}`}
                      onClick={handleSetMinutes}
                      className={`chi-time-picker__minute${
                        selectedDate.minute() === e ? " -active" : ""
                      }`}
                    >
                      {(e + "").padStart(2, "0")}
                    </div>
                  );
                }, 60)}
              </div>
              <div className="chi-time-picker__periods">
                <div
                  onClick={handleSetAM}
                  className={`chi-time-picker__period${selectedDate.hour() < 12 ? " -active" : ""}`}
                >
                  AM
                </div>
                <div
                  onClick={handleSetPM}
                  className={`chi-time-picker__period${selectedDate.hour() > 11 ? " -active" : ""}`}
                >
                  PM
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </Popover>
    </React.Fragment>
  );
};

DateTimePicker.propTypes = {
  disabled: PropTypes.bool,
  defaultDate: PropTypes.any,
  disablePast: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default DateTimePicker;

import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import IconButton from "components/common/IconButton";
import { useTranslation } from "react-i18next";
// Has to be an odd number
const visibleButtons = 3;
// Required page buttons are the first 2, last 2, and the visibleButtons count in the middle.
// Only need to be worried about ellipses if the total pages is more then that.
const MIN_REQ_PAGE_BUTTONS = 4 + visibleButtons;

const Pagination = (props) => {
  const justBeforeCurrentPage = (index, currentPageIndex) => {
    return index >= currentPageIndex - Math.floor(visibleButtons / 2) && index < currentPageIndex;
  };

  const currentPageCloseToFirst = (index, currentPageIndex) => {
    return index - visibleButtons - 1 <= 0 && index >= currentPageIndex;
  };

  const justAfterCurrentPage = (index, currentPageIndex) => {
    return index <= currentPageIndex + Math.floor(visibleButtons / 2) && index > currentPageIndex;
  };

  const currentPageCloseToLast = (index, currentPageIndex, pageCount) => {
    return index + visibleButtons + 1 >= pageCount - 1 && index <= currentPageIndex;
  };

  const currentPage = (index, currentPageIndex) => {
    return index === currentPageIndex;
  };

  const firstPage = (index) => {
    return index === 0;
  };

  const lastPage = (index, pageCount) => {
    return index === pageCount - 1;
  };

  const shouldRender = (index) => {
    if (props.pageCount <= MIN_REQ_PAGE_BUTTONS) {
      return true;
    }

    return (
      currentPage(index, props.pageIndex) ||
      firstPage(index) ||
      lastPage(index, props.pageCount) ||
      justBeforeCurrentPage(index, props.pageIndex) ||
      justAfterCurrentPage(index, props.pageIndex) ||
      currentPageCloseToFirst(index, props.pageIndex) ||
      currentPageCloseToLast(index, props.pageIndex, props.pageCount)
    );
  };

  const { t } = useTranslation();

  return (
    <nav
      className={`chi-pagination${" " + props.className || ""}`}
      role="navigation"
      aria-label="Pagination"
    >
      <div className="chi-pagination__content">
        <div className="chi-pagination__start">
          <div className="chi-pagination__results">
            <span className="chi-pagination__results-label">
              {props.resultCount} {t("common:pagination.results")}
            </span>
          </div>
          <div className="chi-pagination__page-size">
            <select
              className="chi-input"
              value={props.pageSize}
              onChange={(e) => {
                props.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 15, 20, 25, 30].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <span className="chi-pagination__label">{t("common:pagination.per_page")}</span>
          </div>
        </div>
        <div className="chi-pagination__center">
          <div className="chi-pagination__button-group chi-button-group">
            <IconButton
              id="pagination-prev-page"
              flat
              onClick={props.previousPage}
              icon="icon-chevron-left"
            />

            {R.times((index) => {
              const handleGoTo = () => {
                props.gotoPage(index);
              };
              if (shouldRender(index)) {
                return (
                  <React.Fragment key={`pagination-button-fragment-${index}`}>
                    {!shouldRender(index - 1) && index === props.pageCount - 1 ? (
                      <div
                        key={`pagination-left-dots-${index}`}
                        className="chi-button -flat"
                        aria-hidden="true"
                        disabled
                      >
                        ...
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      key={`pagination-button-${index}`}
                      id={`pagination-button-${index}`}
                      onClick={handleGoTo}
                      className={`chi-button -flat${props.pageIndex === index ? " -active" : ""}`}
                      aria-label="Page 1"
                      type="button"
                    >
                      {index + 1}
                    </button>
                    {!shouldRender(index + 1) && index === 0 ? (
                      <div
                        key={`pagination-right-dots-${index}`}
                        className="chi-button -flat"
                        aria-hidden="true"
                        disabled
                      >
                        ...
                      </div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              }
            }, props.pageCount || 0)}
            <IconButton
              id={`pagination-next-page`}
              flat
              onClick={props.nextPage}
              icon="icon-chevron-right"
            />
          </div>
        </div>
        <div className="chi-pagination__end"></div>
      </div>
    </nav>
  );
};

Pagination.defaultProps = {
  pageSize: 25,
};

Pagination.propTypes = {
  pageSize: PropTypes.number,
  resultCount: PropTypes.number,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageOptions: PropTypes.array,
  setPageSize: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default Pagination;

import * as R from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Table from "components/table/Table";
import Status from "components/details/Status";

const getStatusCell = (props) => {
  return <Status id={`row-${props.row.id}-severity`} status={props.row.original.status} />;
};
const getStatusAccesssor = (t) => (action) => {
  const statuses = [
    "common:events.status.okay",
    "common:events.status.warning",
    "common:events.status.critical",
    "common:events.status.unknown",
  ];
  return t(statuses[action.status]);
};

const getAutomaticAccessor = (t) => (action) => {
  return action.automatic ? t("common:actions.automatic") : t("common:actions.manual");
};
const EventActionsTab = styled.div``;
const EventActions = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      Header: "common:actions.type",
      accessor: "action_type", // accessor is the "key" in the data
    },
    {
      Header: "common:actions.status",
      accessor: getStatusAccesssor(t), // accessor is the "key" in the data
      Cell: getStatusCell,
    },
    {
      Header: "common:actions.automatic",
      accessor: getAutomaticAccessor(t), // accessor is the "key" in the data
    },
    {
      Header: "common:actions.description",
      accessor: "description", // accessor is the "key" in the data
    },
    {
      Header: "common:actions.executed",
      accessor: "timestamp",
      isTimestamp: true,
      timestampOffset: 1000,
    },
    {
      Header: "common:actions.output",
      accessor: "output", // accessor is the "key" in the data
    },
  ];

  const data = R.values(R.path(["event", "event_handlers"], props)) || [];
  return (
    <EventActionsTab>
      <Table id="event-actions" columns={columns} data={data} />
    </EventActionsTab>
  );
};

EventActions.propTypes = {};

export default EventActions;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledIcon = styled.i`
  && {
    color: ${(props) => props.color} !important;
  }
`;

const Icon = (props) => {
  return (
    <StyledIcon
      id={props.id}
      className={`chi-icon ${props.icon} -${props.size || "sm"} ${props.className || ""}`}
      color={props.color}
    />
  );
};

Icon.propTypes = {
  size: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Icon;

import React from "react";
import PropTypes from "prop-types";

const Spinner = (props) => {
  return (
    <div id={props.id} className={props.className}>
      <svg
        className={`chi-spinner -icon--${props.color} -${props.size || "md"}`}
        viewBox="0 0 66 66"
      >
        <title>Loading</title>
        <circle className="path" cx="33" cy="33" r="30" fill="none" strokeWidth="6"></circle>
      </svg>
    </div>
  );
};

Spinner.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Spinner;

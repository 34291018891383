import * as R from "ramda";
import PropTypes from "prop-types";
import styled from "styled-components";
import React, { useEffect } from "react";
import CheckBox from "components/form/CheckBox";
import { useTranslation } from "react-i18next";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import Pagination from "components/common/Pagination";
import TextInput from "components/form/TextInput";
import IconButton from "components/common/IconButton";
import Icon from "components/common/Icon";
import Divider from "components/common/Divider";
import Spinner from "components/form/Spinner";
import TableCell from "components/table/TableCell";

// This is a workaround for using custom attributes with styled components.
// styled components filters non-standard attributes by default for known html elements.
// using a custom component with it will "trick" it into passing along non-standard attributes.
// these are used to sometimes add metadata onto the row, such as serverid and rowid.
const CustomRow = (props) => {
  // eslint-disable-next-line no-unused-vars
  var { loading, ...rowPrps } = props;
  return <tr {...rowPrps}>{props.children}</tr>;
};

CustomRow.propTypes = {
  children: PropTypes.array,
  loading: PropTypes.bool,
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.grey[10]};
  display: flex;
  flex-direction: column;
`;
// Row spacer so that the footer does not move when the number of results
//shown on current page differs from next/prev pages.
// const TableSpacerRow = styled.tr`
//   /* essentially disables the hover over */
//   background-color: transparent !important;
// `;

// const SpacerRowDiv = styled.div`
//   /* set to lineheight of text in a table */
//   height: 1rem;
// `;

const HeaderWrapper = styled.div`
  padding: 1rem;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const SortIconWrapper = styled.span`
  margin-left: 0.5rem;
`;

const TableFooter = styled.div`
  padding: 1.5rem 1rem;
`;

const StyledRow = styled(CustomRow)`
  height: 3.5rem;
  opacity: ${(props) => (props.loading ? "0.4" : "1.0")};
`;

const StyledHeader = styled.tr`
  height: 40px !important;
`;

const StyledHeaderCell = styled.th`
  padding: 0 1rem !important;
`;

const StyledDivider = styled(Divider)`
  margin: 0 1rem !important;
`;

const StyledTBody = styled.tbody`
  position: relative;
`;

const PaginationStyle = styled(Pagination)``;

const LoadingOverlay = styled(Spinner)`
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const GlobalFilter = ({ globalFilter, setGlobalFilter, id }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  }, 200);

  return (
    <span>
      <TextInput
        id={id}
        placeholder={t("common:common.search")}
        iconRight="icon-search"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
};

GlobalFilter.propTypes = {
  setGlobalFilter: PropTypes.func,
  globalFilter: PropTypes.string,
  id: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/prop-types
const IndeterminateCheckbox = ({ onChange, checked, indeterminate, id }) => {
  const onChangeEvent = (val) => {
    onChange({ target: { checked: val } });
  };
  return (
    <CheckBox indeterminate={indeterminate} checked={checked} id={id} onChange={onChangeEvent} />
  );
};

const mapIndexed = R.addIndex(R.map);

// TODO: Consider amending table to allow user to add or remove certain columns

// https://react-table.tanstack.com/docs/quick-start
const Table = (props) => {
  const columns = React.useMemo(() => props.columns || [], [props.columns]);

  const data = React.useMemo(() => props.data || [], [props.data]);

  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { selectedRowIds, pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: props.selectedRowIds || {},
        pageSize: props.defaultPageSize,
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    props.selectable ? useRowSelect : () => "",
    (hooks) => {
      if (props.selectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            // eslint-disable-next-line react/display-name, react/prop-types
            Header: (header) => (
              <IndeterminateCheckbox
                id={`select-all`}
                {...header.getToggleAllRowsSelectedProps()}
              />
            ),
            // eslint-disable-next-line react/display-name, react/prop-types
            Cell: (cell) => {
              return (
                <IndeterminateCheckbox
                  id={`row-select-${cell.row.id}`}
                  {...cell.row.getToggleRowSelectedProps()}
                />
              );
            },
          },
          ...columns,
        ]);
      }
    }
  );
  useEffect(() => {
    if (props.getSelectedRowIds) {
      props.getSelectedRowIds(selectedRowIds);
    }
  }, [selectedRowIds]);
  return (
    <Wrapper>
      <HeaderWrapper className="">
        <LeftHeader>
          <GlobalFilter
            id={`table-search-${props.id}`}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <StyledDivider vertical />
          <IconButton
            onClick={() => ""}
            id={`table-filter-${props.id}`}
            noPadding
            flat
            icon="icon-filter"
          />
        </LeftHeader>
      </HeaderWrapper>
      <table className="chi-table -portal" {...getTableProps()} id={props.id}>
        <thead>
          {
            // Loop over the header rows
            mapIndexed((headerGroup, headerGroupIndex) => {
              return (
                <StyledHeader {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    mapIndexed((column, columnIndex) => {
                      return (
                        <StyledHeaderCell
                          key={`header${props.id}-row${headerGroupIndex}-column${columnIndex}`}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          {column.id !== "selection" && column.disableSortBy !== true ? (
                            <React.Fragment>
                              {t(column.render("Header"))}
                              <SortIconWrapper>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <Icon
                                      id={`table-sorting-${column.id}-column`}
                                      size="xs"
                                      icon="icon-arrow-down"
                                    />
                                  ) : (
                                    <Icon
                                      id={`table-sorting-${column.id}-column`}
                                      size="xs"
                                      icon="icon-arrow-up"
                                    />
                                  )
                                ) : (
                                  <Icon
                                    id={`table-sorting-${column.id}-column`}
                                    size="xs"
                                    icon="icon-arrow-sort"
                                  />
                                )}
                              </SortIconWrapper>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </StyledHeaderCell>
                      );
                    }, headerGroup.headers)
                  }
                </StyledHeader>
              );
            }, headerGroups)
          }
        </thead>
        <StyledTBody {...getTableBodyProps()}>
          {props.loading && <LoadingOverlay id="agent-loading-spinner" size="xl" color="primary" />}
          {
            // Loop over the table rows
            mapIndexed((row, rowIndex) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <StyledRow
                  row={rowIndex}
                  id={`${props.id}-row-${rowIndex}`}
                  key={`${props.id}-row-${rowIndex}`}
                  loading={props.loading}
                  style={{ height: "3.5rem" }}
                  testattr="test"
                  {...row.getRowProps()}
                  {...(props.getCustomRowProps ? props.getCustomRowProps(row) : {})}
                >
                  {
                    // Loop over the rows cells
                    mapIndexed((cell, cellIndex) => {
                      // Apply the cell props
                      return (
                        <TableCell
                          key={`${props.id}-row-${rowIndex}-column${cellIndex}`}
                          cell={cell}
                          cellIndex={cellIndex}
                          rowIndex={rowIndex}
                          tableId={props.id}
                        />
                      );
                    }, row.cells)
                  }
                </StyledRow>
              );
            }, page)
          }
          {R.times(
            (i) => (
              <StyledRow key={`table-padding-${i}`}></StyledRow>
            ),
            page.length < data.length ? pageSize - page.length : 0
          )}
        </StyledTBody>
      </table>
      <TableFooter>
        <PaginationStyle
          id={`${props.id}-pagination`}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          resultCount={data.length || 0}
        />
      </TableFooter>
    </Wrapper>
  );
};

Table.defaultProps = {
  defaultPageSize: 15,
};

Table.propTypes = {
  defaultPageSize: PropTypes.number,
  selectable: PropTypes.bool,
  selectedRowIds: PropTypes.object,
  getSelectedRowIds: PropTypes.func,
  getCustomRowProps: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

/*
Custom Column Props
isTimestamp: Bool - If data in column is meant to be a timestamp(assumes epoch)
TimestampOffset: number - number to multiply the timestamp by.
  Moment by default assumes milliseconds so x1000
*/

export default Table;

import axios from "axios";
import * as localStorage from "clients/localStorage";
import settings from "utils/settings";
import { removeKey, APITOKEN } from "clients/localStorage";

// Do things like read env to get proper base url dev,int,prod
const baseUrl = `${settings.CAM_URL}/services`;

export const headers = () => {
  // Do things like get tokens form local storage
  return {
    Authorization: `Bearer ${localStorage.loadKey(localStorage.APITOKEN)}`,
  };
};

const camClient = () => {
  let client = axios.create({
    baseURL: baseUrl,
    timeout: 5000,
    headers: headers(),
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // whatever you want to do with the error
      if (error.response && error.response.status === 401) {
        removeKey(APITOKEN);
      }
      // eslint-disable-next-line
      console.error("Call to '" + error.request?.responseURL + "' failed.", error);
      return Promise.reject(error);
    }
  );

  return client;
};

const get = async (url, options = {}) => {
  let client = camClient();
  let resp = await client.get(url, options);
  return resp.data;
};

const getOrganizations = async () => {
  let scopes = await get("/scopes");
  return scopes.organizations;
};

export default {
  getOrganizations,
};

import React from "react";
import PropTypes from "prop-types";
import Progress from "components/form/Progress";
import IconButton from "components/common/IconButton";
import { useTranslation } from "react-i18next";

const icons = {
  success: "icon-circle-check",
  danger: "icon-circle-x",
  warning: "icon-warning",
  info: "icon-circle-info",
  muted: "icon-circle-info",
};

const Notification = (props) => {
  const { t } = useTranslation();

  return (
    <div
      id={props.id}
      ref={props.getRef}
      className={`chi-alert${props.style && ` -${props.style}`}${props.type && ` -${props.type}`}${
        props.size ? ` -${props.size}` : " -sm"
      }`}
      role="alert"
    >
      <i className={`chi-alert__icon chi-icon ${icons[props.type]}`}></i>
      <div className="chi-alert__content">
        {props.title ? <p className="chi-alert__title">{t(props.title)}</p> : ""}
        <p className="chi-alert__text">{t(props.msg)}</p>
        {props.primaryButtonText && (
          <div className="chi-alert__actions">
            {props.secondaryButtonText && (
              <button className="chi-button -sm" onClick={props.handleSecondaryClick}>
                {props.secondaryButtonText}
              </button>
            )}
            <button className="chi-button -sm -primary" onClick={props.handlePrimaryClick}>
              {props.primaryButtonText}
            </button>
          </div>
        )}
      </div>
      {props.timeout && (
        <Progress size={20} id={props.id + "-timer"} timeout={props.timeout}>
          <IconButton close size="sm" id={props.id} onClick={props.handleClose} icon="icon-x" />
        </Progress>
      )}
      {!props.timeout && (
        <IconButton close id={props.id} onClick={props.handleClose} icon="icon-x" />
      )}
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.string, // e.g., "toast"
  type: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  msg: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  secondaryButtonText: PropTypes.string,
  primaryButtonText: PropTypes.string,
  handleSecondaryClick: PropTypes.func,
  handlePrimaryClick: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  getRef: PropTypes.func.isRequired,
};

export default Notification;

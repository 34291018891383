import camClient from "cam/clients/camClient";
import { createSlice, createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";
import { addNotification } from "redux/notifications/notificationSlice";

const serverAdapter = createEntityAdapter();
export const getOrganizations = createAsyncThunk(
  "cam/getOrganizations",
  async (_, { dispatch }) => {
    try {
      let orgs = await camClient.getOrganizations();
      return orgs;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Failed to get orgs", err);
      dispatch(addNotification(["danger", "Unable to fetch customers.", true, "Error"]));
      throw err;
    }
  }
);

const initialState = serverAdapter.getInitialState({
  loading: false,
  entities: [],
  ids: [],
});

const camSlice = createSlice({
  name: "cam",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.loading = false;
      serverAdapter.setAll(state, action.payload);
    });
    builder.addCase(getOrganizations.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default camSlice.reducer;

export const customerSelectors = serverAdapter.getSelectors((state) => state.cam);
export const getCustomers = getOrganizations;

import React, { useEffect, useState } from "react";
import * as R from "ramda";
import styled from "styled-components";
import PropTypes from "prop-types";
import watcherTheme from "watcherTheme";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { putAgentVars, selectAgentVars, selectAgentFacts } from "redux/agents/agentsSlice";
import ReactJson from "react-json-view";
import TextInput from "components/form/TextInput";

const HeaderWrapper = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const JsonContainer = styled.div`
  max-height: 50%;
  overflow: auto;
  padding: 5px 15px;
  border-bottom: 2px solid ${watcherTheme.grey[25]};
`;

const AgentMetadataTab = (props) => {
  const { t } = useTranslation();
  const initialVars = useSelector((state) => selectAgentVars(state, props.agentId));
  const initialFacts = useSelector((state) => selectAgentFacts(state, props.agentId));
  const [vars, setVars] = useState(initialVars);
  const [facts, setFacts] = useState(initialFacts);
  const dispatch = useDispatch();

  const getPaths = (obj, searchValue) => {
    let paths = [];

    // loop through keys in obj
    for (let o in obj) {
      let current = obj[o];

      // if current key matches searchValue
      if (o.toLowerCase().includes(searchValue.toLowerCase())) {
        paths.push(o);
      }

      if (typeof current == "object") {
        // recursively call getPaths on nested object
        let deepPaths = getPaths(current, searchValue);

        R.forEach((deepPath) => {
          paths.push(`${o}.${deepPath}`);
        }, deepPaths);
      } else {
        if (current.toString().toLowerCase().includes(searchValue.toLowerCase())) {
          paths.push(o);
        }
      }
    }

    return paths;
  };

  const buildObject = (obj, path, val) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj[lastKey] = val;
  };

  const onChange = (searchValue) => {
    // if search is empty, revert back to original values
    if (searchValue === "") {
      setVars(initialVars);
      setFacts(initialFacts);
      return;
    }

    // get paths to keys/values that match searchValue
    const varPaths = getPaths(initialVars, searchValue);
    const factPaths = getPaths(initialFacts, searchValue);

    const filteredVars = {};
    // loop through varPaths and build resulting object
    R.forEach((path) => {
      const splitPath = R.split(".", path);
      buildObject(filteredVars, path, R.path(splitPath, initialVars));
    }, R.reverse(varPaths));

    const filteredFacts = {};
    // loop through factPaths and build resulting object
    R.forEach((path) => {
      const splitPath = R.split(".", path);
      buildObject(filteredFacts, path, R.path(splitPath, initialFacts));
    }, R.reverse(factPaths));

    // set vars/facts to filtered results
    setVars(filteredVars);
    setFacts(filteredFacts);
  };

  const updateVars = (json) => {
    const params = {
      scope: props.agentScope,
      agentId: props.agentId,
      vars: json.updated_src,
    };

    dispatch(putAgentVars(params));
  };

  useEffect(() => {
    setVars(initialVars);
  }, [initialVars]);

  useEffect(() => {
    setFacts(initialFacts);
  }, [initialFacts]);

  return (
    <React.Fragment>
      <HeaderWrapper>
        <Header>
          <TextInput
            id="filter-facts-vars"
            placeholder={t("common:common.search")}
            iconRight="icon-search"
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </Header>
      </HeaderWrapper>
      <JsonContainer>
        <ReactJson
          src={vars}
          name="vars"
          enableClipboard={false}
          displayDataTypes={true}
          onEdit={updateVars}
          onAdd={updateVars}
          onDelete={updateVars}
          collapsed={true}
        />
      </JsonContainer>
      <JsonContainer>
        <ReactJson
          src={facts}
          name="facts"
          enableClipboard={false}
          displayDataTypes={false}
          collapsed={true}
        />
      </JsonContainer>
    </React.Fragment>
  );
};

AgentMetadataTab.propTypes = {
  agentId: PropTypes.string.isRequired,
  agentScope: PropTypes.string.isRequired,
};

export default AgentMetadataTab;

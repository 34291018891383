import PropTypes from "prop-types";
import styled from "styled-components";
import CheckBoxIcon from "assets/CheckBoxIcon.svg";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const InputLabel = styled.label`
  height: 100%;
  transform: scale(1);
  min-height: 16px !important;
  padding-left: 0px !important;
  &:before {
    ${({ label }) => (label ? "margin-right: 8px !important;" : "")}
    position: unset !important;
  }
  ${(props) => {
    if (props.indeterminate) {
      return `
      &:before {
        ${props.checked ? "background-color: props.theme.grey[50] !important;" : ""}
        ${props.checked ? "border-color: props.theme.grey[50] !important;" : ""}
      }
      &:after {
        ${!props.checked ? `background-image: url(${CheckBoxIcon}) !important;` : ""}
        transform: scale(1) !important;
      }
      `;
    }
  }}
`;

/* eslint-enable indent*/
const CheckBox = (props) => {
  const [checked, setChecked] = useState(Boolean(props.checked));
  const { t } = useTranslation();

  useEffect(() => {
    // React Table needs to be able to controll this component
    setChecked(Boolean(props.checked));
  }, [props.checked]);

  const handleChecked = () => {
    setChecked(!checked);
    props.onChange(!checked);
  };

  return (
    <div key={`${props.id}-form-item`} className="chi-form__item">
      <div id={`${props.id}-wrapper`} className="chi-checkbox">
        <input
          key={`${props.id}-input`}
          className="chi-checkbox__input"
          type="checkbox"
          id={props.id}
          disabled={props.disabled}
          checked={checked}
          area-labelledby="checkbox-label"
          style={{ outlineOffset: "0px !important" }}
          onChange={!props.disabled ? handleChecked : undefined}
        />
        <InputLabel
          key={`${props.id}-input-label`}
          id={`${props.id}-label`}
          className={`chi-checkbox__label${props.hasError ? " -danger" : ""}`}
          htmlFor={props.id}
          indeterminate={props.indeterminate}
          label={props.label}
        >
          {t(props.label)}
          {props.isRequired ? (
            <abbr className="chi-label__required" title="Required field">
              *
            </abbr>
          ) : (
            ""
          )}
        </InputLabel>
      </div>
      {props.description ? (
        <div className={`chi-label -status${props.hasError ? " -danger" : ""}`}>
          {t(props.description)}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

CheckBox.propTypes = {
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isRequired: PropTypes.bool,
  description: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default CheckBox;

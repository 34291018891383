import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Loading = (props) => {
  const { t } = useTranslation();

  return <p>{props.t ? t(props.t) : "Loading..."}</p>;
};

Loading.propTypes = {
  t: PropTypes.array,
};

export default Loading;

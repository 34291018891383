import * as R from "ramda";
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import moment from "moment";

const createNotification = ([type, msg, hasTimeout, title, details]) => {
  return {
    id: moment().format() + Math.random(),
    type,
    msg,
    hasTimeout,
    title,
    details,
  };
};

const notificationAdapter = createEntityAdapter();

const initialState = notificationAdapter.getInitialState({});

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    addNotification: (state, action) => {
      notificationAdapter.addOne(state, createNotification(action.payload));
      if (action.payload.type === "danger") {
        // eslint-disable-next-line no-console
        console.error(action.payload.msg);
      } else if (action.payload.type === "warning") {
        // eslint-disable-next-line no-console
        console.warn(action.payload.msg);
      }
    },
    removeNotification: (state, action) => {
      notificationAdapter.removeOne(state, action.payload);
    },
  },
});

export default notificationSlice.reducer;

export const notificationSelectors = notificationAdapter.getSelectors(
  (state) => state.notification
);

export const { addNotification, removeNotification } = notificationSlice.actions;

export const notificationListSelector = (state) => R.path(["notification", "list"], state);

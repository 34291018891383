import * as R from "ramda";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Icon from "components/common/Icon";
import { useTranslation } from "react-i18next";

const StyledLeftIcon = styled(Icon)`
  margin-right: 5px;
`;
const StyledRightIcon = styled(Icon)`
  margin-left: 5px;
`;
const Button = (props) => {
  const omittedProps = [
    "getRef",
    "iconLeft",
    "iconRight",
    "danger",
    "flat",
    "primary",
    "noPadding",
    "t",
  ];
  const [referenceElement, setReferenceElement] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.getRef) {
      props.getRef(referenceElement);
    }
  }, [referenceElement]);

  return (
    <button
      {...R.omit(omittedProps, props)}
      ref={setReferenceElement}
      className={`chi-button${props.className ? ` ${props.className}` : ""}${
        props.danger ? " -danger" : ""
      }${props.flat ? " -flat" : ""}${props.size ? ` -${props.size}` : ""}${
        props.primary ? " -primary" : ""
      }`}
    >
      <div className="chi-button__content">
        {props.iconLeft && <StyledLeftIcon id={`icon-lef-${props.id}`} icon={props.iconLeft} />}
        {props.children ? props.children : t(props.t)}
        {props.iconRight && (
          <StyledRightIcon id={`icon-right-${props.id}`} icon={props.iconRight} />
        )}
      </div>
    </button>
  );
};

Button.propTypes = {
  size: PropTypes.string,
  flat: PropTypes.bool,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  getRef: PropTypes.func,
  id: PropTypes.string.isRequired,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  t: PropTypes.array,
};

export default Button;

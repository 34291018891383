import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAgentResults, resultsSelectors } from "redux/results/resultsSlice";
import { getAgents, agentSelectors, getAgentDetails } from "redux/agents/agentsSlice";
import {
  selectedCustomerIdSelector,
  setSelectedCustomer,
  customerSelectors,
} from "redux/navigation/navigationSlice";
import Tabs, { TabContent } from "components/common/Tabs";
import PageTitle from "components/common/PageTitle";
import IconButton from "components/common/IconButton";
import ResultDetailsTab from "components/agents/ResultDetailsTab";
import { toTitleCase } from "utils/globals.js";

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const ResultContent = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const ResultDetailsWrapper = styled.div`
  flex-grow: 1;
`;

const StyledIconButton = styled(IconButton)`
  height: 36px !important;
  margin-right: 1rem;
`;

const ResultDetailsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenant = useSelector(selectedCustomerIdSelector);
  const { agentUuid, resultUuid } = useParams();
  const results = useSelector(resultsSelectors.selectAll);
  const loading = useSelector((state) => state.results.loading);
  const [selectedResult, setSelectedResult] = useState({ defaultKey: "defaultValue" });
  const selectedAgent = useSelector((state) => agentSelectors.selectById(state, agentUuid)) || {};
  const agentCustomerId = R.path(["customer_id"], selectedAgent);
  const agentCustomer = useSelector((state) =>
    customerSelectors.selectById(state, agentCustomerId)
  );

  useEffect(() => {
    if (R.path(["error"], selectedAgent)) {
      history.push("/agents");
    }
  }, [selectedAgent]);

  useEffect(() => {
    // In case of page refresh
    dispatch(getAgentDetails(agentUuid));
    dispatch(getAgentResults(agentUuid));
    // Prevent redirect to /results before page is loaded
    if (R.path(["id"], selectedResult) !== undefined) {
      history.push(`/agents/${agentUuid}/results`);
    }
  }, [agentUuid]);

  useEffect(() => {
    let result = results.find((result) => result.id === resultUuid);
    setSelectedResult(result || { defaultKey: "defaultValue" });
  }, [resultUuid, results]);

  useEffect(() => {
    if (!loading && R.isEmpty(selectedResult)) {
      history.push(`/agents/${agentUuid}/results`);
    }
  }, [selectedResult]);

  // If user changes tenant, redirect to agent list page
  const usePrev = (value) => {
    const ref = useRef(value);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevTenant = usePrev(tenant);

  // TODO: move this into custom hook and write unit test for hook
  useEffect(() => {
    dispatch(getAgents(tenant));
    if ((tenant || "").toString() != (prevTenant || "").toString() && tenant != agentCustomerId) {
      history.push("/agents");
    }
  }, [tenant]);

  // If the current customer doesn't match the customer
  // on the selected agent, update tenant in navigation store.
  useEffect(() => {
    if (agentCustomerId && tenant != agentCustomerId) {
      dispatch(setSelectedCustomer(agentCustomer));
    }
  }, [agentCustomer]);

  const handleBackButton = () => {
    history.push(`/agents/${agentUuid}/results`);
  };

  const getPageTitle = () => {
    let result = R.find(R.propEq("id", resultUuid), results);
    let checkType = R.path(["check_type"], result);
    let checkId = R.path(["id"], result);
    return checkType || checkId ? ` - ${toTitleCase(checkType || checkId)}` : "";
  };

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <Header>
            <StyledIconButton
              id="return"
              icon="icon-chevron-left"
              onClick={handleBackButton}
            ></StyledIconButton>
            <PageTitle id="page-title" size="h3" t={["common:page_navigation.result_details"]}>
              {getPageTitle()}
            </PageTitle>
          </Header>
          <ResultContent>
            <ResultDetailsWrapper>
              <Tabs>
                <TabContent
                  exact
                  to={`/agents/${agentUuid}/results/${resultUuid || ""}`}
                  id="result-details-tab"
                  title="common:agents.results.details.details_tab"
                >
                  <ResultDetailsTab result={selectedResult} agentId={agentUuid} loading={loading} />
                </TabContent>
                <TabContent
                  exact
                  to={`/agents/${agentUuid}/results/${resultUuid || ""}/history`}
                  id="result-history-tab"
                  title="common:agents.results.details.history_tab"
                >
                  <div>Result history content will go here</div>
                </TabContent>
              </Tabs>
            </ResultDetailsWrapper>
          </ResultContent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

ResultDetailsPage.propTypes = {
  searchTerm: PropTypes.string,
};

export default ResultDetailsPage;

const grey = {
  100: "#242526",
  90: "#313336",
  80: "#3F4145",
  70: "#53565A",
  60: "#65686C",
  50: "#8E9399",
  40: "#ACB0B5",
  30: "#D0D4D9",
  25: "#DADEE2",
  20: "#EDF0F2",
  15: "#F4F5F6",
  10: "#F8F9F9",
};
const green = {
  70: "#007E44",
  60: "#009054",
  30: "#95E9C9",
  20: "#DBFAEE",
};
const blue = {
  100: "#083176",
  75: "#0262B9",
  70: "#0075C9",
  60: "#0080DC",
  30: "#AEDEFF",
  20: "#E0F3FF",
};
const red = {
  70: "#D62015",
  60: "#EE3026",
  30: "#FFC2BD",
  20: "#FFE8E5",
};

const yellow = {
  70: "#A15C00",
  60: "#B96B00",
  30: "#FFCC89",
  20: "#FAECD9",
};
const cyan = {
  50: "#0C9ED9",
  40: "#38C6F4",
};

const breakpoints = {
  xs: 0,
  sm: 575,
  md: 768,
  lg: 992,
  xl: 1200,
};

const only = {
  xs: `@media screen and (max-width: ${breakpoints.sm}px)`,
  sm: `@media screen and (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
};

const up = {
  xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
};

const down = {
  xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
  sm: `@media screen and (max-width: ${breakpoints.sm}px)`,
  md: `@media screen and (max-width: ${breakpoints.md}px)`,
  lg: `@media screen and (max-width: ${breakpoints.lg}px)`,
  xl: `@media screen and (max-width: ${breakpoints.xl}px)`,
};

const watcherTheme = {
  primary: blue[70],
  secondary: cyan[40],
  white: "#FFFFFF",
  black: "#000000",
  success: green[60],
  error: red[60],
  warning: yellow[60],
  info: blue[60],
  muted: grey[60],
  grey,
  green,
  blue,
  red,
  yellow,
  cyan,
  breakpoints,
  only,
  up,
  down,
};

export default watcherTheme;

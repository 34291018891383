import watcherClient from "clients/watcherClient";
import morpheusClient from "edge/clients/morpheusClient";

const getEnablementServers = async (source, customerId) => {
  let resp = await watcherClient().get(`enablement/customers/${source}/${customerId}/assets`, {});
  return resp.data;
};

const postEnablementServers = async (source, customerId, body) => {
  let resp = await watcherClient().post(
    `enablement/customers/${source}/${customerId}/assets:bulk_update`,
    body,
    { timeout: 5000 }
  );
  return resp.data;
};

const getServers = (customerId) => {
  // @TODO: call get enablement servers in parallel and match the results
  // by server id for the final return.
  return Promise.all([morpheusClient.getServers(), getEnablementServers("edge", customerId)]).then(
    (responses) => {
      let morphServers = responses[0];
      let enablementServers = responses[1];
      let servers = morphServers.map((srv) => {
        let enablementSrv = enablementServers.find(
          (s) => s.external_data.server_id.toString() === srv.id.toString()
        );
        srv.service_management = enablementSrv ? enablementSrv.service_management : null;
        srv.service_management_update_date = enablementSrv
          ? enablementSrv.service_management_update_date
          : null;
        srv.msa_status = enablementSrv ? "active" : "inactive";
        return srv;
      });

      // Ensure that all servers that have been MSA enabled exist in morpheus.
      // Otherwise, add a fake record with a status as absent.
      // Most likely the server was deleted in morpheus, but did not get deleted in MSA.
      enablementServers.forEach((enableSrv) => {
        if (
          morphServers.find(
            (s) => s.id.toString() === enableSrv.external_data.server_id.toString()
          ) == null
        ) {
          let newSrv = {
            id: enableSrv.external_data.server_id,
            msa_status: "absent",
            service_management: enableSrv.service_management,
          };
          servers.push(newSrv);
        }
      });
      return servers;
    }
  );
};

export default {
  getServers,
  postEnablementServers,
};

import PropTypes from "prop-types";
import useKeyEvent from "hooks/useKeyEvent";
import Icon from "components/common/Icon";
import Button from "components/common/Button";
import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import styled from "styled-components";

const SplitContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.p`
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const Modal = (props) => {
  const [referenceElement, setReferenceElement] = useState(null);

  const contentTransitions = useTransition(props.isOpen, {
    trail: 200,
    from: { transform: "translate3d(0,-120%,0)" },
    enter: { transform: "translate3d(0,0px,0)" },
    leave: { transform: "translate3d(0,-500%,0)" },
  });

  const backdropTransitions = useTransition(props.isOpen, {
    from: { opacity: "0" },
    enter: { opacity: "1" },
    leave: { opacity: "0" },
  });

  const handleClose = () => {
    return props.handleClose ? props.handleClose() : "";
  };
  const handleConfirm = () => {
    handleClose();
    return props.handleConfirm ? props.handleConfirm() : "";
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (referenceElement && !referenceElement.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [referenceElement]);

  useKeyEvent(handleClose, ["Escape"]);

  return backdropTransitions(
    (backdropProps, backdropItem, backdropKey) =>
      backdropItem && (
        <animated.div style={backdropProps} key={backdropKey} className={`chi-backdrop`}>
          <div className="chi-backdrop__wrapper ">
            {contentTransitions(
              (contentProps, contentItem, contentKey) =>
                contentItem && (
                  <animated.div ref={setReferenceElement} key={contentKey} style={contentProps}>
                    <section
                      id={props.id}
                      className="chi-modal -portal"
                      role="dialog"
                      aria-label="Modal description"
                      aria-modal="true"
                    >
                      <header className="chi-modal__header">
                        <h2 className="chi-modal__title">{props.title}</h2>
                        <Button
                          id={`close-${props.id}`}
                          className="chi-button -icon -close"
                          aria-label="Close"
                          onClick={handleClose}
                        >
                          <div className="chi-button__content">
                            <i className="chi-icon icon-x"></i>
                          </div>
                        </Button>
                      </header>
                      <div className="chi-modal__content">
                        {!props.icon && (
                          <StyledText>
                            {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
                            {props.children}
                          </StyledText>
                        )}
                        {props.icon && (
                          <SplitContent>
                            <Icon
                              id={`modal-icon-${props.id}`}
                              icon={`${props.icon}`}
                              size={"sm--3"}
                              className={`-icon--${props.colorType}`}
                            />
                            <StyledText>
                              {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
                              {props.children}
                            </StyledText>
                          </SplitContent>
                        )}
                      </div>
                      <footer className="chi-modal__footer">
                        {props.cancelButtonText && (
                          <Button
                            id={`cancel-${props.id}`}
                            onClick={handleClose}
                            className={"chi-button -primary -outline -lg -px--4 -text--uppercase"}
                          >
                            {props.cancelButtonText}
                          </Button>
                        )}
                        {props.handleConfirm ? (
                          <Button
                            id={`save-${props.id}`}
                            className="chi-button -primary -lg -px--4 -text--uppercase"
                            onClick={handleConfirm}
                          >
                            {props.confirmButtonText || "Save"}
                          </Button>
                        ) : (
                          ""
                        )}
                      </footer>
                    </section>
                  </animated.div>
                )
            )}
          </div>
        </animated.div>
      )
  );
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  colorType: PropTypes.string, // ex: "warning"
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

export default Modal;

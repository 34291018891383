import * as queryString from "query-string";
import * as R from "ramda";

export const getQueryParameter = (parameter, location = window.location) => {
  return R.propOr(null, parameter)(queryString.parse(location.search));
};

export const windowLocationNoOrigin = (location = window.location) => {
  let result = [];
  result.push(location.pathname);
  result.push(location.search);
  result.push(location.hash);

  return result.join("");
};

export const windowLocationOrigin = (location = window.location) => {
  let result = [];
  result.push(`${location.protocol}//`);
  result.push(location.hostname);
  result.push(location.port ? `:${location.port}` : "");

  return result.join("");
};

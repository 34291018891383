import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getEvents, eventsSelectors } from "redux/events/eventsSlice";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import Tabs, { TabContent } from "components/common/Tabs";
import PageTitle from "components/common/PageTitle";
import IconButton from "components/common/IconButton";
import EventDetailsTab from "components/events/EventDetailsTab";
import { toTitleCase } from "utils/globals.js";
import EventActionsTab from "components/events/EventActionsTab";

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const EventContent = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const EventDetailsWrapper = styled.div`
  flex-grow: 1;
`;

const StyledIconButton = styled(IconButton)`
  height: 36px !important;
  margin-right: 1rem;
`;

const EventDetailsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenant = useSelector(selectedCustomerIdSelector);
  const events = useSelector(eventsSelectors.selectAll);
  const loading = useSelector((state) => state.events.loading);
  const [selectedEvent, setSelectedEvent] = useState({ defaultKey: "defaultValue" });
  const { eventUuid } = useParams();

  useEffect(() => {
    dispatch(getEvents(tenant));
    // necessary to make sure we don't redirect to /events before page is loaded
    if (R.path(["id"], selectedEvent) !== undefined) {
      history.push("/events");
    }
  }, [tenant]);

  useEffect(() => {
    if (!loading && R.isEmpty(selectedEvent)) {
      history.push("/events");
    }
  }, [selectedEvent]);

  useEffect(() => {
    setSelectedEvent(
      events.find((event) => event.id === eventUuid) || { defaultKey: "defaultValue" }
    );
  }, [eventUuid, events]);

  const handleBackButton = () => {
    history.push("/events");
  };

  const getPageTitle = () => {
    let checkType = R.path(["check", "check_type"], selectedEvent);
    let checkName = R.path(["check", "name"], selectedEvent);
    return checkType || checkName ? ` - ${toTitleCase(checkType || checkName)}` : "";
  };

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          <Header>
            <StyledIconButton
              id="button-return"
              icon="icon-chevron-left"
              onClick={handleBackButton}
            ></StyledIconButton>
            <PageTitle size="h3" t={["common:page_navigation.event_details"]}>
              {getPageTitle()}
            </PageTitle>
          </Header>
          <EventContent>
            <EventDetailsWrapper>
              <Tabs>
                <TabContent
                  exact
                  to={`/events/${eventUuid || ""}`}
                  id="event-details-tab"
                  title="common:events.details.details_tab"
                >
                  <EventDetailsTab event={selectedEvent} loading={loading} />
                </TabContent>
                <TabContent
                  exact
                  to={`/events/${eventUuid || ""}/actions`}
                  id="event-actions-tab"
                  title="common:events.details.actions_tab"
                >
                  <EventActionsTab event={selectedEvent} loading={loading} />
                </TabContent>
              </Tabs>
            </EventDetailsWrapper>
          </EventContent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

EventDetailsPage.propTypes = {
  searchTerm: PropTypes.string,
};

export default EventDetailsPage;

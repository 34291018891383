import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import Text from "components/form/Text";

const Popover = (props) => {
  const [popoverElement, setPopoverElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(props.refElement, popoverElement, {
    placement: props.placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 15],
        },
      },
      { name: "arrow", options: { element: arrowElement } },
    ],
  });

  const popoverTransitions = useTransition(props.show, {
    from: { opacity: "0" },
    enter: { opacity: "1" },
    leave: { opacity: "0" },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverElement &&
        !popoverElement.contains(event.target) &&
        !props.refElement.contains(event.target)
      ) {
        props.handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverElement]);

  return (
    props.show &&
    props.refElement &&
    popoverTransitions(
      (transitionProps, item, key) =>
        item && (
          <animated.div
            id={props.id}
            className="chi-popover -active"
            aria-label="Popover Title"
            aria-modal="true"
            role="dialog"
            ref={setPopoverElement}
            style={{
              ...transitionProps,
              ...styles.popper,
              margin: 0,
              maxWidth: "unset",
              ...props.popoverStyles,
            }}
            {...attributes.popper}
            x-placement={props.placement}
            key={key}
          >
            {props.title && (
              <header
                className="chi-popover__header"
                style={{ width: "auto", padding: "0.5rem 1rem 0" }}
              >
                <Text className="chi-popover__title" size="lg" t={[props.title]} />
              </header>
            )}
            <div className="chi-popover__content" style={{ width: "auto", padding: "0.5rem 1rem" }}>
              {props.text ? <Text className="chi-popover__text" t={[props.text]} /> : ""}
              {props.children}
            </div>
            <div className="chi-popover__arrow" ref={setArrowElement} style={styles.arrow} />
          </animated.div>
        )
    )
  );
};

Popover.defaultProps = {
  placement: "top",
};

Popover.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refElement: PropTypes.any,
  id: PropTypes.string.isRequired,
  popoverStyles: PropTypes.any,
};

export default Popover;

//

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useRanger } from "react-ranger";
import { useTranslation } from "react-i18next";

const Segment = styled("div")`
  background: ${(props) => (props.index === 0 ? props.theme.primary : props.theme.grey[30])};
  height: 100%;
  border-radius: 1rem;
`;

const Track = styled("div")`
  display: inline-block;
  height: 0.25rem;
  width: 100%;
  border-radius: 1rem;
`;

const Handle = styled("div")`
  background-color: ${({ theme }) => theme.white};
  border: 0.125rem solid ${({ theme }) => theme.primary};
  border-radius: 0.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  left: 50%;
  pointer-events: none;
  ${({ first }) => (first ? "margin-left: .625rem;" : "")}
  ${({ last }) => (last ? "margin-left: -.625rem;" : "")}
`;

// This is necessary to prevent the "line" part of the slider to
// absorb the click events at this tick.
const TickWrapper = styled("div")`
  z-index: 2;
`;

const Tick = styled("div")`
  border-left: 0.0625rem solid ${(props) => props.theme.grey[30]};
  height: 0.25rem;
  margin: 0.25rem auto 0;
  ${({ first }) => (first ? "margin-left: .625rem;" : "")}
  ${({ last }) => (last ? "margin-left: -.625rem;" : "")}
`;

const TickLabel = styled("div")`
  font-size: 0.8125rem;
  left: -3.25rem;
  line-height: 1.25rem;
  position: absolute;
  text-align: center;
  top: 0;
  width: 6.5rem;
  margin: 1rem auto 0;
`;

const TickButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  height: 35px;
  width: 50px;
  left: -25px;
  bottom: -10px;
`;

const Slider = (props) => {
  const [values, setValues] = useState(props.defaultValues || [props.min]);
  const { t } = useTranslation();
  const sliderId = props.id;

  const memoHandleChange = useCallback(
    (e) => {
      setValues(e);
      props.onChange(e);
    },
    [values]
  );

  const { getTrackProps, handles, segments, ticks } = useRanger({
    min: props.min,
    max: props.max,
    stepSize: props.stepSize || 1,
    ticks: props.labels.map((label, i) => i),
    values,
    onChange: memoHandleChange,
  });

  useEffect(() => {
    setValues(props.defaultValues || [props.min]);
  }, [props.defaultValues]);

  return (
    <div className="">
      <label className="">{t([props.name])}</label>
      <div className="">
        <Track {...getTrackProps()}>
          {ticks.map(({ value, getTickProps }, i) => {
            const handleTickButtonClick = () => {
              memoHandleChange([value]);
            };
            return (
              <TickWrapper key={i} {...getTickProps()}>
                <Tick first={i === 0} last={i === ticks.length - 1}></Tick>
                {!values.includes(value) && (
                  <TickButton onClick={handleTickButtonClick} id={`e2e-button-${sliderId}-${i}`} />
                )}
                <TickLabel onClick={handleTickButtonClick}>{t(props.labels[value])}</TickLabel>
              </TickWrapper>
            );
          })}
          {segments.map(({ getSegmentProps }, i) => (
            <Segment {...getSegmentProps()} key={i} index={i} />
          ))}
          {handles.map((thing, i) => {
            return (
              <button
                key={i}
                id={`button-${props.id}`}
                {...thing.getHandleProps({
                  style: {
                    appearance: "none",
                    border: "none",
                    background: "transparent",
                    outline: "none",
                  },
                })}
              >
                <Handle first={thing.value === 0} last={thing.value === ticks.length - 1}></Handle>
              </button>
            );
          })}
        </Track>
      </div>
    </div>
  );
};

Slider.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  stepSize: PropTypes.number,
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
};

export default Slider;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as R from "ramda";
import styled from "styled-components";
import Button from "components/common/Button";
import Loading from "components/common/Loading";
import ConfigureMSA from "components/enablement/ConfigureMSA";
import Link from "components/form/Link";
import Table from "components/table/Table";
import {
  getEnablementServers,
  postEnablementServers,
  enablementServerSelectors,
} from "redux/enablement/enablementSlice";
import MSAEnabledCell from "components/table/MSAEnabledCell";
import Icon from "components/common/Icon";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import Dropdown, { DropdownItem } from "components/menu/Dropdown";
import Modal from "components/modal/Modal";
import PageTitle from "components/common/PageTitle";

// May not be necessary if only one button in cell
const ButtonsCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableUtilityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0px;
  margin: 0px;
`;

const EnablementPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tenant = useSelector(selectedCustomerIdSelector);
  const servers = useSelector(enablementServerSelectors.selectAll);

  const [selectedServerIndices, setSelectedServerIndices] = useState({});

  const [configMode, setConfigMode] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [parameters, setParameters] = useState({});

  const handleSelectedServerIndices = (ids) => {
    setSelectedServerIndices(ids);
  };

  const handleOpenConfig = (index) => {
    if (index !== null) {
      setSelectedServerIndices({ [index]: true });
    }
    setConfigMode(true);
  };

  const handleCloseConfig = () => {
    setConfigMode(false);
  };

  const handleOpenConfirm = (params) => {
    setParameters(params);
    setConfirming(true);
  };

  const handleCancelConfirm = () => {
    setConfirming(false);
  };

  const handleSaveConfig = () => {
    if (!R.isEmpty(parameters)) dispatch(postEnablementServers(parameters));
    setConfirming(false);
    handleCloseConfig();
  };

  const getMSAEnabledCell = (properties) => {
    const id = "status-" + properties.row.id;
    return (
      <MSAEnabledCell
        key={`msa-enabled-cell-${id}`}
        id={id}
        show={false}
        isUpdating={properties.row.original.isUpdating}
        serviceManagement={properties.row.original.service_management || {}}
      />
    );
  };

  const getEdgeNameCell = (properties) => {
    return (
      <Link
        id={`enablement-table-row-${properties.row.index}-edge-link`}
        to=""
        tooltip="Be ye hereafter banished to oblivion"
      >
        {properties.value}
      </Link>
    );
  };

  const getDateCell = (properties) => {
    if (properties.value) {
      let id = properties.row.original.id + "-date";
      let lastConfigDate = new Date(properties.value).toLocaleString(
        {},
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        }
      );
      return (
        <div id={id} key={id}>
          {lastConfigDate}
        </div>
      );
    }
    return "";
  };

  const getConfigButtonCell = (properties) => {
    return (
      <ButtonsCell>
        <Dropdown id={`kebab-button-${properties.row.id}`} flat noPadding icon="icon-more-vert">
          <DropdownItem
            id={`action-button-config-${properties.row.id}`}
            onClick={() => handleOpenConfig(properties.row.id)}
          >
            <Icon
              id={`configure-enablement-icon-${properties.row.id}`}
              flat
              icon="icon-compose"
              size="sm--2"
              noPadding
            />
            {t("common:enablement.button_configure_enablement")}
          </DropdownItem>
        </Dropdown>
      </ButtonsCell>
    );
  };

  useEffect(() => {
    dispatch(getEnablementServers(tenant));
  }, [tenant]);

  // TODO: disable checkboxes/buttons for any servers missing account property?

  const columns = [
    {
      Header: "common:enablement.table.header_name",
      accessor: "name",
      Cell: getEdgeNameCell,
    },
    {
      Header: "common:enablement.table.header_msa",
      accessor: "msa_status",
      Cell: getMSAEnabledCell,
    },
    // {
    //   Header: "Agent Name",
    //   accessor: ""
    // },
    {
      Header: "common:enablement.table.header_customer",
      accessor: "account.name",
    },
    {
      Header: "common:enablement.table.header_cloud",
      accessor: "zone.name",
    },
    {
      Header: "common:enablement.table.header_os",
      accessor: "serverOs.name",
    },
    {
      Header: "common:enablement.table.header_last_configured",
      accessor: "service_management_update_date",
      Cell: getDateCell,
    },
    {
      Header: "common:common.empty", // to avoid 'missingKey' i18 warning
      accessor: "id",
      Cell: getConfigButtonCell,
      disableSortBy: true,
    },
  ];

  if (!servers.loading) {
    return (
      <React.Fragment>
        {!configMode && (
          <React.Fragment>
            <PageTitle size="h3" t={["common:page_navigation.enablement"]} />
            <Table
              getSelectedRowIds={handleSelectedServerIndices}
              selectedRowIds={selectedServerIndices}
              columns={columns}
              data={servers}
              id="enablement-servers-table"
              selectable
              getCustomRowProps={(row) => {
                return {
                  serverid: row.original.id,
                  rowid: row.id,
                };
              }}
            />
            <TableUtilityRow>
              <Button
                primary
                className={configMode || R.isEmpty(selectedServerIndices) ? "-disabled" : ""}
                id="open-config-button"
                iconRight="icon-compose"
                onClick={() => handleOpenConfig(null)}
                t={["common:enablement.button_configure_enablement"]}
              />
            </TableUtilityRow>
          </React.Fragment>
        )}
        <ConfigureMSA
          serverIndices={selectedServerIndices}
          handleClose={handleCloseConfig}
          showConfig={configMode}
          handleOpenModal={handleOpenConfirm}
          handleSave={handleSaveConfig}
        />
        <Modal
          id={"confirmation-modal"}
          title={t("common:enablement.confirmation.title")}
          subtitle={t("common:enablement.confirmation.subtitle")}
          confirmButtonText={t("common:common.button_save")}
          cancelButtonText={t("common:common.button_go_back")}
          handleClose={handleCancelConfirm}
          handleConfirm={handleSaveConfig}
          isOpen={confirming}
          icon="icon-warning"
          colorType="warning"
          t={["common:enablement.confirmation.text"]}
        />
      </React.Fragment>
    );
  }

  return <Loading t={["common:enablement.loading"]} postEnablementServers />;
};

export default EnablementPage;

import React from "react";
import Modal from "components/modal/Modal";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as R from "ramda";
import { useTranslation } from "react-i18next";

const ServerList = styled.div`
  max-height: 400px;
  padding-right: 15px;
  overflow-y: auto;
`;

const ServerName = styled.p`
  margin: 0px;
`;

const ErrorType = styled.p`
  font-style: italic;
  margin: 0px 0px 8px;
`;

const NotificationDetailsModal = (props) => {
  const { t } = useTranslation();
  let children = R.map((datum) => {
    let id = datum.id;
    return (
      <div id={`${id}-details`} key={`key-${id}`}>
        <ServerName>{datum.displayName}</ServerName>
        <ErrorType>{`Error: ${datum.errorMessage}`}</ErrorType>
      </div>
    );
  }, props.detailsData);

  return (
    <Modal
      id={`${props.detailsType}-notification-modal`}
      title={t(props.title)}
      subtitle={t(props.subtitle)}
      confirmButtonText={t("common:common.button_ok")}
      handleClose={props.handleClose}
      handleConfirm={props.handleClose}
      isOpen={props.isOpen}
      icon={props.icon}
      colorType={props.detailsType}
    >
      <ServerList>{children}</ServerList>
    </Modal>
  );
};

NotificationDetailsModal.propTypes = {
  detailsType: PropTypes.string.isRequired,
  detailsData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NotificationDetailsModal;

/*
This encapsulates how to load env specific settings.  Currently it reads the
environment from window.runtimeEnvironment which is loaded from env.js.

Ideally this would load the env from .env in the key=value format (ie like dotenv).
 */
let settings = window.runtimeEnvironment || {};
let host = window.location.hostname;
settings.source = host.split(".")[0];

export default settings;

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const NumInput = (props) => {
  const [count, setCount] = useState(props.initialValue || 0);
  const { t } = useTranslation();

  const increase = () => {
    setCount(count + 1);
  };

  const decrease = () => {
    setCount(count - 1);
  };

  useEffect(() => {
    props.onChange(count);
  }, [count]);

  const handleChange = (e) => {
    setCount(Number(e.target.value));
  };

  return (
    <div className="chi-form__item">
      {props.label ? (
        <label className="chi-label" htmlFor={props.id}>
          {t(props.label)}
          {props.isRequired ? (
            <abbr className="chi-label__required" title="Required field">
              *
            </abbr>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}
      <div className={`chi-number-input${props.isExpanded ? " -expanded" : ""}`}>
        <input
          id={props.id}
          type="number"
          className={`chi-input${props.hasError ? " -danger" : ""}`}
          value={count}
          onChange={handleChange}
          aria-label="Quantity"
        />
        <button
          className={`${props.isExpanded ? "chi-button -icon" : ""}`}
          onClick={decrease}
          aria-label="Decrease"
        >
          {props.isExpanded ? (
            <div className="chi-button__content">
              <i className="chi-icon icon-minus" />
            </div>
          ) : (
            ""
          )}
        </button>
        <button
          className={`${props.isExpanded ? "chi-button -icon" : ""}`}
          onClick={increase}
          aria-label="Increase"
        >
          {props.isExpanded ? (
            <div className="chi-button__content">
              <i className="chi-icon icon-plus" />
            </div>
          ) : (
            ""
          )}
        </button>
      </div>
      <div className={`chi-label -status${props.hasError ? " -danger" : ""}`}>
        {t(props.description)}
      </div>
    </div>
  );
};

NumInput.propTypes = {
  initialValue: PropTypes.number,
  isExpanded: PropTypes.bool,
  hasError: PropTypes.bool,
  isRequired: PropTypes.bool,
  description: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default NumInput;

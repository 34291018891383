import PropTypes from "prop-types";
import styled from "styled-components";
import { usePopper } from "react-popper";
import useKeyEvent from "hooks/useKeyEvent";
import Button from "components/common/Button";
import React, { useState, useEffect } from "react";
import IconButton from "../common/IconButton";
import Popover from "components/modal/Popover";
import { useTranslation } from "react-i18next";

const StyledDropdownItem = styled.a`
  &&& {
    cursor: pointer;
  }
`;
const StyledDropdownMenu = styled.div`
  &&& {
    background-color: white;
  }
`;

export const DropdownItem = (props) => {
  const { t } = useTranslation();

  const itemSelected = (e) => {
    props.toggleMenu();
    props.onClick();
    e.stopPropagation();
  };
  return (
    <StyledDropdownItem
      id={props.id}
      value={props.value}
      className={`chi-dropdown__menu-item ${props.className ? props.className : ""}`}
      onClick={itemSelected}
    >
      {props.children ? props.children : t(props.t)}
    </StyledDropdownItem>
  );
};

const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popoverElement, setPopoverElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popoverElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverElement &&
        !popoverElement.contains(event.target) &&
        !referenceElement.contains(event.target)
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverElement]);

  useKeyEvent(handleClose, ["Escape"]);

  return (
    <div className="chi-dropdown">
      {props.icon ? (
        <IconButton
          icon={props.icon}
          getRef={setReferenceElement}
          id={props.id}
          onClick={handleToggle}
          noPadding={props.noPadding}
          flat={props.flat}
          float={props.float}
        />
      ) : (
        <Button getRef={setReferenceElement} id={props.id} onClick={handleToggle}>
          {props.text}
        </Button>
      )}
      <Popover
        id={`${props.id}-popper`}
        refElement={referenceElement || {}}
        placement="bottom-start"
        show={isOpen}
        handleClose={handleClose}
      >
        <StyledDropdownMenu
          ref={setPopoverElement}
          style={{ ...styles.popover, margin: 0 }}
          {...attributes.popover}
          x-placement="bottom"
          className={`chi-dropdown__menu -active`}
        >
          {/* this is a way to be able to pass a prop down to all of the children */}
          {/* We do this so we can pass down a function to close the menu so that */}
          {/* This component can be completely self contained */}
          {React.Children.map(props.children, (child) => {
            return React.cloneElement(child, {
              toggleMenu: handleToggle,
            });
          })}
        </StyledDropdownMenu>
      </Popover>
    </div>
  );
};

Dropdown.propTypes = {
  noPadding: PropTypes.bool,
  float: PropTypes.bool,
  flat: PropTypes.bool,
  icon: PropTypes.string,
  leftLabel: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
};

DropdownItem.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.string,
  children: PropTypes.node,
  toggleMenu: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default Dropdown;

import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agentsApi from "clients/agentsApi";
import * as R from "ramda";
import { addNotification } from "redux/notifications/notificationSlice";
import i18next from "i18next";
import settings from "utils/settings";

const agentAdapter = createEntityAdapter({
  sortComparer: R.ascend(R.prop("name")),
});

export const getAgents = createAsyncThunk("agents/getAgents", async (tenantId, { dispatch }) => {
  return await agentsApi.getAgents(settings.source, tenantId).catch((err) => {
    dispatch(
      addNotification([
        "danger",
        i18next.t("common:agents.notifications.error.fetching_agents", { tenant: tenantId }),
        false,
        "common:common.error_title",
      ])
    );

    throw err;
  });
});

export const getAgentDetails = createAsyncThunk(
  "agents/getAgentDetails",
  async (agentId, { dispatch }) => {
    return await agentsApi.getAgentDetails(agentId).catch((e) => {
      dispatch(
        addNotification([
          "danger",
          i18next.t("common:agents.notifications.error.agent_not_found", { agentId: agentId }),
          false,
          "common:common.error_title",
        ])
      );

      throw e;
    });
  }
);

const process_refresh = async (customerId, agentIds, dispatch, getState, config) => {
  const handleErrorNotify = (message, details) => {
    dispatch(addNotification(["danger", message, false, "common:common.error_title", details]));
  };

  const handleSuccessNotify = (message) => {
    dispatch(addNotification(["success", message, true, "common:common.success_title"]));
  };

  try {
    await config.postRefresh(settings.source, customerId, agentIds);
    handleSuccessNotify(config.all_successful_message);
  } catch (err) {
    if (!err.response) {
      handleErrorNotify(config.all_failed_message);
      throw err;
    } else if (err.response.data.message) {
      handleErrorNotify(err.response.data.message);
      throw err;
    } else {
      let successCount = err.response.data.filter((a) => a.status < 300).length;
      if (successCount > 0) {
        handleSuccessNotify(
          i18next.t(config.partial_success_message, {
            count: successCount,
          })
        );
      }

      let failed = err.response.data.filter((a) => a.status >= 300);

      const detailsData = failed.map((failedAgent) => {
        const agent = agentSelectors.selectById(getState(), failedAgent.agent_id);

        return {
          id: failedAgent.agent_id,
          displayName: agent ? `${agent.display_name}` : `${failedAgent.agent_id}`,
          errorMessage: failedAgent.message,
        };
      });

      const details = {
        title: "common:agents.notifications.errorModal.title",
        subtitle: "common:agents.notifications.errorModal.subtitle",
        icon: "icon-circle-alert",
        data: detailsData,
      };

      handleErrorNotify(
        i18next.t(config.partial_failed_message, {
          count: failed.length,
        }),
        details
      );
    }
  }
};

export const refreshFacts = createAsyncThunk(
  "agents/refreshFacts",
  async ({ customerId, agentIds }, { dispatch, getState }) => {
    await process_refresh(customerId, agentIds, dispatch, getState, {
      postRefresh: agentsApi.postRefreshFacts,
      all_successful_message: "common:agents.notifications.success.refreshing_facts_all",
      all_failed_message: "common:agents.notifications.error.refreshing_facts_all",
      partial_success_message: "common:agents.notifications.success.refreshing_facts_partial",
      partial_failed_message: "common:agents.notifications.error.refreshing_facts_partial",
    });
  }
);

export const refreshConfig = createAsyncThunk(
  "agents/refreshConfig",
  async ({ customerId, agentIds }, { dispatch, getState }) => {
    await process_refresh(customerId, agentIds, dispatch, getState, {
      postRefresh: agentsApi.postRefreshConfig,
      all_successful_message: "common:agents.notifications.success.refreshing_config_all",
      all_failed_message: "common:agents.notifications.error.refreshing_config_all",
      partial_success_message: "common:agents.notifications.success.refreshing_config_partial",
      partial_failed_message: "common:agents.notifications.error.refreshing_config_partial",
    });
  }
);

export const putAgentVars = createAsyncThunk(
  "agents/putAgentVars",
  async ({ scope, agentId, vars }, { dispatch }) => {
    return await agentsApi.putAgentVars(scope, agentId, vars).catch((e) => {
      dispatch(
        addNotification([
          "danger",
          i18next.t("common:agents.notifications.error.updating_vars", { agentId: agentId }),
          false,
          "common:common.error_title",
        ])
      );

      throw e;
    });
  }
);

const initialState = agentAdapter.getInitialState({
  loading: false,
  entities: {},
  ids: [],
});

const agentsSlice = createSlice({
  name: "agents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        agentAdapter.upsertMany(state, action.payload);
        state.loading = false;
      })
      .addCase(getAgents.rejected, (state) => {
        agentAdapter.removeAll(state);
        state.loading = false;
      })
      .addCase(getAgentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgentDetails.fulfilled, (state, action) => {
        agentAdapter.upsertOne(state, action.payload);
        state.loading = false;
      })
      .addCase(getAgentDetails.rejected, (state, action) => {
        agentAdapter.addOne(state, {
          id: action.meta.arg,
          error: action.error,
        });
        state.loading = false;
      })
      .addCase(refreshFacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshFacts.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(refreshFacts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(refreshConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshConfig.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(refreshConfig.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putAgentVars.pending, (state) => {
        state.loading = true;
      })
      .addCase(putAgentVars.fulfilled, (state, action) => {
        agentAdapter.upsertOne(state, { id: action.meta.arg.agentId, vars: action.meta.arg.vars });
        state.loading = false;
      })
      .addCase(putAgentVars.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default agentsSlice.reducer;

export const agentSelectors = agentAdapter.getSelectors((state) => state.agents);

export const selectAgentVars = (state, agentId) => {
  return R.path(["agents", "entities", agentId, "vars"], state);
};

export const selectAgentFacts = (state, agentId) => {
  return R.path(["agents", "entities", agentId, "facts"], state);
};

import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useTranslation } from "react-i18next";

const Select = (props) => {
  const { t } = useTranslation();

  const getSelectOptions = () => {
    return R.map((option) => {
      return <option key={`key-${option}`}>{option}</option>;
    }, props.options);
  };

  return (
    <div id={props.id + "-wrapper"} className="chi-input__wrapper -floating-label">
      <select className="chi-select -lg" id={props.id}>
        <option></option>
        {getSelectOptions()}
      </select>
      <label htmlFor={props.id}>{t(props.label)}</label>
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default Select;

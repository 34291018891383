import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledDivider = styled.div``;

const Divider = (props) => {
  const classes = [
    "chi-divider",
    props.vertical ? "-vertical" : "",
    props.inverse ? "-inverse" : "",
    props.label ? "-label" : "",
    props.size ? `-bt--${props.size}` : "",
    props.className || "",
  ];
  return (
    <StyledDivider className={classes.join(" ").replace(/\s+/g, " ").trim()}>
      {props.label || ""}
    </StyledDivider>
  );
};

Divider.propTypes = {
  vertical: PropTypes.bool,
  inverse: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
};

export default Divider;

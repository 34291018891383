import * as ls from "clients/localStorage";
import * as nav from "utils/nav";
import * as R from "ramda";
import settings from "utils/settings";

export const CAM_OAUTH_URL = `${settings.CAM_URL}/services/security/oauth/authorize`;

// Key names for local storage
export const STATE_TOKEN_KEY = `${ls.PREFIX}.oauthStateToken`;

const extractFromFragment = (location = window.location, binding) => {
  const re = new RegExp(`${binding}=(.+?)(?:&|$)`, "i");
  return R.compose(R.last, R.match(re))(location.hash);
};

export const extractTokenFromFragment = (location = window.location) => {
  return extractFromFragment(location, "access_token");
};

export const extractStateFromFragment = (location = window.location) => {
  return extractFromFragment(location, "state");
};

const generateOauthStateToken = () => {
  const min = 0;
  const max = 281474976710656; // 2^48

  return Math.floor(Math.random() * (max - min)) + min;
};

const buildCamOAuthUrl = (state = null) => {
  let query = [];
  query.push("response_type=token");
  query.push(`client_id=${settings.CAM_OAUTH_CLIENT_ID}`);
  query.push(`redirect_uri=${nav.windowLocationOrigin()}/login`);

  if (state != null) {
    query.push(`state=${state}`);
  }

  return `${CAM_OAUTH_URL}?${query.join("&")}`;
};

export const redirectToCamOAuth = () => {
  const token = generateOauthStateToken();

  ls.saveKey(STATE_TOKEN_KEY, token);

  window.location.assign(buildCamOAuthUrl(token));
};

export const previousStateToken = () => {
  const token = ls.loadKey(STATE_TOKEN_KEY);
  if (token == null) {
    return token;
  }

  return token.toString();
};

export const popPreviousStateToken = () => {
  const token = previousStateToken();
  ls.removeKey(STATE_TOKEN_KEY);

  return token;
};

export const oauthFragmentIsSet = (location = window.location) => {
  const hash = location.hash;
  return R.and(R.not(R.isEmpty(hash)), R.not(R.isNil(hash)));
};

export const oauthStateIsValid = (location = window.location) => {
  const stateToken = extractStateFromFragment(location);
  const prevStateToken = popPreviousStateToken();

  if (stateToken !== prevStateToken) {
    return false;
  }

  return true;
};

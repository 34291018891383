import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import IconButton from "components/common/IconButton";
import Badge from "components/common/Badge";
import { useSelector, useDispatch } from "react-redux";
import {
  selectedCustomerIdSelector,
  setSelectedCustomer,
  customerSelectors,
} from "redux/navigation/navigationSlice";
import { useHistory, useParams } from "react-router-dom";
import watcherTheme from "watcherTheme";
import * as R from "ramda";
import {
  agentSelectors,
  getAgents,
  refreshConfig,
  getAgentDetails,
} from "redux/agents/agentsSlice";
import { resultsSelectors, getAgentResults } from "redux/results/resultsSlice";
import Tabs, { TabContent } from "components/common/Tabs";
import Text from "components/form/Text";
import Spinner from "../form/Spinner";
import AgentResultsTab from "./AgentResultsTab";
import AgentMetadataTab from "components/agents/AgentMetadataTab";
import PageTitle from "components/common/PageTitle";

const AgentDetailsBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin-bottom: 1rem;
  ${({ theme }) => theme.down.md} {
    margin-bottom: 2rem;
  }
`;

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px 10px;
  align-items: center;
`;

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
`;

const StyledDiv = styled.div`
  margin: 0px 10px;
  display: inline-block;
`;

const TextDetail = styled(Text)`
  color: ${({ theme }) => theme.grey[50]};
`;

const AgentDetailsTabs = styled.div`
  min-height: 500px;
`;

const FlexSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  height: 36px;
  margin-right: 1rem;
`;

const AgentDetailsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { agentUuid } = useParams();
  const tenant = useSelector(selectedCustomerIdSelector);
  const selectedAgent = useSelector((state) => agentSelectors.selectById(state, agentUuid)) || {};
  const loading = useSelector((state) => state.agents.loading);
  const selectedAgentResults = useSelector(resultsSelectors.selectAll);

  const agentCustomerId = R.path(["customer_id"], selectedAgent);
  // @TODO:  There is no need to store the entire customer object in
  //  local storage/redux.  We just need the ID.
  const agentCustomer = useSelector((state) =>
    customerSelectors.selectById(state, agentCustomerId)
  );

  useEffect(() => {
    if (R.path(["error"], selectedAgent)) {
      history.push("/agents");
    }
  }, [selectedAgent]);

  useEffect(() => {
    dispatch(getAgentDetails(agentUuid));
    dispatch(getAgentResults(agentUuid));
  }, [agentUuid]);

  if (!R.isEmpty(selectedAgent) && agentCustomer == null) {
    // eslint-disable-next-line no-console
    console.warn(
      `Agent customer id ${agentCustomerId} does not appear to be a customer the user has access to`
    );
  }

  // If user changes tenant, redirect to agent list page
  const usePrev = (value) => {
    const ref = useRef(value);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevTenant = usePrev(tenant);

  // TODO: move this into custom hook and write unit test for hook
  useEffect(() => {
    dispatch(getAgents(tenant));
    if ((tenant || "").toString() != (prevTenant || "").toString() && tenant != agentCustomerId) {
      history.push("/agents");
    }
  }, [tenant]);

  // If the current customer doesn't match the customer
  // on the selected agent, update tenant in navigation store.
  useEffect(() => {
    if (agentCustomerId && tenant != agentCustomerId) {
      dispatch(setSelectedCustomer(agentCustomer));
    }
  }, [agentCustomer]);

  if (loading) {
    return <Spinner id="agent-loading-spinner" size="xl" color="primary" />;
  }

  const handleClick = () => {
    history.push("/agents");
  };

  const textDetails = [
    {
      labels: [
        "common:agents.details.label_operating_system",
        R.path(["facts", "os", "name"], selectedAgent),
        "-",
        R.path(["facts", "os", "release", "full"], selectedAgent),
      ],
    },
    {
      labels: [
        "common:agents.details.label_agent_version",
        R.path(["facts", "build_version"], selectedAgent) || "common:common.not_found",
      ],
    },
    {
      labels: [
        "common:agents.details.label_memory",
        R.path(["facts", "memorysize"], selectedAgent) || "common:common.not_found",
      ],
    },
    {
      labels: [
        "common:agents.details.label_processors",
        R.path(["facts", "processorcount"], selectedAgent) || "common:common.not_found",
      ],
    },
    {
      labels: [
        "common:agents.details.label_ip",
        R.path(["facts", "ipaddress"], selectedAgent) || "common:common.not_found",
      ],
    },
    {
      labels: [
        "common:agents.details.label_instance_id",
        R.path(["registration", "instance_id"], selectedAgent) || "common:common.not_found",
      ],
    },
  ];

  const getTextDetails = () => {
    const mapIndexed = R.addIndex(R.map);
    return mapIndexed((textDetail, index) => {
      return <TextDetail size="sm" className="-my--0 -mr--2" key={index} t={textDetail.labels} />;
    }, textDetails);
  };

  const getStatusBadge = () => {
    return R.path(["connected"], selectedAgent) ? (
      <Badge id="status-badge" color={watcherTheme.green[70]} t="common:agents.status_online" />
    ) : (
      <Badge id="status-badge" color={watcherTheme.red[70]} t="common:agents.status_offline" />
    );
  };

  const refreshAgentConfig = () => {
    dispatch(refreshConfig({ customerId: tenant, agentIds: [selectedAgent.id] }));
  };

  return (
    <React.Fragment>
      <FlexSection>
        <StyledIconButton
          id="return"
          icon="icon-chevron-left"
          onClick={handleClick}
        ></StyledIconButton>
        <PageTitle size="h3" t={["common:page_navigation.agent_details"]} />
      </FlexSection>
      <AgentDetailsBody>
        <StyledDiv>
          <img
            src="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
            style={{ height: 110, width: 110 }}
          />
        </StyledDiv>
        <FlexColumnDiv>
          <FlexRowDiv>
            {getStatusBadge()}
            <StyledDiv>
              <Text size="lg" m="0" t={["common:agents.details.config_update"]} />
            </StyledDiv>
            {/*TODO: Hook up to API*/}
          </FlexRowDiv>
          <FlexRowDiv>
            <h4 id="selected-agent-name">
              {R.path(["display_name"], selectedAgent)}{" "}
              {R.path(["suppressed"], selectedAgent) ? "(Suppressed)" : ""}
            </h4>
            <StyledDiv>
              <IconButton id="button-refresh" icon="icon-refresh" onClick={refreshAgentConfig} />
            </StyledDiv>
          </FlexRowDiv>
          <FlexRowDiv>{getTextDetails()}</FlexRowDiv>
        </FlexColumnDiv>
      </AgentDetailsBody>
      <AgentDetailsTabs>
        <Tabs>
          <TabContent
            exact
            to={`/agents/${agentUuid}/results`}
            id="agent-results-tab"
            title="common:agents.results.results_tab"
          >
            <AgentResultsTab data={selectedAgentResults || []} agentUuid={agentUuid} />
          </TabContent>
          <TabContent
            to={`/agents/${agentUuid}/metadata`}
            id="agent-metadata-tab"
            title="common:agents.results.metadata_tab"
          >
            <AgentMetadataTab
              agentId={agentUuid}
              agentScope={R.path(["workspace_id"], selectedAgent)}
            />
          </TabContent>
          <TabContent
            to={`/agents/${agentUuid}/history`}
            id="agent-history-tab"
            title="common:agents.results.history_tab"
          ></TabContent>
        </Tabs>
      </AgentDetailsTabs>
    </React.Fragment>
  );
};

export default AgentDetailsPage;

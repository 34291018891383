import styled from "styled-components";
import React, { useState } from "react";
import Table from "components/table/Table";
import Modal from "components/modal/Modal";
import Slider from "components/form/Slider";
import Select from "components/form/Select";
import ChiSlider from "components/form/ChiSlider";
import Popover from "components/modal/Popover";
import Button from "components/common/Button";
import CheckBox from "components/form/CheckBox";
import NumInput from "components/form/NumInput";
import TextInput from "components/form/TextInput";
import ToggleSwitch from "components/form/ToggleSwitch";
import Dropdown, { DropdownItem } from "components/menu/Dropdown";
import Notifications from "./notification/Notifications";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { addNotification } from "redux/notifications/notificationSlice";
/* eslint-disable no-console */

const columns = [
  {
    Header: "Column 1",
    accessor: "col1", // accessor is the "key" in the data
  },
  {
    Header: "Column 2",
    accessor: "col2",
  },
];

const data = [
  {
    col1: "Hello",
    col2: "World",
  },
  {
    col1: "react-table",
    col2: "rocks",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
  {
    col1: "whatever",
    col2: "you want",
  },
];

const sliderOptions = [
  [0, "Option 1"],
  [1, "Option 2"],
  [2, "Option 3"],
];

const labels = ["OFF", "Alerting", "Managed"];

const DebugWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 50px 10% 50px 10%;
  flex-direction: column;
`;
const DebugGroup = styled.div`
  display: flex;
`;

const DebugItemWrapper = styled.div`
  padding: 5px;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const SliderWrapper = styled.div`
  margin: 25px auto;
  width: 75%;
`;

const SelectWrapper = styled.div`
  margin: 25px auto;
  width: 75%;
`;

const Debug = () => {
  const [modalReferenceElement, setModalReferenceElement] = useState(null);
  console.log(modalReferenceElement);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [referenceElement2, setReferenceElement2] = useState(null);
  console.log(referenceElement2);
  const [show2, setShow2] = useState(false);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  return (
    <DebugWrapper>
      <DebugGroup>
        <DebugItemWrapper>
          <Button
            onClick={() => {
              dispatch(addNotification(["", "I am a Base Notification", true]));
              dispatch(
                addNotification(["success", "I am a Success Notification", true, "Success"])
              );
              dispatch(addNotification(["danger", "I am an Error Notification", false, "Error"]));
              dispatch(
                addNotification(["warning", "I am a Warning Notification", false, "Warning"])
              );
              dispatch(addNotification(["info", "I am a Info Notification", false, "Note"]));
              dispatch(addNotification(["muted", "I am a Muted Notification", true]));
            }}
          >
            I create Alerts
          </Button>
        </DebugItemWrapper>
        <DebugItemWrapper>
          <Dropdown id="debug-dropdown-wrapper" text="I am a menu">
            <DropdownItem id="dropdown-item" onClick={() => ""}>
              I do something
            </DropdownItem>
          </Dropdown>
        </DebugItemWrapper>
        <DebugItemWrapper>
          <Button
            id="open-modal-button"
            onClick={() => {
              setModalIsOpen(!modalIsOpen);
            }}
            data-target="#modal-1"
            className="chi-modal__trigger"
          >
            I open a modal
          </Button>

          <Modal
            id="debug-modal"
            title="Some Title"
            isOpen={modalIsOpen}
            handleClose={() => setModalIsOpen(false)}
          >
            <Button
              id="debug-popover-1-button"
              className="-primary"
              getRef={setModalReferenceElement}
              onClick={() => setShow(!show)}
            >
              I Open a Popover
            </Button>
            <Popover
              id="debug-popover-1"
              onClick={() => setShow(false)}
              handleClose={() => setShow(false)}
              refElement={modalReferenceElement}
              placement="bottom"
              show={show}
              text="rofl"
            />
          </Modal>
        </DebugItemWrapper>
        <DebugItemWrapper>
          <Button
            id="debug-popover-2-button"
            className="-primary"
            getRef={setReferenceElement2}
            onClick={() => setShow2(!show2)}
          >
            I open a popover
          </Button>
          <Popover
            id="debug-popover-2"
            handleClose={() => setShow2(false)}
            refElement={referenceElement2}
            placement="bottom"
            show={show2}
            text="rofl"
          />
        </DebugItemWrapper>
      </DebugGroup>

      <DebugGroup>
        <DebugItemWrapper>
          <CheckBox
            id="debug-checkbox-1"
            onChange={(e) => console.log(e)}
            label="I am checked"
            checked={true}
          />
          <CheckBox
            id="debug-checkbox-2"
            onChange={(e) => console.log(e)}
            label="I am indeterminate"
            indeterminate={true}
          />
          <CheckBox id="debug-checkbox-3" onChange={() => "console.log(e)"} />
        </DebugItemWrapper>
        <DebugItemWrapper>
          <NumInput id="debug-num-input" label="Some Label" onChange={(e) => console.log(e)} />
        </DebugItemWrapper>
        <DebugItemWrapper>
          <TextInput
            id="debug-text-input"
            label="Some label"
            placeholder="Some placeholder"
            error
            onChange={(e) => console.log(e)}
            message="Some message about input/error"
          />
        </DebugItemWrapper>
        <DebugItemWrapper>
          <TextInput
            id="debug-text-input"
            label="Some label"
            placeholder="Some placeholder"
            error
            onChange={(e) => console.log(e)}
            message="Some message about input/error"
            iconLeft="icon-search"
          />
        </DebugItemWrapper>
        <DebugItemWrapper>
          <TextInput
            id="debug-text-input"
            label="Some label"
            placeholder="Some placeholder"
            error
            onChange={(e) => console.log(e)}
            message="Some message about input/error"
            iconRight="icon-search"
          />
        </DebugItemWrapper>
        <DebugItemWrapper>
          <ToggleSwitch
            id="debug-toggle"
            leftLabel="Some left label"
            rightLabel="Some right label"
          />
        </DebugItemWrapper>
      </DebugGroup>
      <TableWrapper>
        <Table
          id="debug-table"
          getSelectedRowIds={(e) => console.log(e)}
          columns={columns}
          data={data}
          selectable
        />
      </TableWrapper>
      <SliderWrapper>
        <ChiSlider
          id="test-slider"
          options={sliderOptions}
          label="Example Slider"
          min="0"
          max="2"
        />
      </SliderWrapper>
      <SliderWrapper>
        <Slider
          id="test-ranger-slider"
          name="React-Ranger Slider"
          labels={labels}
          min={0}
          max={2}
        />
      </SliderWrapper>
      <SelectWrapper>
        <Select
          id="debug-eelect"
          label="Debug select"
          options={["Option 1", "Option 2", "Option 3"]}
        />
      </SelectWrapper>
      <DebugGroup>
        {/* <Tabs>
          <TabContent exact to={`/debug`} id="debug-tab-0" title="Results">
            Some Default Route
          </TabContent>
          <TabContent to={`/debug/thing`} id="debug-tab-1" title="Metadata">
            Thing
          </TabContent>
          <TabContent to={`/debug/other`} id="debug-tab-2" title="History">
            other
          </TabContent>
        </Tabs> */}
      </DebugGroup>
      <Notifications />
      <DebugItemWrapper>
        <div style={{ display: "block" }}>
          <Dropdown id="debug-changeLanguage" text="Chaneg Language">
            <DropdownItem id="dropdown-item-2" onClick={() => i18n.changeLanguage("en")}>
              English
            </DropdownItem>
            <DropdownItem id="dropdown-item-2" onClick={() => i18n.changeLanguage("jp")}>
              Japanese
            </DropdownItem>
          </Dropdown>

          <div>Translations</div>
          <div style={{ marginTop: "10px" }}>
            {t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.name")}
          </div>
          <div>{t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.description")}</div>
          <div style={{ marginTop: "10px" }}>PARAMETERS:</div>
          <div>
            {t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.params.host.label")}:
            {t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.params.host.description")}
          </div>
          <div>
            {t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.params.port.label")}:
            {t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.params.port.description")}
          </div>
          <div>
            {t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.params.path.label")}:
            {t("check_types:5bcc5a2e-5402-4828-805e-d83edb713350.params.path.description")}
          </div>
        </div>
      </DebugItemWrapper>
    </DebugWrapper>
  );
};

export default Debug;

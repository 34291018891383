import watcherClient from "clients/watcherClient";
import * as R from "ramda";

const postRefreshFacts = async (source, customerId, agentIds) => {
  let resp = await watcherClient("v2").post(
    `/agents/${source}/customer/${customerId}/facts/refresh`,
    { agent_ids: agentIds }
  );

  return resp.data;
};

const postRefreshConfig = async (source, customerId, agentIds) => {
  let resp = await watcherClient("v2").post(
    `/agents/${source}/customer/${customerId}/checks/refresh`,
    { agent_ids: agentIds }
  );

  return resp.data;
};

const getAgents = async (source, customerId) => {
  let resp = await watcherClient("v2").get(`/agents/${source}/customer/${customerId}`, {});
  let data = resp.data.map(mapAgentDisplayName);
  return data;
};

const getAgentDetails = async (agentId) => {
  let resp = await watcherClient("v2").get(`/agents/${agentId}`, {});
  return resp.data;
};

const getAgentResults = async (agentId) => {
  let resp = await watcherClient("v2").get(`/agents/${agentId}/results`, {});
  return resp.data;
};

const putAgentVars = async (scope, agentId, vars) => {
  let resp = await watcherClient().put(`/agents/${scope}/${agentId}/vars`, vars);
  return resp.data;
};

const mapAgentDisplayName = (agent) => {
  agent.display_name =
    R.path(["external_data", "server_name"], agent) ||
    R.path(["facts", "hostname"], agent) ||
    agent.id;
  return agent;
};

export default {
  postRefreshFacts,
  postRefreshConfig,
  getAgents,
  getAgentDetails,
  getAgentResults,
  putAgentVars,
};

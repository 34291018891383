import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "components/common/Button";
import InfoBox from "components/common/InfoBox";
import Icon from "components/common/Icon";
import Slider from "components/form/Slider";
import Text from "components/form/Text";
import Popover from "components/modal/Popover";
import PopoverAnchor from "components/modal/PopoverAnchor";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import { enablementServerSelectors } from "redux/enablement/enablementSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { msaConfigOptions as options } from "utils/globals.js";
import settings from "utils/settings";
import * as R from "ramda";
import { evaluateServiceManagement, compareServiceManagement } from "utils/serviceManagement";
import PageTitle from "components/common/PageTitle";

const ConfigPaneWrapper = styled.div`
  overflow-x: hidden;
  height: 100%;
  ${({ theme }) => theme.down.lg} {
    padding: 0px 30px;
  }
`;

const ConfigPane = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0px 0px 40px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  padding-right: 30px;
  margin: 1rem 0;
  ${({ theme }) => theme.down.lg} {
    width: 100%;
    padding-right: 0px;
  }
`;

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  padding-right: 30px;
  margin: 1rem 0;
  ${({ theme }) => theme.down.lg} {
    width: 100%;
    padding-right: 0px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 1rem 0;
  ${({ theme }) => theme.down.lg} {
    width: 100%;
  }
`;

const HeaderWrapper = styled.span`
  display: flex;
  align-items: center;
  border-bottom: 1px ${({ theme }) => theme.grey[25]} solid;
  height: 2em;
`;

const ColumnHeaderText = styled(Text)`
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const InfoBoxWrapper = styled.div`
  margin-top: 20px;
`;

const AsteriskNote = styled(Text)`
  margin-top: 1rem;
`;

const ServerList = styled.div`
  max-height: 300px;
  margin-top: 20px;
  overflow-y: auto;
`;

const StyledServerNames = styled.div`
  width: 100%;
  margin: 0px auto;
`;

const StyledAnchor = styled(PopoverAnchor)`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-self: center;
`;

const ServerListItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  height: 2rem;
  padding: 2px 15px;
  margin: 0px;
  text-align: left;
  cursor: default;
  overflow: hidden;
  align-items: center;
  ${({ theme }) => `&: nth-child(even) {
    background-color: ${theme.grey[20]};
  }`}
  ${({ theme }) => `&: nth-child(odd) {
    background-color: ${theme.grey[25]};
  }`}
`;

const StyledServerName = styled(Text)`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 0.3rem;
`;

const SliderGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  height: 230px;
  width: 80%;
  margin: 30px auto;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0px 0px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const StyledAboutText = styled.div`
  text-align: justify;
  max-height: 450px;
  margin-top: 15px;
  overflow-y: auto;
`;

const StyledParagraph = styled.p`
  margin: 0px 0px 3px;
`;

const StyledSubheader = styled.p`
  font-weight: 500;
  text-transform: uppercase;
  margin: 12px 0px 3px;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 5px;
`;

const CurrentValuesDiv = styled(FlexDiv)`
  padding-right: 12px;
`;

const usePrev = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getInstanceId = (tags) => {
  if (tags && tags.length > 0) {
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].name === "Morpheus Instance Id") {
        return Number(tags[i].value);
      }
    }
  }
  return null;
};

const ConfigureMSA = (props) => {
  const { t } = useTranslation();
  const tenant = useSelector(selectedCustomerIdSelector);
  const servers = useSelector(enablementServerSelectors.selectAll);
  const selectedServers = R.compose(R.values, R.pickAll)(R.keys(props.serverIndices), servers);

  const [sliderValues, setSliderValues] = useState({ os: 0, ws: 0, db: 0 });
  const prevSliders = usePrev(sliderValues || []);
  const [hasChanges, setHasChanges] = useState({});
  const mapIndexed = R.addIndex(R.map);

  const labels = R.map((option) => {
    return option[1];
  }, Object.values(options));

  const handleOsChange = ([value]) => {
    const currentValues = R.clone(sliderValues);
    currentValues.os = value;
    setSliderValues(currentValues);
  };

  const handleWsChange = ([value]) => {
    const currentValues = R.clone(sliderValues);
    currentValues.ws = value;
    setSliderValues(currentValues);
  };

  const handleDbChange = ([value]) => {
    const currentValues = R.clone(sliderValues);
    currentValues.db = value;
    setSliderValues(currentValues);
  };

  const getSliderValue = (type) => {
    const value = R.path([0, "service_management", type], selectedServers);
    if (!value) {
      return 0;
    } else {
      return labels.findIndex((item) => item.substring(item.lastIndexOf(".") + 1) === value);
    }
  };

  const getParams = () => {
    let body = R.map((server) => {
      return {
        service_management: {
          os: labels[sliderValues.os].substring(labels[sliderValues.os].lastIndexOf(".") + 1),
          ws: labels[sliderValues.ws].substring(labels[sliderValues.ws].lastIndexOf(".") + 1),
          db: labels[sliderValues.db].substring(labels[sliderValues.db].lastIndexOf(".") + 1),
        },
        external_data: {
          source: "edge",
          tenant_id: tenant,
          url: `${settings.EDGE_URL}/infrastructure/servers/${server.id}`,
          type: "server",
          instance_id: getInstanceId(server.tags),
          server_id: server.id,
        },
      };
    }, selectedServers);
    const params = {
      source: "edge",
      customerId: tenant,
      body: body,
    };
    return params;
  };

  const handleOpenConfirm = () => {
    props.handleOpenModal(getParams());
  };

  useEffect(() => {
    const currentValues = R.clone(sliderValues);
    if (sliderValues.os !== prevSliders.os) {
      if (sliderValues.os < sliderValues.ws) {
        currentValues.ws = sliderValues.os;
      }
      if (sliderValues.os < sliderValues.db) {
        currentValues.db = sliderValues.os;
      }
    } else if (sliderValues.ws !== prevSliders.ws) {
      if (sliderValues.os < sliderValues.ws) {
        currentValues.os = sliderValues.ws;
      }
    } else if (sliderValues.db !== prevSliders.db) {
      if (sliderValues.os < sliderValues.db) {
        currentValues.os = sliderValues.db;
      }
    }
    setSliderValues(currentValues);
  }, [sliderValues.os, sliderValues.ws, sliderValues.db]);

  useEffect(() => {
    let values = {
      os: selectedServers.length === 1 ? getSliderValue("os") : 0,
      ws: selectedServers.length === 1 ? getSliderValue("ws") : 0,
      db: selectedServers.length === 1 ? getSliderValue("db") : 0,
    };
    setSliderValues(values);
    setHasChanges({});
  }, [props.showConfig]);

  useEffect(() => {
    let tmp = {};
    mapIndexed((server, index) => {
      tmp = R.set(
        R.lensProp(index),
        !compareServiceManagement(
          evaluateServiceManagement(server.service_management),
          sliderValues
        ),
        tmp
      );
    }, selectedServers);
    setHasChanges(tmp);
  }, [sliderValues, props.showConfig]);

  const ServerNames = () => {
    let list = R.map((server) => {
      let sm = evaluateServiceManagement(server.service_management);
      const [show, setShow] = useState(false);
      const [cellRefElement, setCellRefElement] = useState(null);

      const handlePopoverOpen = () => {
        setShow(true);
      };
      const handlePopoverClose = () => {
        setShow(false);
      };

      return (
        <ServerListItem key={server.id}>
          <StyledServerName asterisk={!compareServiceManagement(sm, sliderValues)}>
            {server.name}
          </StyledServerName>
          <StyledAnchor
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            getRef={setCellRefElement}
          >
            <StyledIcon
              id={`info-icon-${server.id}`}
              icon="icon-circle-info-outline"
              size="xs"
              className="-icon--info"
            />
          </StyledAnchor>
          <Popover
            id={"popover-" + server.id}
            title={server.name}
            refElement={cellRefElement || {}}
            placement="right"
            show={show}
            handleClose={handlePopoverClose}
          >
            <FlexDiv>
              <CurrentValuesDiv>
                <Text
                  size="md"
                  lh="0"
                  t={["common:enablement.msa_os", labels[sm.os]]}
                  asterisk={sm.os !== sliderValues.os}
                />
              </CurrentValuesDiv>
              <CurrentValuesDiv>
                <Text
                  size="md"
                  lh="0"
                  t={["common:enablement.msa_ws", labels[sm.ws]]}
                  asterisk={sm.ws !== sliderValues.ws}
                />
              </CurrentValuesDiv>
              <CurrentValuesDiv>
                <Text
                  size="md"
                  lh="0"
                  t={["common:enablement.msa_db", labels[sm.db]]}
                  asterisk={sm.db !== sliderValues.db}
                />
              </CurrentValuesDiv>
            </FlexDiv>
          </Popover>
        </ServerListItem>
      );
    }, selectedServers);
    return <React.Fragment>{list}</React.Fragment>;
  };

  const AboutMSALevels = () => {
    return (
      <StyledAboutText>
        <StyledParagraph>{t("common:enablement.configureMSA.about_msa.overview")}</StyledParagraph>
        <StyledSubheader>
          {t("common:enablement.configureMSA.configOptions.managed")}
        </StyledSubheader>
        <StyledParagraph>{t("common:enablement.configureMSA.about_msa.managed")}</StyledParagraph>
        <StyledSubheader>
          {t("common:enablement.configureMSA.configOptions.alerting")}
        </StyledSubheader>
        <StyledParagraph>{t("common:enablement.configureMSA.about_msa.alerting")}</StyledParagraph>
        <StyledSubheader>{t("common:enablement.configureMSA.configOptions.off")}</StyledSubheader>
        <StyledParagraph>{t("common:enablement.configureMSA.about_msa.off")}</StyledParagraph>
      </StyledAboutText>
    );
  };

  return (
    <React.Fragment>
      {props.showConfig && (
        <ConfigPaneWrapper>
          <PageTitle size="h4" t={["common:enablement.configureMSA.config_title"]} />
          <ConfigPane>
            <LeftColumn>
              <HeaderWrapper>
                <ColumnHeaderText t={["common:enablement.configureMSA.column_selected_servers"]} />
              </HeaderWrapper>
              {selectedServers.length > 1 && (
                <InfoBoxWrapper>
                  <InfoBox t={["common:enablement.configureMSA.infoBox"]} />
                </InfoBoxWrapper>
              )}
              <ServerList>
                <StyledServerNames>
                  <ServerNames />
                </StyledServerNames>
              </ServerList>
              {R.find(R.equals(true), R.values(hasChanges)) && (
                <AsteriskNote
                  className="-text--danger"
                  t={["common:enablement.configureMSA.asterisk_note"]}
                />
              )}
            </LeftColumn>
            <CenterColumn>
              <HeaderWrapper>
                <ColumnHeaderText t={["common:enablement.configureMSA.column_configure_levels"]} />
              </HeaderWrapper>
              <SliderGroup>
                <Slider
                  id="os-slider"
                  name="common:enablement.configureMSA.slider_os"
                  min={0}
                  max={labels.length - 1}
                  labels={labels}
                  defaultValues={[sliderValues.os]}
                  onChange={handleOsChange}
                />
                <Slider
                  id="ws-slider"
                  name="common:enablement.configureMSA.slider_ws"
                  min={0}
                  max={labels.length - 1}
                  labels={labels}
                  defaultValues={[sliderValues.ws]}
                  onChange={handleWsChange}
                />
                <Slider
                  id="db-slider"
                  name="common:enablement.configureMSA.slider_db"
                  min={0}
                  max={labels.length - 1}
                  labels={labels}
                  defaultValues={[sliderValues.db]}
                  onChange={handleDbChange}
                />
              </SliderGroup>
              <ButtonRow>
                <StyledButton
                  id="config-cancel-button"
                  onClick={props.handleClose}
                  t={["common:common.button_cancel"]}
                />
                <StyledButton
                  id="config-save-button"
                  className="-primary"
                  onClick={handleOpenConfirm}
                  t={["common:common.button_save"]}
                />
              </ButtonRow>
            </CenterColumn>
            <RightColumn>
              <HeaderWrapper>
                <ColumnHeaderText t={["common:enablement.configureMSA.column_about_levels"]} />
              </HeaderWrapper>
              <AboutMSALevels />
            </RightColumn>
          </ConfigPane>
        </ConfigPaneWrapper>
      )}
    </React.Fragment>
  );
};

ConfigureMSA.propTypes = {
  showConfig: PropTypes.bool.isRequired,
  serverIndices: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default ConfigureMSA;

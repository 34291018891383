import axios from "axios";
import * as localStorage from "clients/localStorage";
import settings from "utils/settings";

export const defaultWatcherUrl = () => {
  let host;

  host = window.location.hostname;

  if (
    host === "localhost.watcher.ctl.io" ||
    host === "edge.localhost.watcher.ctl.io" ||
    host === "cam.localhost.watcher.ctl.io"
  ) {
    if (settings.NODE_ENV === "local-dev" || settings.NODE_ENV === "local") {
      host = "local.watcher.ctl.io";
    } else if (settings.NODE_ENV === "prod" || settings.NODE_ENV === "production") {
      host = "watcher.ctl.io";
    } else if (settings.NODE_ENV === "test" || settings.NODE_ENV === "dev") {
      host = "dev.watcher.ctl.io";
    } else {
      host = "local.watcher.ctl.io";
    }
  }

  if (host.startsWith("edge")) {
    host = host.substring(host.indexOf(".") + 1);
  }

  return `https://api.${host}/`;
};

const baseUrl = defaultWatcherUrl();

export const headers = (apiVersion = "v1") => {
  return {
    Authorization: `Bearer ${localStorage.loadKey(localStorage.APITOKEN)}`,
    "Accept-Version": apiVersion,
  };
};

const watcherClient = (apiVersion = "v1") => {
  let client = axios.create({
    baseURL: baseUrl,
    timeout: 5000,
    headers: headers(apiVersion),
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // eslint-disable-next-line
      console.error(error);
      return Promise.reject(error);
    }
  );

  return client;
};

export default watcherClient;

export const msaConfigOptions = {
  off: [0, "common:enablement.configureMSA.configOptions.off"],
  alerting: [1, "common:enablement.configureMSA.configOptions.alerting"],
  managed: [2, "common:enablement.configureMSA.configOptions.managed"],
};

export const toTitleCase = (str) => {
  return !str.includes(" ")
    ? str[0].toUpperCase() + str.slice(1)
    : str
    /* eslint-disable */
      .split(" ")
      .map((word) => {
          return word[0].toUpperCase() + (word.length > 1 ? word.slice(1) : "");
        })
        .join(" ");
    /* eslint-enable */
};

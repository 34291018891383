import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { Route, Link, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";

import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

export const TabContent = (props) => {
  return (
    <Route role="tab" exact path={props.to}>
      {props.children}
    </Route>
  );
};

TabContent.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
};

const Tab = (props) => {
  let match = useRouteMatch({
    path: props.to,
    exact: props.exact || false,
  });
  useEffect(() => {
    if (match) props.setSelectedTab(props.to);
  }, [match]);
  return (
    <li ref={props.setRef} id={`${props.id}-wrapper`} className={match ? "-active" : ""}>
      <Link
        to={props.to}
        id={`${props.id}-link`}
        key={`${props.id}-link`}
        style={{ borderBottom: "0px" }}
      >
        {props.title}
      </Link>
    </li>
  );
};

Tab.propTypes = {
  exact: PropTypes.bool,
  children: PropTypes.node,
  setSelectedTab: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

// consts
const Tabs = (props) => {
  // TODO: Finish underline sliding
  const [tabOffsets, setTabOffsets] = useState({});
  const [selectedTab, setSelectedTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const sliderStyles = useSpring({
    width: tabOffsets[selectedTab] && tabOffsets[selectedTab][0],
    left: tabOffsets[selectedTab] && tabOffsets[selectedTab][1],
    display: "unset",
  });
  const { t } = useTranslation();

  useEffect(() => {
    const tmpOffsets = {};
    let tmpCount = 0;
    const children = Array.isArray(props.children) ? props.children : [props.children];
    setTabs(
      R.map((child) => {
        const setRef = (el) => {
          if (!el) return;
          let margin = Number(window.getComputedStyle(el).marginLeft.replace("px", ""));
          let width = el.offsetWidth;

          tmpOffsets[child.props.to] = [width, tmpCount + margin];

          tmpCount += margin + width;
        };
        return (
          <Tab
            setSelectedTab={setSelectedTab}
            setRef={setRef}
            title={t(child.props.title)}
            key={`${child.props.id}-tab-wrapper`}
            id={child.props.id}
            exact={child.props.exact}
            to={child.props.to}
          />
        );
      }, children)
    );
    setTabOffsets(tmpOffsets);
  }, []);

  return (
    <React.Fragment>
      <ul className="chi-tabs -border">
        {tabs}
        <animated.li className="chi-sliding-border" style={sliderStyles}></animated.li>
      </ul>
      {props.children}
    </React.Fragment>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;

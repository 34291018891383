import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEvents, eventsSelectors } from "redux/events/eventsSlice";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import EventsTable from "components/events/EventsTable";
import SuppressionConfig from "components/suppression/SuppressionConfig";
import PageTitle from "components/common/PageTitle";

const EventsPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector(selectedCustomerIdSelector);
  const events = useSelector(eventsSelectors.selectAll);
  const [suppression, setSuppression] = useState({});
  const [suppressConfig, setSuppressConfig] = useState(false);

  const handleGetSuppression = (obj) => {
    setSuppression(obj);
  };

  const handleOpenSuppression = () => {
    setSuppressConfig(true);
  };

  const handleCloseSuppression = () => {
    setSuppressConfig(false);
  };

  useEffect(() => {
    dispatch(getEvents(tenant));
  }, [tenant]);

  return (
    <React.Fragment>
      {!suppressConfig && (
        <React.Fragment>
          <PageTitle size="h3" t={["common:page_navigation.events"]} />
          <EventsTable
            data={events}
            openSuppressConfig={handleOpenSuppression}
            getSuppression={handleGetSuppression}
          />
        </React.Fragment>
      )}
      {suppressConfig && (
        <SuppressionConfig suppression={suppression} handleClose={handleCloseSuppression} />
      )}
    </React.Fragment>
  );
};

export default EventsPage;

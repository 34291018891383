import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import watcherTheme from "watcherTheme";
import { useTranslation } from "react-i18next";
import Icon from "components/common/Icon";

const StyledBadge = styled.div`
  && {
    color: ${watcherTheme.white};
    background-color: ${(props) => props.color};
    background-color: 0.0625rem solid ${(props) => props.color};
  }
`;

const StyledIconBadge = styled.div`
  && {
    color: ${(props) => props.color};
  }
`;

const Badge = (props) => {
  const { t } = useTranslation();

  return props.icon ? (
    <StyledIconBadge
      id={props.id}
      className={`chi-badge -flat -px--0 ${props.className}`}
      color={props.color}
    >
      <div className="chi-badge__content">
        <Icon id="test" icon={props.icon} color={props.color} />
        <span>{props.t ? t(props.t) : props.children}</span>
      </div>
    </StyledIconBadge>
  ) : (
    <StyledBadge id={props.id} className={`chi chi-badge ${props.className}`} color={props.color}>
      <span>{props.t ? t(props.t) : props.children}</span>
    </StyledBadge>
  );
};

Badge.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  t: PropTypes.string,
  icon: PropTypes.string,
};

export default withTheme(Badge);

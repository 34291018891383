import { createSlice, createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";
import suppressionClient from "clients/suppressionClient";
import { addNotification } from "redux/notifications/notificationSlice";
// Note: This is not in use yet, but left here because it will be needed for getting suppressions
const suppressionAdapter = createEntityAdapter();

export const postSuppression = createAsyncThunk(
  "suppression/postSuppression",
  async ({ scope, body }, { dispatch }) => {
    return await suppressionClient
      .postSuppression(scope, body)
      .then(() => {
        // eslint-disable-next-line
        dispatch(addNotification(["success", "common:suppression.notifications.success.suppression", true, "Success"]));
      })
      .catch(() => {
        // eslint-disable-next-line
        dispatch(addNotification(["danger", "common:suppression.notifications.error.suppression", true, "Error"]));
      });
  }
);

const initialState = suppressionAdapter.getInitialState({
  loading: false,
});

const suppressionSlice = createSlice({
  name: "suppression",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSuppression.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSuppression.fulfilled, (state) => {
        state.loading = false;
      });
    // @TODO: Handle errors.  Note a not found here implies the customer was never setup for MSA
    // (the service failed to find the customer).
  },
});

export default suppressionSlice.reducer;

export const suppressionServerSelectors = suppressionAdapter.getSelectors(
  (state) => state.suppression
);

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as R from "ramda";
import Text from "components/form/Text";
import TextArea from "components/form/TextArea";
import FuzzyTime from "components/common/FuzzyTime";
import watcherTheme from "watcherTheme";
import Link from "components/form/Link";
import Status from "components/details/Status";
import Command from "components/details/Command";
import { toTitleCase } from "utils/globals.js";
import { useTranslation } from "react-i18next";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0px 0px 40px;
  padding-top: 15px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-right: 30px;
  ${({ theme }) => theme.down.lg} {
    width: 100%;
    padding-right: 0px;
  }
`;

const Subsection = styled.div`
  margin-bottom: 1rem;
`;

const FlexSection = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 2rem;
  border-bottom: 1px solid ${watcherTheme.grey[20]};
  padding: 0.5rem 0.4rem 0.5rem 0;
`;

const SubheaderSection = styled(FlexSection)`
  border-bottom: 2px solid ${watcherTheme.grey[20]};
  padding: 5px 0;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  ${({ theme }) => theme.down.lg} {
    width: 100%;
  }
`;

const InlineItems = styled.div`
  display: flex;
`;

const FixedWidthCategory = styled(Text)`
  min-width: 160px;
`;

const EventDetailsTab = (props) => {
  const { t } = useTranslation();

  const SubheaderText = (properties) => {
    return (
      <Text size="xl" m="0" className="-mt--1 -text--bold" t={properties.t}>
        {properties.children}
      </Text>
    );
  };

  const CategoryText = (properties) => {
    return (
      <FixedWidthCategory className="-text--bolder" m="0" t={properties.t}>
        {properties.children}
      </FixedWidthCategory>
    );
  };

  const InfoText = (properties) => {
    return (
      <Text className="-pr--2" m="0" size="md" t={properties.t}>
        {properties.children}
      </Text>
    );
  };

  const InfoObject = (properties) => {
    return <InlineItems className="-text--md -m--0">{properties.children}</InlineItems>;
  };

  const getStatus = () => {
    let data = R.path(["check", "status"], props.event);
    let status = data === 0 ? 0 : data ? data : 3; // 0 was falsy
    return (
      <FlexSection>
        <CategoryText t={["common:events.details.status"]} />
        <div className="-text--md">
          <Status id={"status"} status={status} />
        </div>
      </FlexSection>
    );
  };

  const getOccurrences = () => {
    let occurrences = R.path(["occurrences"], props.event);
    return occurrences ? (
      <FlexSection id="occurrences">
        <CategoryText t={["common:events.details.occurrences"]} />
        <InfoText>{occurrences}</InfoText>
      </FlexSection>
    ) : (
      ""
    );
  };

  const getLastOccurrence = () => {
    return (
      <FlexSection>
        <CategoryText t={["common:events.details.last_occurrence"]} />
        <InfoObject>
          <FuzzyTime
            id={`details-last-occurrence-${R.path(["id"], props.event)}`}
            time={R.path(["check", "executed"], props.event) * 1000}
          />
        </InfoObject>
      </FlexSection>
    );
  };

  const getFirstOccurrence = () => {
    let occ = R.path(["occurrences"], props.event);
    let int = R.path(["check", "interval"], props.event);
    if (occ && int) {
      let now = new Date().getTime() / 1000;
      let secondsElapsed =
        R.path(["occurrences"], props.event) * R.path(["check", "interval"], props.event);
      return (
        <FlexSection id="first-occurrence">
          <CategoryText t={["common:events.details.first_occurrence"]} />
          <InfoObject>
            <FuzzyTime
              id={`details-first-occurrence-${R.path(["id"], props.event)}`}
              time={new Date((now - secondsElapsed) * 1000)}
            />
          </InfoObject>
        </FlexSection>
      );
    } else {
      return "";
    }
  };

  const getSuppressionStatus = () => {
    let suppressed = R.path(["silenced"], props.event)
      ? "common:events.details.yes"
      : "common:events.details.no";
    return (
      <FlexSection>
        <CategoryText t={["common:events.details.check_suppressed"]} />
        <InfoText id="suppression-status" t={[suppressed]} />
      </FlexSection>
    );
  };

  const getCheckType = () => {
    let checkType = R.path(["check", "check_type"], props.event);
    let checkName = R.path(["check", "name"], props.event);
    return (
      <FlexSection>
        <CategoryText t={["common:events.details.type"]} />
        <InfoText id="check-type">
          {checkType || checkName ? toTitleCase(checkType || checkName) : ""}
        </InfoText>
      </FlexSection>
    );
  };

  const getPolicyLink = () => {
    let policyId = R.path(["check", "watcher_policy_id"], props.event);
    return policyId ? (
      <FlexSection id="policy">
        <CategoryText t={["common:events.details.policy"]} />
        <InfoObject id="policy-name">
          <Link
            id="policy-link"
            to={`/policies/${policyId}`}
            tooltip={t("common:tooltips.policy_info")}
          >
            {R.path(["check", "watcher_policy_name"], props.event)}
          </Link>
        </InfoObject>
      </FlexSection>
    ) : (
      ""
    );
  };

  const getAgentName = () => {
    let id = R.path(["client", "id"], props.event);
    let name =
      R.path(["client", "display_name"], props.event) ||
      R.path(["client", "hostname"], props.event) ||
      id;
    if (name === "Agent not found.") return <p>{name}</p>;
    return (
      <FlexSection>
        <CategoryText t={["common:events.details.agent"]} />
        <InfoText id="agent-name">
          <Link id={`agent-link`} to={`/agents/${id}`} tooltip={t("common:tooltips.event_details")}>
            {name}
          </Link>
        </InfoText>
      </FlexSection>
    );
  };

  const getDescription = () => {
    return (
      <FlexSection>
        <CategoryText t={["common:events.details.description"]} />
        <InfoText id="check-description">
          {R.path(["check", "description"], props.event) || ""}
        </InfoText>
      </FlexSection>
    );
  };

  const getCommand = () => {
    let command = R.path(["check", "command"], props.event);
    return command ? (
      <FlexSection id="check-command">
        <CategoryText t={["common:events.details.command"]} />
        <InfoObject>
          <Command command={command} />
        </InfoObject>
      </FlexSection>
    ) : (
      ""
    );
  };

  const getConfig = () => {
    let warning = R.path(["check", "thresholds", "warning"], props.event);
    let critical = R.path(["check", "thresholds", "critical"], props.event);
    return warning || critical ? (
      <FlexSection id="config">
        <CategoryText t={["common:events.details.configuration"]} />
        {warning ? (
          <InfoText id={"event-details-config-warning"} t={["common:events.status.warning"]}>
            {warning}
          </InfoText>
        ) : (
          ""
        )}
        {critical ? (
          <InfoText id={"event-details-config-critical"} t={["common:events.status.critical"]}>
            {critical}
          </InfoText>
        ) : (
          ""
        )}
      </FlexSection>
    ) : (
      ""
    );
  };
  /*
    Todo: Alot of this has the exact same layout/structure as ResultDetailsTab
    Alot of this should be pulled into a CheckDetails component, and the ids need
    to be changed to be unique. With the current state of things I didnt want to spend
    even more time tweaking/fixing it.
    XXX CM - 30/03/2022
  */
  return (
    <React.Fragment>
      {!props.loading && (
        <ContentWrapper>
          <LeftColumn>
            <Subsection>
              <SubheaderSection>
                <SubheaderText t={["common:events.details.event_details"]} />
              </SubheaderSection>
              {getStatus()}
              {getOccurrences()}
              {getLastOccurrence()}
              {getFirstOccurrence()}
              {getSuppressionStatus()}
            </Subsection>
            <Subsection>
              <SubheaderSection>
                <SubheaderText t={["common:events.details.check_details"]} />
              </SubheaderSection>
              {getCheckType()}
              {getPolicyLink()}
              {getAgentName()}
              {getDescription()}
              {getCommand()}
              {getConfig()}
            </Subsection>
          </LeftColumn>

          <RightColumn>
            <SubheaderSection className="-mb--1">
              <SubheaderText t={["common:events.details.output"]} />
            </SubheaderSection>
            <TextArea
              id={`details-output-${R.path(["id"], props.event)}`}
              defaultValue={R.path(["check", "output"], props.event)}
              disabled
              onChange={() => {}}
              size="-lg"
              style={{ height: "400px" }}
            />
          </RightColumn>
        </ContentWrapper>
      )}
    </React.Fragment>
  );
};

EventDetailsTab.propTypes = {
  event: PropTypes.object,
  loading: PropTypes.bool,
};

export default EventDetailsTab;

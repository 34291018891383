import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "components/table/Table";
import styled from "styled-components";
import Button from "components/common/Button";
import Loading from "components/common/Loading";
import Icon from "components/common/Icon";

import Link from "components/form/Link";
import OnlineStatus from "components/details/OnlineStatus";
import { useDispatch, useSelector } from "react-redux";

import SuppressionConfig from "components/suppression/SuppressionConfig";
import { agentSelectors, getAgents, refreshFacts, refreshConfig } from "redux/agents/agentsSlice";
import { selectedCustomerIdSelector } from "redux/navigation/navigationSlice";
import Dropdown, { DropdownItem } from "../menu/Dropdown";
import PageTitle from "components/common/PageTitle";

const TableUtilityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
`;

const ButtonsCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const UtilityRowLeft = styled.div``;

const UtilityRowRight = styled.div``;

const getFullOSName = (props) => {
  return R.path(["value", "name"], props) + " - " + R.path(["value", "release", "full"], props);
};

const getAgentNameCell = (props) => {
  const { t } = useTranslation();
  return (
    <Link
      id={`row-${props.row.id}-details-link`}
      to={`/agents/${props.row.original.id}/results`}
      tooltip={t("common:tooltips.result_details")}
    >
      {props.value}
    </Link>
  );
};

const getOnlineStatus = (props) => {
  const id = props.row.original.id + "-status";
  return <OnlineStatus id={id} status={props.value} />;
};

const getSuppressionStatus = (props) => {
  const suppressions = props.value;
  if (suppressions && Array.isArray(suppressions) && suppressions.length > 0) {
    let agentSuppressed = false;
    let checkSuppressed = false;

    R.forEach((suppression) => {
      if (suppression.check) checkSuppressed = true;
      else agentSuppressed = true;
    }, suppressions);

    if (agentSuppressed && checkSuppressed) return "Agent/Check";
    return agentSuppressed ? "Agent" : "Check";
  } else return "None";
};

const AgentsPage = () => {
  const [selectedAgentIds, setSelectedAgentIds] = useState({});
  const [suppressConfig, setSuppressConfig] = useState(false);
  const tenant = useSelector(selectedCustomerIdSelector);
  const agents = useSelector(agentSelectors.selectAll);
  const loading = useSelector((state) => state.agents.loading);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (tenant != null && tenant != "") {
      dispatch(getAgents(tenant));
    }
  }, [tenant]);

  const getSelectedIds = () => {
    let ids = R.toPairs(selectedAgentIds || {});
    return R.reduce(
      (acc, idArray) => {
        let agent = agents[idArray[0]];
        if (idArray[1]) {
          acc.push(agent.id);
        }
        return acc;
      },
      [],
      ids
    );
  };

  const handleSelectedAgentIds = (ids) => {
    setSelectedAgentIds(ids);
  };

  const handleSuppressAgents = () => {
    if (R.keys(selectedAgentIds).length === 0) return;
    setSuppressConfig(true);
  };

  const handleCloseSuppression = () => {
    setSuppressConfig(false);
  };

  // Get the updated ist of agents after the suppression has been made to update Suppression column
  // TODO: If we ever do pagination this should only get the agent/agents that were suppressed
  const handleSuppress = () => {
    dispatch(getAgents(tenant));
  };

  const getSuppression = () => {
    // TODO: On a future card, check for existing suppressions
    // before returning objects with default values
    return {
      client_ids: getSelectedIds(),
      expire: -1,
      expire_on_resolve: false,
      reason: "",
      policy_id: "",
      check_id: "",
    };
  };

  const getCellButtons = (properties) => {
    const params = {
      customerId: tenant,
      agentIds: [properties.row.original.id],
    };

    const handleSuppressAgent = () => {
      setSuppressConfig(true);
      setSelectedAgentIds({ [properties.row.id]: true });
    };

    const handleRefreshFacts = () => {
      dispatch(refreshFacts(params));
    };

    const handleRefreshConfig = () => {
      dispatch(refreshConfig(params));
    };

    return (
      <ButtonsCell>
        <Dropdown
          key={`kebab-button-${properties.row.id}`}
          id={`kebab-button-${properties.row.id}`}
          flat
          noPadding
          icon="icon-more-vert"
        >
          <DropdownItem
            onClick={handleSuppressAgent}
            id={`action-button-suppress-agent-${properties.row.id}`}
          >
            <Icon
              id={`suppress-button-${properties.row.id}`}
              onClick={handleSuppressAgent}
              icon="icon-bell-off"
              float
              flat
            />
            {t("common:agents.button_suppress_agents")}
          </DropdownItem>
          <DropdownItem
            onClick={handleRefreshFacts}
            id={`action-button-refresh-facts-${properties.row.id}`}
          >
            <Icon id={`facts-button-${properties.row.id}`} icon="icon-ticket-wrench" float flat />
            {t("common:agents.button_refresh_facts")}
          </DropdownItem>
          <DropdownItem
            onClick={handleRefreshConfig}
            id={`action-button-refresh-config-${properties.row.id}`}
          >
            <Icon id={`config-button-${properties.row.id}`} icon="icon-sync" float flat />
            {t("common:agents.button_refresh_config")}
          </DropdownItem>
        </Dropdown>
      </ButtonsCell>
    );
  };

  const getAgentParams = () => {
    let agentIds = getSelectedIds();
    if (agentIds.length === 0) {
      // TODO: Notify user none were checked
      return null;
    }
    const params = {
      customerId: tenant,
      agentIds: agentIds,
    };
    return params;
  };

  const refreshAgentFacts = () => {
    let params = getAgentParams();

    if (params) dispatch(refreshFacts(params));
  };

  const refreshAgentConfig = () => {
    let params = getAgentParams();

    if (params) dispatch(refreshConfig(params));
  };

  const columns = [
    {
      id: "agent-name-cell",
      Header: "common:agents.table.header_name",
      accessor: (agent) => agent.display_name,
      Cell: getAgentNameCell,
    },
    {
      id: "status-cell",
      Header: "common:agents.table.header_status",
      accessor: "connected",
      Cell: getOnlineStatus,
    },
    {
      id: "suppression-cell",
      Header: "common:agents.table.header_suppressed",
      accessor: "suppressions",
      Cell: getSuppressionStatus,
    },
    // {
    //     Header: "Provider",
    //     accessor: "provider"
    // },
    {
      id: "os-cell",
      Header: "OS",
      accessor: "facts.os",
      Cell: getFullOSName,
    },
    // {
    //     Header: "Alerts",
    //     accessor: "alerts"
    // },
    {
      id: "buttons-cell",
      Header: "",
      accessor: "",
      Cell: getCellButtons,
      disableSortBy: true,
    },
  ];

  if (!loading || agents.length > 0) {
    return (
      <React.Fragment>
        {suppressConfig ? (
          <SuppressionConfig
            onSuppress={handleSuppress}
            suppression={getSuppression()}
            handleClose={handleCloseSuppression}
          />
        ) : (
          <React.Fragment>
            <PageTitle size="h3" t={["common:page_navigation.agents"]} />
            <Table
              id="agents-table"
              getSelectedRowIds={handleSelectedAgentIds}
              columns={columns}
              data={[...agents]}
              selectedRowIds={selectedAgentIds}
              selectable
              loading={loading}
            />
            <TableUtilityRow>
              <UtilityRowLeft></UtilityRowLeft>
              <UtilityRowRight>
                <StyledButton
                  id="suppress-agents-button"
                  iconLeft="icon-bell-off"
                  onClick={handleSuppressAgents}
                  t={["common:agents.button_suppress_agents"]}
                />
                <StyledButton
                  id="refresh-facts-button"
                  iconLeft="icon-ticket-wrench"
                  onClick={refreshAgentFacts}
                  t={["common:agents.button_refresh_facts"]}
                />
                <StyledButton
                  id="refresh-config-button"
                  iconLeft="icon-sync"
                  onClick={refreshAgentConfig}
                  primary
                  t={["common:agents.button_refresh_config"]}
                />
              </UtilityRowRight>
            </TableUtilityRow>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return <Loading t={["common:agents.loading"]} />;
};

export default AgentsPage;

import * as R from "ramda";
import styled from "styled-components";
import Popover from "components/modal/Popover";
import Button from "components/common/Button";
import TextInput from "components/form/TextInput";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DropdownItem } from "components/menu/Dropdown";
import {
  customerListSelector,
  setSelectedCustomer,
  selectedCustomerNameSelector,
  getCustomers,
} from "redux/navigation/navigationSlice";
import { useTranslation } from "react-i18next";

const TenantPopoverListStyle = styled.div`
  max-height: 500px;
  width: 400px;
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ContextNavigation = () => {
  const dispatch = useDispatch();
  const tenantName = useSelector(selectedCustomerNameSelector);
  const tenants = useSelector(customerListSelector);
  useEffect(() => {
    if (tenants == null || tenants.length == 0) {
      dispatch(getCustomers());
    }
  }, [tenants]);

  const [modalReferenceElement, setModalReferenceElement] = useState(null);
  const [show, setShow] = useState(false);
  const [filteredTenants, setFilteredTenants] = useState(tenants);
  const [filterText, setFilterText] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    setFilteredTenants(R.filter(filter, tenants));
  }, [filterText, tenants]);

  const filter = (tenant) => {
    const inputString = filterText.toLowerCase();
    const tenantName = tenant.name.toLowerCase();

    return tenantName.includes(inputString);
  };

  const handleToggleTenantSelect = () => {
    setShow(!show);
  };

  const handleFilterInput = (event) => {
    setFilterText(event.target.value);
  };

  const handleSelectClose = () => {
    setShow(false);
    setFilterText("");
  };

  const mapTenantList = (tenant) => {
    const handleSelectTenant = () => {
      dispatch(setSelectedCustomer(tenant));
    };
    return (
      <DropdownItem
        id="tenant-selector-dropdown"
        value={tenant.name}
        toggleMenu={handleSelectClose}
        key={tenant.id}
        onClick={handleSelectTenant}
      >
        {tenant.name}
      </DropdownItem>
    );
  };
  let items = R.map(mapTenantList, filteredTenants);
  return (
    <React.Fragment>
      <Button
        id="select-tenant-button"
        className="-flat"
        onClick={handleToggleTenantSelect}
        getRef={setModalReferenceElement}
      >
        {tenantName
          ? t("common:context_navigation.tenant") + tenantName
          : t("common:context_navigation.tenant_select")}
      </Button>
      <Popover
        id="tenant-list-popover"
        refElement={modalReferenceElement || {}}
        placement="bottom-start"
        show={show}
        handleClose={handleSelectClose}
      >
        <TextInput
          id="filter-tenant-input"
          placeholder="common:context_navigation.tenant_search_placeholder"
          iconRight="icon-search"
          onChange={handleFilterInput}
          value={filterText}
        />
        <TenantPopoverListStyle>{items}</TenantPopoverListStyle>
      </Popover>
    </React.Fragment>
  );
};

export default ContextNavigation;

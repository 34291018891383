import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TruncatedText from "components/common/TruncatedText";
import PopoverAnchor from "components/modal/PopoverAnchor";
import Tooltip from "components/modal/Tooltip";
import Icon from "components/common/Icon";

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 5px;
  cursor: pointer;
`;

const handleCopy = (str) => {
  navigator.clipboard.writeText(str);
};

const Command = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [tooltipRef, setTooltipRef] = useState(null);

  const handleTooltipOpen = () => {
    setShow(true);
  };

  const handleTooltipClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      <TruncatedText
        id={"command-text"}
        text={props.command || ""}
        maxLength={100}
        popoverStyles={{ maxWidth: "400px" }}
      />
      <PopoverAnchor
        id={`copy-command-tooltip-anchor`}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        getRef={setTooltipRef}
      >
        <IconWrapper>
          <Icon
            className="-m--0 -ml--5"
            id="copy-command-button"
            icon="icon-paste"
            float
            flat
            onClick={() => handleCopy(props.command)}
          />
        </IconWrapper>
      </PopoverAnchor>
      <Tooltip
        id={"copy-command-tooltip"}
        text={t("common:tooltips.copy_command")}
        show={show}
        refElement={tooltipRef || {}}
        handleClose={handleTooltipClose}
      />
    </React.Fragment>
  );
};

Command.propTypes = {
  command: PropTypes.string.isRequired,
};

export { handleCopy };
export default Command;
